import Person01 from "../Assets/dummy.jpg";

//testimonialsSettings
const testimonialsSettings = {
  // margin: 120,
  // loop: true,
  // center: false,
  // mouseDrag: false,
  // touchDrag: false,
  // nav: false,
  // dots: true,
  // autoplay: true,
  autoplayTimeout: 100000000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 2,
    },
    900: {
      items: 3,
    },
    1200: {
      items: 3,
    },
    1536: {
      items: 3,
    },
  },
};

const Testimonials = [
  {
    id: 1,
    name: "Surya Teja",
    location: "",
    rating: 5,
    title: "My dream kitchen interior design",
    content:
      "We always had an appreciation for style but are too confused to pull a design together so we decided to place all of our trust in Modulin. We hired them to furnish our new kitchen top-to-bottom, and we are SO happy with the results! We had some idea of what we were looking for but we would never be able to pull together like what the team did. Watching it all come together was a treat. All we had to do was sit back and watch as the design and delivery team brought pieces to our home, assemble and arrange them!! Just blindly trust them. You will be completely satisfied.",
    imageUrl: Person01,
  },
  {
    id: 2,
    name: "Sai Peddireddy",
    location: "",
    rating: 5,
    title: "Best quality with best design",
    content:
      "Best quality with design at a sustainable price. Staff here is so humble and they work at their best in satisfying customers. Especially architect Suresh has a lot of talent and he helped us so much in delivering the best design and output for our project. I'm overall satisfied with the final output and the attached picture shows the result.",
    imageUrl: Person01,
  },
  {
    id: 3,
    name: "Nodagala Viswanadh",
    location: "",
    rating: 5,
    title: "I enjoy going to the kitchen every day to spend time",
    content:
      "If you are looking for help in getting your kitchen done don't hesitate to contact Modulin. All the staff are very professional and I felt very comfortable working with my designer and their team. They listened to what I wanted and made sure to be available if I had new ideas and they always updated me on the progress of the Kitchen. My favorite thing about this company is definitely their distinct modern style. It feels very current & trendy and I enjoy going to the kitchen every day to spend time .They are also very reasonably priced. They used my small space to the fullest in ways I never thought was possible. In one word, it's beautiful, comfortable and functional with very affordable prices.",
    imageUrl: Person01,
  },
  {
    id: 4,
    name: "Durga Prasad M",
    location: "",
    rating: 5,
    title: "Our Dream kitchen turned out great",
    content:
      "Sleek Kitchen was great. We had a limited budget and they presented us with a number of options for our modular kitchen Interiors that were within our financial range. They explained color schemes and choice of materials simply so that we could understand and visualize the concept. Our Dream kitchen turned out great.",
    imageUrl: Person01,
  },
  {
    id: 5,
    name: "Ramya",
    location: "",
    rating: 5,
    title: "Dream kitchen interior design ",
    content:
      "Their expertise and advice enabled us to get the kitchen we wanted and he saved us lots of time and money. I would recommend them to anyone who needs a great kitchen designer. Using their Kitchen Design is money well spent!",
    imageUrl: Person01,
  },
  {
    id: 6,
    name: "Sirisha Jyotsna",
    location: "",
    rating: 5,
    title: "Their work was very professional and hassle free",
    content:
      "I am Very Pleased and happy with the wonderful work done by Modulin And Sleek Kitchens in transforming my kitchen into a beautiful piece of art. They were very accommodative and full of ideas. Their work was very professional and hassle free. ",
    imageUrl: Person01,
  },

  {
    id: 7,
    name: "Nikhil Raj",
    location: "",
    rating: 5,
    title: "One stop solution to all your kitchen",
    content:
      "Modulin -  is your one stop solution to all your kitchen needs and desires. They are highly flexible, prompt and efficient in addressing all your kitchen requirements and it's intricacies. I would highly suggest them as they are completely professional and worth every penny and you wouldn't regret your decision choosing them",
    imageUrl: Person01,
  },

  {
    id: 8,
    name: "Pedipati Gideon",
    location: "",
    rating: 5,
    title: "Many styles and good designs",
    content:
      "This is a very good quality, such quality and sleek products are not found anywhere only in our Kakinada town in Modulin. I looked at every kitchen style and found kitchens with many styles and good designs.",
    imageUrl: Person01,
  },

  {
    id: 9,
    name: "Amarendra Chowdary",
    location: "",
    rating: 5,
    title: "Mastered in meticulous planning",
    content:
      "Modulin mastered in meticulous planning of Modular-kitchen layout & placement of Kitchen Appliances. Their coordination with Sleek in execution & installation is seamless and extraordinary including their service. The product is top-notch.",
    imageUrl: Person01,
  },

  {
    id: 10,
    name: "John Lazarus",
    location: "",
    rating: 5,
    title: "Looks like a work of art",
    content:
      "We had an old kitchen which was drab and didn't function well. Now we have a fabulous kitchen which looks like a work of art. It is functional with clean lines and it is formal but still friendly.      ",
    imageUrl: Person01,
  },

  {
    id: 11,
    name: "Srianjaneyulu",
    location: "",
    rating: 5,
    title: "Excellent customer service",
    content:
      "I got my wardrobes, kitchen and crockery done through Sleek Kitchens from Modulin and I must say they have provided excellent customer service      ",
    imageUrl: Person01,
  },
];

export { testimonialsSettings, Testimonials };

import React from "react";

import Slide01 from "../../Assets/dummy.jpg";

import BannerImage from "../../Assets/images/modularinteriors/modularinterior.jpg";
import ModularKitchen from "../../Assets/images/kitchen/K1.jpg";
import Wardrobes from "../../Assets/images/wardrobe/Wd01.jpeg";
import PoojaMandir from "../../Assets/images/pooja-room/Pr01.jpg";
import StudyArea from "../../Assets/images/studyarea/Sa01.jpg";
import TvUnits from "../../Assets/images/tv-units/Tvu04.jpg";
import CrockeryUnits from "../../Assets/images/crockeryunits/Cu01.jpg";
import Entrance from "../../Assets/images/entanceDoor/main.jpg";
import ShoeRack from "../../Assets/images/shoeRack/mian.jpg";
import Living from "../../Assets/images/living-area/La01.jpg";
import MasterRoom from "../../Assets/images/master-bed-room/Mb01.jpg";
import Kids from "../../Assets/images/kids-bedroom/Kbr01.jpg";
import Storage from "../../Assets/images/storage-units/Su01.jpg";
import HomeOff from "../../Assets/images/homeOffices/main.jpg";

const bannerDetails = {
  title: "Elevate your home with enduring, exquisite woodwork.",
  desc: "Experience seamless home transformation with Modulin Select. Explore our extensive collection of affordable, top-notch modular solutions including kitchens, wardrobes, TV units, bookshelves, shoe racks, and more. Tailor-made to suit your home, choose from a variety of colors, designs, and sizes that perfectly complement your unique style.",
  bannerimage: BannerImage,
};

const carouselSettings = {
  responsive: {
    0: {
      items: 1.3,
    },

    600: {
      items: 1.6,
    },
    900: {
      items: 2.8,
    },
    1200: {
      items: 3.3,
    },
    1536: {
      items: 4.5,
    },
  },
};

const whatWeOffer = [
  {
    id: 1,
    title: "Kitchen",
    imageUrl: ModularKitchen,
    category: "Modular Kitchen",
    link: "/kitchens",
    categoryLink: "/kitchens",
    // categoryLink: "/design-ideas/modular-kitchen-designs",
  },
  {
    id: 2,
    title: "Wardrobes",
    imageUrl: Wardrobes,
    category: "Wardrobes",
    link: "/wardrobes",
    categoryLink: "/wardrobes",
    // categoryLink: "/design-ideas/wardrobe-designs",
  },
  {
    id: 3,
    title: "Master Bedroom",
    imageUrl: MasterRoom,
    category: "Master Bedroom",
    // link: "/modular-interiors/master-room",
    link: "/design-ideas/master-bedroom-designs",
    categoryLink: "/design-ideas/master-bedroom-designs",
  },

  {
    id: 7,
    title: "Kids Bedroom",
    imageUrl: Kids,
    category: "Kids Bedroom",
    // link: "/modular-interiors/kids-bedroom",
    link: "/design-ideas/kids-bedroom-designs",
    categoryLink: "/design-ideas/kids-bedroom-designs",
  },
  {
    id: 7,
    title: "Living Room",
    imageUrl: Living,
    category: "Living Room",
    // link: "/modular-interiors/living-room",
    link: "/design-ideas/living-room-designs",
    categoryLink: "/design-ideas/living-room-designs",
  },
  {
    id: 3,
    title: "Pooja Room",
    imageUrl: PoojaMandir,
    category: "Pooja Room ",
    link: "/pooja-mandir",
    categoryLink: "/pooja-mandir",
    // categoryLink: "/design-ideas/pooja-room-designs",
  },
  {
    id: 6,
    title: "TV Units",
    imageUrl: TvUnits,
    category: "TV Units",
    link: "/tv-units",
    categoryLink: "/tv-units",
    // categoryLink: "/design-ideas/tv-unit-designs",
  },
  {
    id: 5,
    title: "Crockery Units",
    imageUrl: CrockeryUnits,
    category: "Crockery Units",
    link: "/crockery-units",
    categoryLink: "/crockery-units",
    // categoryLink: "/design-ideas/crockery-unit-designs",
  },
  // {
  //   id: 4,
  //   title: "Storage Unit",
  //   imageUrl: Storage,
  //   category: "Storage Unit",
  //   // link: "/modular-interiors/storage-units",
  //   link: "/design-ideas/storage-unit-designs",
  //   categoryLink: "/design-ideas/storage-unit-designs",
  // },
  {
    id: 4,
    title: "Study Area",
    imageUrl: StudyArea,
    category: "Study Area",
    link: "/study-area",
    categoryLink: "/study-area",
    // categoryLink: "/design-ideas/study-area",
  },
  {
    id: 7,
    title: "Home Offices",
    imageUrl: HomeOff,
    category: "Home Offices",
    // link: "/modular-interiors/home-office",
    link: "/design-ideas/home-office-designs",
    categoryLink: "/design-ideas/home-office-designs",
  },
  {
    id: 8,
    title: "Entrance Door",
    imageUrl: Entrance,
    category: "Entrance Door",
    // link: "/modular-interiors/entrance-door",
    link: "/design-ideas/entrance-door-designs",
    categoryLink: "/design-ideas/entrance-door-designs",
  },
  {
    id: 8,
    title: "Shoe Racks",
    imageUrl: ShoeRack,
    category: "Shoe Racks",
    // link: "/modular-interiors/shoe-rack",
    link: "/design-ideas/shoe-rack-designs",
    categoryLink: "/design-ideas/shoe-rack-designs",
  },
];

export { bannerDetails, carouselSettings, whatWeOffer };

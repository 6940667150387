import React from "react";

const MenuItems = [
  {
    id: 2,
    name: "Residences",
    link: "/design-ideas",
    subMenu: [
      {
        id: 201,
        name: "Modular Kitchen Designs",
        link: "/design-ideas/modular-kitchen-designs",
      },
      {
        id: 202,
        name: "Wardrobe Designs",
        link: "/design-ideas/wardrobe-designs",
      },
      {
        id: 203,
        name: "Master Bedroom Designs",
        link: "/design-ideas/master-bedroom-designs",
      },
      {
        id: 204,
        name: "Living Room Designs",
        link: "/design-ideas/living-room-designs",
      },
      {
        id: 205,
        name: "Pooja Room Designs",
        link: "/design-ideas/pooja-room-designs",
      },
      {
        id: 206,
        name: "TV Unit Designs",
        link: "/design-ideas/tv-unit-designs",
      },
      {
        id: 207,
        name: "Kids Bedroom Designs",
        link: "/design-ideas/kids-bedroom-designs",
      },
      {
        id: 208,
        name: "Dining Room Designs",
        link: "/design-ideas/dining-room-designs",
      },
      {
        id: 209,
        name: "Guest Bedroom Designs",
        link: "/design-ideas/guest-bedroom-designs",
      },
    ],
  },
  {
    id: 3,
    name: "Offices",
    subMenu: [
      { id: 301, name: "Workstations", link: "/design-ideas/workstations" },
      { id: 302, name: "Office Tables", link: "/design-ideas/office-tables" },
      { id: 303, name: "Storage Units", link: "/design-ideas/storage-unit-designs" },
    ],
  },
  {
    id: 4,
    name: "",
    // name: "Cities",
    subMenu: [
      // { id: 401, name: "Kakinada", link: "/city/kakinada" },
      // { id: 402, name: "Vijayawada", link: "/city/vijayawada" },
      // { id: 403, name: "Hyderabad", link: "/city/hyderabad" },
      // { id: 404, name: "Guntur", link: "/city/guntur" },
    ],
  },
  {
    id: 5,
    name: "Others Link",
    subMenu: [
      { id: 501, name: "Login / Sign Up", link: "/login" },
      // { id: 502, name: "Offerings", link: "/offerings" },
      { id: 503, name: "About Us", link: "/about-us" },
      // { id: 504, name: "How it Works", link: "/how-it-works" },
      { id: 505, name: "Refer a Friend", link: "/refer-a-customer" },
      { id: 506, name: "Price Calculations", link: "/price-calculations" },
      { id: 507, name: "Careers", link: "/careers" },
      { id: 508, name: "Contact Us", link: "/contact-us" },
      // { id: 509, name: "Partner with Us", link: "/partner-with-us" },
      { id: 510, name: "Policies", link: "/policies" },
    ],
  },
  {
    id: 6,
    name: "Services we deliver",
    text: "All over Andhra pradesh & Telangana.",
    desclaimer:
      "All Images used on this website is only for reference  if any objection please inform us ( info@modulin.in )",
  },
  {
    id: 7,
    name: "Offices",
    subMenu: [
      { id: 501, name: "retail", link: "/retail" },
      { id: 503, name: "Offices", link: "/offices" },
    ],
    },
];

export default MenuItems;

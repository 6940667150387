import React from "react";

import {
  Box,
  Avatar,
  Typography,
  Stack,
  Toolbar,
  AppBar,
  Grid,
  SvgIcon,
  IconButton,
} from "@mui/material";
import theme from "../../../Theme";
import { menu } from "../../../Assets/icons/icons";
import Boy from "../../../Assets/boy.jpg";
import Girl from "../../../Assets/girl.jpg";
const Header = () => {
  const NAME = localStorage.getItem("NAME");
  const GENDER = localStorage.getItem("GENDER");
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: `rgba(${theme.palette.text.white},1)` }}
    >
      <Toolbar>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid>
            <IconButton>{/* <SvgIcon component={menu} /> */}</IconButton>
          </Grid>
          <Grid>
            <Stack direction="row" alignItems="center" gap={1}>
              <Avatar>
                <img
                  src={GENDER === "Male" ? Boy : Girl}
                  alt=""
                  width={"100%"}
                />
              </Avatar>
              <Box>
                <Typography>{NAME || "Demo"}</Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

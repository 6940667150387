import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { Box, Breadcrumbs, Typography, Link as MuiLink } from "@mui/material";
import theme from "../../Theme";

const PageBreadcrumbs = ({ marginbottom }) => {
  const { pathname } = useLocation();
  const segments = pathname.split("/").filter((segment) => segment !== "");
  const UpdateSegment = segments?.filter(
    (item) => item !== "modular-interiors"
  );
  console.log(segments);
  // Add the "Home" breadcrumb at the beginning
  const breadcrumbTrail = ["Home", ...UpdateSegment];

  return (
    <React.Fragment>
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: marginbottom }}>
        {breadcrumbTrail.map((segment, index) => {
          const isCurrentPage = index === breadcrumbTrail.length - 1;
          const linkUrl =
            index === 0
              ? "/"
              : `/${breadcrumbTrail.slice(1, index + 1).join("/")}`;

          // Capitalize the first letter and remove hyphens or underscores
          const segmentText = segment
            .replace(/[-_]/g, " ")
            .replace(/^\w/, (c) => c.toUpperCase());

          // Render the breadcrumb separator if not the first or last segment
          const separator = index > 0 && index < breadcrumbTrail.length - 1 && (
            <span> </span>
          );

          return (
            <span key={index}>
              {!isCurrentPage ? (
                <MuiLink
                  underline="hover"
                  color={theme.palette.text.primary}
                  component={Link}
                  to={linkUrl}
                  sx={{ fontWeight: 600 }}
                >
                  {segmentText}
                </MuiLink>
              ) : (
                <Typography color="primary">{segmentText}</Typography>
              )}
              {separator}
            </span>
          );
        })}
      </Breadcrumbs>
    </React.Fragment>
  );
};

PageBreadcrumbs.defaultProps = {
  marginbottom: "20px",
};

export default PageBreadcrumbs;

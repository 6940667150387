import React from "react";
//Custom Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBannner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import commingSoon from "../../Assets/commingSoon.jpg";
const PriceCalculations = () => {
  return (
    <React.Fragment>
      <DefaultLayout>
        <InnerBannner
          image={commingSoon}
          commingSoon={"Coming Soon"}
          title={"Modular Solutions Price Estimator"}
          desc={"Instantly estimate the cost of your modular interiors with our easy-to-use price calculator."}
        />
      </DefaultLayout>
    </React.Fragment>
  );
};

export default PriceCalculations;

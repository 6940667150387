import React, { useState } from "react";
import ModaalDark from "../../../Component/HelperComponents/Modal/ModalDark";
import { Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  OutlinedInput,
  Select,
} from "@mui/material";
import theme from "../../../Theme";
import axios from "axios";
import { POST_UPDATE_PROFILE } from "../../../Service/ApiRoutes";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
export const AddDetailsAgentModal = ({ Toggler, Initial, Refetch }) => {
  const Token = localStorage.getItem("TOKEN");
  const nav = useNavigate();
  const handleFormSubmit = (values) => {
    console.log(values);
    axios
      .post(POST_UPDATE_PROFILE(), values, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
      .then(({ data }) => {
        console.log(data);
        if (data?.status === "Success") {
          toast.success(data?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          Toggler();
          Refetch();
        } else {
          toast.error("Token Expired", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          nav("../login");
        }
      });
  };

  const [workProfile, setworkProfile] = useState(Initial?.work_profile);
  const [Gender, setGender] = useState(Initial?.gender);
  const [Marrit, setMarrit] = useState(Initial?.marital_status);
  return (
    <ModaalDark anchorEl={true}>
      <div className="Modal1200">
        <Typography
          variant="body2"
          align="left"
          sx={{
            //   fontSize: "1.2em",
            fontWeight: 700,
          }}
        >
          Update Details
        </Typography>
        <span
          onClick={() => Toggler()}
          id="closeIcon"
          style={{
            display: "block",
            borderRadius: "50px",
            position: "absolute",
            top: "20px",
            right: "20px",
            background: "rgb(239 91 65)",
            fontSize: ".9em",
            width: "14px",
            height: "14px",
            zIndex: 10,
          }}
        />
        <div className="modalContent">
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.orange1,
              fontWeight: 500,
            }}
          >
            Personal Info
          </Typography>
          <Formik
            initialValues={{
              first_name: Initial?.first_name,
              last_name: Initial?.last_name,
              alternate_num: Initial?.account_num,
              gender: Initial?.gender,
              marital_status: Initial?.marital_status,
              current_address: Initial?.current_address,
              permanent_address: Initial?.permanent_address,
              bank_name: Initial?.bank_name,
              account_num: Initial?.account_num,
              ifsc_code: Initial?.ifsc_code,
              holder_name: Initial?.holder_name,
              branch: Initial?.branch,
              email: Initial?.email,
              work_profile: Initial?.work_profile,
            }}
            // validationSchema={VALIDATION}
            // enableReinitialize
            onSubmit={handleFormSubmit}
          >
            {({ setFieldValue, handleSubmit }) => (
              <Form>
                <div className="Grid8 gap-3 m-0 mt-3 p-2">
                  <div className="">
                    <div className="sec_subBox">
                      <label htmlFor="" className="mb-2 fs-7 fw600">
                        First Name
                      </label>
                      <Field name="first_name">
                        {({ field }) => (
                          <TextField
                            {...field}
                            className="My_fieldBoxNormalGray"
                            fullWidth
                            autoComplete="off"
                            id="outlined-basic"
                            label="Enter Name"
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        className="errors"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="sec_subBox">
                      <label htmlFor="" className="mb-2 fs-7 fw600">
                        Last Name
                      </label>
                      <Field name="last_name">
                        {({ field }) => (
                          <TextField
                            {...field}
                            className="My_fieldBoxNormalGray"
                            fullWidth
                            autoComplete="off"
                            id="outlined-basic"
                            label="Enter Name"
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        className="errors"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="sec_subBox">
                      <label htmlFor="" className="mb-2 fs-7 fw600">
                        Phone Number
                      </label>
                      <Field name="mobile_num">
                        {({ field }) => (
                          <TextField
                            {...field}
                            disabled
                            className="My_fieldBoxNormalGray"
                            fullWidth
                            autoComplete="off"
                            id="outlined-basic"
                            label="Enter Number"
                            variant="outlined"
                            value={Initial?.mobile_num?.slice(-10)}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="mobile_num"
                        component="div"
                        className="errors"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="sec_subBox">
                      <label htmlFor="" className="mb-2 fs-7 fw600">
                        Alternate Number
                      </label>
                      <Field name="alternate_num">
                        {({ field }) => (
                          <TextField
                            {...field}
                            type="number"
                            className="My_fieldBoxNormalGray"
                            fullWidth
                            autoComplete="off"
                            id="outlined-basic"
                            label="Enter Number"
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="alternate_num"
                        component="div"
                        className="errors"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="sec_subBox">
                      <label htmlFor="" className="mb-2 fs-7 fw600">
                        Mail
                      </label>
                      <Field name="email">
                        {({ field }) => (
                          <TextField
                            {...field}
                            className="My_fieldBoxNormalGray"
                            fullWidth
                            autoComplete="off"
                            id="outlined-basic"
                            label="Enter Mail ID"
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="errors"
                      />
                    </div>
                  </div>

                  <div className="">
                    <label htmlFor="leaveId" className="mb-2 fs-7 fw600">
                      Work Profile
                    </label>
                    <FormControl
                      fullWidth
                      className="CustomSelectDropNormalGray"
                    >
                      {console.log(workProfile?.substring(10))}
                      <InputLabel id="leaveId">Select</InputLabel>
                      <Select
                        labelId="leaveId"
                        id="demo-simple-leave"
                        label="Select"
                        name="work_profile"
                        value={workProfile}
                        className="Custom_Select"
                        sx={{
                          '& .MuiSelect-icon': {
                            display: workProfile ? 'none' : 'block', // Hide the dropdown icon if a value is selected
                          },
                        }}
                      >
                        {[
                          "Architect",
                          "Interior Designer",
                          "Civil Engineer",
                          "Carpenter",
                          "Marketing Agent",
                          "Client",
                          "Others",
                        ]?.map((Elem) => {
                          console.log(Elem?.substring(10))
                          return (
                            <MenuItem
                              value={Elem}
                              key={Elem}
                              onClick={() => {
                                setFieldValue("work_profile", Elem);
                                setworkProfile(Elem);
                              }}
                            >
                              {Elem}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <ErrorMessage
                      name="work_profile"
                      component="div"
                      className="errors"
                      style={{
                        marginTop: "0px",
                      }}
                    />
                  </div>

                  <div className="">
                    <label htmlFor="leaveId" className="mb-2 fs-7 fw600">
                      Gender
                    </label>
                    <FormControl
                      fullWidth
                      className="CustomSelectDropNormalGray"
                    >
                      <InputLabel id="leaveId">Select</InputLabel>
                      <Select
                        labelId="leaveId"
                        id="demo-simple-leave"
                        label="Select"
                        name="gender"
                        className="Custom_Select"
                        value={Gender}
                      >
                        {["Male", "Female", "Other"]?.map((Elem) => {
                          return (
                            <MenuItem
                              value={Elem}
                              key={Elem}
                              onClick={() => {
                                setFieldValue("gender", Elem);
                                setGender(Elem);
                              }}
                            >
                              {Elem}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <ErrorMessage
                      name="gender"
                      component="div"
                      className="errors"
                      style={{
                        marginTop: "0px",
                      }}
                    />
                  </div>
                  <div className="">
                    <label htmlFor="leaveId" className="mb-2 fs-7 fw600">
                      Marital Status
                    </label>
                    <FormControl
                      fullWidth
                      className="CustomSelectDropNormalGray"
                    >
                      <InputLabel id="leaveId">Select</InputLabel>
                      <Select
                        labelId="leaveId"
                        id="demo-simple-leave"
                        label="Select"
                        name="marital_status"
                        className="Custom_Select"
                        value={Marrit}
                      >
                        {["Married", "Single"]?.map((Elem) => {
                          return (
                            <MenuItem
                              value={Elem}
                              key={Elem}
                              onClick={() => {
                                setFieldValue("marital_status", Elem);
                                setMarrit(Elem);
                              }}
                            >
                              {Elem}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <ErrorMessage
                      name="marital_status"
                      component="div"
                      className="errors"
                      style={{
                        marginTop: "0px",
                      }}
                    />
                  </div>
                </div>
                <div className="Grid2 gap-3 mt-2">
                  <div className="">
                    {" "}
                    <label htmlFor="leaveId" className="mb-2 fs-7 fw600">
                      Current Address
                    </label>
                    <div className="sec_subBox">
                      <label htmlFor="" className="mb-2 fs-7 fw600"></label>
                      <Field name="current_address">
                        {({ field }) => (
                          <TextField
                            {...field}
                            className="My_fieldBoxNormalGray"
                            fullWidth
                            multiline
                            rows={4}
                            autoComplete="off"
                            id="outlined-basic"
                            label="Enter Mail ID"
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="current_address"
                        component="div"
                        className="errors"
                      />
                    </div>
                  </div>
                  <div className="">
                    {" "}
                    <label htmlFor="leaveId" className="mb-2 fs-7 fw600">
                      Parmanent Address
                    </label>
                    <div className="sec_subBox">
                      <label htmlFor="" className="mb-2 fs-7 fw600"></label>
                      <Field name="permanent_address">
                        {({ field }) => (
                          <TextField
                            {...field}
                            className="My_fieldBoxNormalGray"
                            fullWidth
                            multiline
                            rows={4}
                            autoComplete="off"
                            id="outlined-basic"
                            label="Enter Mail ID"
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="permanent_address"
                        component="div"
                        className="errors"
                      />
                    </div>
                  </div>
                </div>
                {Initial?.role === "Agent" && (
                  <>
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.orange1,
                        fontWeight: 500,
                        marginTop: "1em",
                      }}
                    >
                      Bank Info
                    </Typography>
                    <div className="Grid8 gap-3 m-0 mt-2 p-2">
                      <div className="">
                        <div className="sec_subBox">
                          <label htmlFor="" className="mb-2 fs-7 fw600">
                            Bank Name
                          </label>
                          <Field name="bank_name">
                            {({ field }) => (
                              <TextField
                                {...field}
                                className="My_fieldBoxNormalGray"
                                fullWidth
                                autoComplete="off"
                                id="outlined-basic"
                                label="Enter Name"
                                variant="outlined"
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="bank_name"
                            component="div"
                            className="errors"
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="sec_subBox">
                          <label htmlFor="" className="mb-2 fs-7 fw600">
                            Account Number
                          </label>
                          <Field name="account_num">
                            {({ field }) => (
                              <TextField
                                {...field}
                                className="My_fieldBoxNormalGray"
                                fullWidth
                                autoComplete="off"
                                id="outlined-basic"
                                label="Enter Name"
                                variant="outlined"
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="account_num"
                            component="div"
                            className="errors"
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="sec_subBox">
                          <label htmlFor="" className="mb-2 fs-7 fw600">
                            IFSC Code
                          </label>
                          <Field name="ifsc_code">
                            {({ field }) => (
                              <TextField
                                {...field}
                                className="My_fieldBoxNormalGray"
                                fullWidth
                                autoComplete="off"
                                id="outlined-basic"
                                label="Enter Number"
                                variant="outlined"
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="ifsc_code"
                            component="div"
                            className="errors"
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="sec_subBox">
                          <label htmlFor="" className="mb-2 fs-7 fw600">
                            Holder Name
                          </label>
                          <Field name="holder_name">
                            {({ field }) => (
                              <TextField
                                {...field}
                                className="My_fieldBoxNormalGray"
                                fullWidth
                                autoComplete="off"
                                id="outlined-basic"
                                label="Enter Number"
                                variant="outlined"
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="holder_name"
                            component="div"
                            className="errors"
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="sec_subBox">
                          <label htmlFor="" className="mb-2 fs-7 fw600">
                            Branch
                          </label>
                          <Field name="branch">
                            {({ field }) => (
                              <TextField
                                {...field}
                                className="My_fieldBoxNormalGray"
                                fullWidth
                                autoComplete="off"
                                id="outlined-basic"
                                label="Enter Mail ID"
                                variant="outlined"
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="branch"
                            component="div"
                            className="errors"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="FlexEnd">
                  <button
                    type="submit"
                    className="btnYellow mt-3 "
                    style={{
                      padding: ".8em 2em",
                    }}
                  >
                    Add
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModaalDark>
  );
};

export const AdminRoutes = [
  {
    name: "Users",
    link: "/admin/users",
  },

  {
    name: "Agents",
    link: "/admin/agents",
  },

  {
    name: "Enquiries",
    link: "/admin/enquiries",
  },

  {
    name: "Consultations",
    link: "/admin/consultations",
  },
  {
    name: "Career",
    link: "/admin/career",
  },
];

export const AgentRoutes = [
  {
    name: "Enquiries",
    link: "/user/enquiries",
  },
];

import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

//Owl Carousel
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//MUI
import theme from "../../Theme";
import { Box, Container, Grid, Button, Typography } from "@mui/material";

//Custome Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBanner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import Section from "../../Component/HelperComponents/Section";
import SectionTitle from "../../Component/HelperComponents/SectionTitle";
import SectionScrolling from "../../Component/HelperComponents/SectionScrolling";
import { FromFieldStyles } from "../../Component/HelperComponents/CustomFormElements/CustomeFormElements";
import ConsultationFormSection from "../../Component/HelperComponents/ConsultationForm/ConsultationFormSection";
import RangeSet from "../../Component/HelperComponents/RangeType/RangeSet";
import LayoutSingleSlider from "../../Component/HelperComponents/CustomCarousel/Layouts/LayoutSingleSlider";
import ConsultationForm from "../../Component/HelperComponents/ConsultationForm/ConsultationForm";
import ModalSection from "../../Component/HelperComponents/ModalSection";
//Image and Icons
import BgImage from "../../Assets/images/bg-image/kitchen-outline.jpg";
//Data
import {
  bannerDetails,
  typesStudyArea,
  PrallexDetails,
  consultationFormSectionDetails,
} from "../../Data/ModularInteriors/StudyArea";

import {
  BannerDetails as BannerDetailsStudyArea,
  Gallery as galleryStudyArea,
} from "../../Data/DesignIdeas/StudyDesign";
import CustomGallery from "../../Component/HelperComponents/CustomGallery/CustomGallery";
function StudyArea() {
  const { pathname } = useLocation();

  const UpdateData = pathname?.split("/")?.filter((item) => {
    return item !== "modular-interiors" && item !== "";
  });
  const galleryData = galleryStudyArea;
  //Modal Setup
  const [openModalForm, setOpenModalForm] = useState(false);
  const handleClickOpen = () => {
    setOpenModalForm(true);
  };
  const handleClose = (event, reason) => {
    //if (reason && reason == "backdropClick") return;
    setOpenModalForm(false);
  };
  //Modal Setup End
  //Form Style
  const classes = FromFieldStyles();
  const location = useLocation();

  return (
    <React.Fragment>
      <DefaultLayout>
        <InnerBanner
          type={bannerDetails.type}
          title={bannerDetails.title}
          desc={bannerDetails.desc}
          image={bannerDetails.image}
          bannertext={bannerDetails.bannertext}
        />
        <Section bgcolor="white">
          <RangeSet
            link={location.pathname}
            title={bannerDetails.title}
            desc={bannerDetails.desc}
          />
        </Section>

        {galleryData ? (
          <Section bgcolor="white" padding="0 24px 50px 24px">
            <SectionTitle
              title={UpdateData && `Ideas For Modular ${UpdateData[0]?.split("-")?.join(" ")}`}
              shadowtext="Idea"
              // title2={UpdateData && `Ideas For Modular ${UpdateData[0]} Design`}
            />

            <CustomGallery
              data={galleryData}
              type="slideimage"
              handleClickOpen={handleClickOpen}
              loadmore={true}
            />
          </Section>
        ) : null}
        <Section bgimg={BgImage}>
          <Container maxWidth={"xl"}>
            <OwlCarousel className="owl-theme" items={1} margin={20} loop>
              {typesStudyArea.map((item) => (
                <LayoutSingleSlider
                  key={item.id} // Use a unique identifier from your data as the key
                  title={item.title}
                  desc={item.desc}
                  imageUrl={item.imageUrl}
                  handleClickOpen={handleClickOpen}
                />
              ))}
            </OwlCarousel>
          </Container>
        </Section>

        <Section
          bgimg={consultationFormSectionDetails.image}
          bgcolor={consultationFormSectionDetails.bgcolor}
        >
          <Container>
            <ConsultationFormSection
              title={consultationFormSectionDetails.title}
            />
          </Container>
        </Section>
      </DefaultLayout>
      <ModalSection open={openModalForm} onClose={handleClose}>
        <ConsultationForm />
      </ModalSection>
    </React.Fragment>
  );
}
export default StudyArea;

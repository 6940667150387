import React from "react";
import { Link } from "react-router-dom";
//MUI
import theme from "../../Theme";
import { styled } from "@mui/system";
import { Grid, Typography, Container, Box, IconButton } from "@mui/material";
//Custom Component
import CopyRights from "../HelperComponents/CopyRights";
//Images and Icons
import Logo from "../../Assets/logo.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { FaXTwitter } from "react-icons/fa6";

//Dummy Data
import MenuItems from "../../Data/MenuList";

const SiteFooter = styled("footer")({
  backgroundColor: `rgba(${theme.palette.text.default},1)`,
  padding: "70px 0 0px 0",
  color: `rgba(${theme.palette.text.white},1)`,
});

const FooterMenu = styled("div")({
  display: "flex",

  flexWrap: "wrap",

  "& > div": {
    "&:first-of-type": {
      //flexBasis: "100%",
    },
  },

  "& div": {
    fontSize: "12px",
    color: `rgba(${theme.palette.text.white},1)`,

    "& div": {
      margin: "0 0 20px 0",
    },
    "& h6": {
      fontSize: "16px",
      fontWeight: 600,
      margin: "0 0 5px 0",
    },
    "& ul": {
      listStyleType: "none",
      gap: "10px",
      "& li": {
        "& a": {
          whiteSpace: "nowrap",
          fontSize: "12px",
          color: `rgba(${theme.palette.text.white},1)`,
          textDecoration: "none",
          padding: "5px 0",
          display: "block",
          "&:hover": {
            color: `rgba(${theme.palette.primary.default},1)`,
          },
        },
      },
    },
    "& p": {
      fontSize: "12px",
      lineHeight: "1.6",
    },
  },
});

const LineMenu = styled("div")({
  width: "100%",
  display: "flex",
  gap: "20px",
  "& h6": {
    fontSize: "16px",
    fontWeight: 600,
  },
  "& ul": {
    display: "flex",
    flexWrap: "wrap",
    listStyleType: "none",
    gap: "10px",
    "& li": {
      "& a": {
        whiteSpace: "nowrap",
        fontSize: "12px",
        color: `rgba(${theme.palette.text.white},1)`,
        textDecoration: "none",
        "&:hover": {
          color: `rgba(${theme.palette.primary.default},1)`,
        },
      },
    },
  },
});

const ResidenceMenuItems = MenuItems.find((item) => item.id === 2);
const ResidenceSubMenu = ResidenceMenuItems ? ResidenceMenuItems.subMenu : [];

const CommercialMenuItems = MenuItems.find((item) => item.id === 3);
const CommercialSubMenu = CommercialMenuItems
  ? CommercialMenuItems.subMenu
  : [];

const CityMenuItems = MenuItems.find((item) => item.id === 4);
const DeleveryOffer = MenuItems.find((item) => item.id === 6);
const Offices = MenuItems.find((item) => item.id === 7);
const CitySubMenu = CityMenuItems ? CityMenuItems.subMenu : [];

const OthersMenuItems = MenuItems.find((item) => item.id === 5);
const OthersSubMenu = OthersMenuItems ? OthersMenuItems.subMenu : [];

const Footer = () => {
  return (
    <React.Fragment>
      <SiteFooter>
        <Grid
          container
          sx={{
            flexDirection: "column",

            "& > div": {
              borderTop: "1px solid rgba(250,250,250,.3)",
              padding: "15px 0",
              "&:first-of-type": {
                borderTop: "none",
              },
            },
          }}
        >
          <Grid sx={{ flex: 1 }}>
            <Container>
              <FooterMenu
                container
                sx={{
                  rowGap: "20px",
                  columnGap: "70px",
                }}
              >
                <Grid
                  sx={{
                    flex: {
                      xs: "",
                      md: 1,
                    },
                    flexBasis: {
                      xs: "100%",
                      md: "",
                    },
                    textAlign: "center",
                  }}
                >
                  <Box component={Link} to="/">
                    <img
                      alt="Logo"
                      src={Logo}
                      style={{ filter: "brightness(0) invert(1)" }}
                    />
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <IconButton
                      component={Link}
                      to="https://www.facebook.com/profile.php?id=61566536365458"
                      target="_blank"
                    >
                      <FacebookIcon sx={{ color: "white" }} />
                    </IconButton>
                    <IconButton
                      component={Link}
                      to="https://www.instagram.com/modul.in/"
                      target="_blank"
                    >
                      <InstagramIcon sx={{ color: "white" }} />
                    </IconButton>
                    <IconButton
                      component={Link}
                      to="https://x.com/InfoModulin"
                      target="_blank"
                    >
                      <FaXTwitter style={{ color: "white", fill: "white" }} />
                    </IconButton>
                    <IconButton
                      component={Link}
                      to="https://www.youtube.com/@MODULIN-g8d"
                      target="_blank"
                    >
                      <YouTubeIcon sx={{ color: "white" }} />
                    </IconButton>
                    {/* <IconButton>
                      <LinkedInIcon sx={{ color: "white" }} />
                    </IconButton> */}
                  </Box>
                </Grid>
                <Grid
                  sx={{
                    flex: 1,
                  }}
                >
                  <Box>
                    <Typography variant="h6">
                      {ResidenceMenuItems.name}
                    </Typography>
                    <ul>
                      {ResidenceSubMenu.map((item) => (
                        <li key={item.id}>
                          <Link to={item.link}>{item.name}</Link>
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Grid>
                <Grid
                  sx={{
                    flex: 1,
                  }}
                >
                  <Box>
                    <Typography variant="h6">
                      {CommercialMenuItems.name}
                    </Typography>
                    <ul>
                      {CommercialSubMenu.map((item) => (
                        <li key={item.id}>
                          <Link to={item.link}>{item.name}</Link>
                        </li>
                      ))}
                    </ul>
                  </Box>
                  <Box>
                    <Typography variant="h6">{CityMenuItems.name}</Typography>
                    <ul>
                      {CitySubMenu.map((item) => (
                        <li key={item.id}>
                          <Link to={item.link}>{item.name}</Link>
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Grid>
                <Grid
                  sx={{
                    flex: 1,
                  }}
                >
                  <Box>
                    <Typography variant="h6">
                      Retails
                    </Typography>
                    {/* <ul>
                      {CommercialSubMenu.map((item) => (
                        <li key={item.id}>
                          <Link to={item.link}>{item.name}</Link>
                        </li>
                      ))}
                    </ul> */}
                  </Box>
                  <Box>
                    <Typography variant="h6">{CityMenuItems.name}</Typography>
                    <ul>
                      {CitySubMenu.map((item) => (
                        <li key={item.id}>
                          <Link to={item.link}>{item.name}</Link>
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Grid>
                <Grid
                  sx={{
                    flex: 1,
                  }}
                >
                  <Box>
                    <Typography variant="h6">{OthersMenuItems.name}</Typography>
                    <ul>
                      {OthersSubMenu.map((item) => (
                        <li key={item.id}>
                          <Link to={item.link}>{item.name}</Link>
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Grid>
                <Grid
                  sx={{
                    flex: 1,
                  }}
                >
                  <Box>
                    <Typography variant="h6">Head Office</Typography>
                    <Typography>
                      Road No: 4, Plot No: 32/33, Industrial Estate, Ramanayya
                      Peta, Sarpavaram, Kakinada, 533005
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6">Phone No</Typography>
                    <Typography>+91 9281482112</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6">Email us</Typography>
                    <Typography>info@modulin.in</Typography>
                  </Box>
                </Grid>
              </FooterMenu>
            </Container>
          </Grid>
          <Grid sx={{ flex: 1 }}>
            <Container>
              <Grid
                container
                sx={{
                  justifyContent: {
                    xs: "center",
                    sm: "space-between",
                    // sm: "flex-start",
                  },
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Grid>
                  <CopyRights type="website" />
                </Grid>
                {/* <Grid
                  sx={{
                    fontSize: "12px",
                    color: `rgba(${theme.palette.text.white},.4)`,
                    "& a": {
                      color: `rgba(${theme.palette.text.white},.4)`,
                      textDecoration: "none",
                      textIndent: "5px",
                      display: "inline-block",
                    },
                  }}
                >
                  Design and developed by
                  <a href="https://tektreeinc.com/" target="_blank">
                    Tektree Inc.
                  </a>
                </Grid> */}
              </Grid>
            </Container>
          </Grid>
          <Grid sx={{ flex: 1 }}>
            <Container>
              <LineMenu container className="linemenu">
                <Typography variant="h6">{ResidenceMenuItems.name}</Typography>
                <ul>
                  {ResidenceSubMenu.map((item) => (
                    <li key={item.id}>
                      <Link to={item.link}>{item.name}</Link>
                    </li>
                  ))}
                </ul>
              </LineMenu>
            </Container>
          </Grid>
          <Grid sx={{ flex: 1 }}>
            <Container>
              <LineMenu container className="linemenu">
                <Typography variant="h6">{Offices.name}</Typography>
                <ul>
                  {Offices?.subMenu?.map((item) => (
                    <li
                      style={{
                        textTransform: "capitalize",
                      }}
                      key={item.id}
                    >
                      <Link to={item.link}>{item.name}</Link>
                    </li>
                  ))}
                </ul>
              </LineMenu>
            </Container>
          </Grid>
          <Grid sx={{ flex: 1 }}>
            <Container>
              <LineMenu container className="linemenu">
                <Typography variant="h6">{DeleveryOffer.name}</Typography>
                <Typography variant="caption">{DeleveryOffer.text}</Typography>
              </LineMenu>
            </Container>
          </Grid>
          <Grid sx={{ flex: 1 }}>
            <Container>
              <Typography
                sx={{
                  fontSize: "12px",
                  "& span": {
                    fontSize: "16px",
                    fontWeight: 600,
                  },
                }}
              >
                <span>Disclaimer :</span> {DeleveryOffer.desclaimer}
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </SiteFooter>
    </React.Fragment>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import DefaultLayout from "../Layout/DefaultLayout";
import {
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { UserTable } from "./UserTable";
import { POST_USER_AGENTS_ADMIN } from "../../../Service/ApiRoutes";
import axios from "axios";

const Users = () => {
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "slno",
      headerName: "Sl.No",
      minWidth: 150,
      editable: true,
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 120,
    },
    {
      field: "formtype",
      headerName: "Form Type",
      minWidth: 120,
      //editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
    },
  ];


  const Token = localStorage.getItem("TOKEN");
  const Role = localStorage.getItem("ROLE");
  const [workProfile, setworkProfile] = useState("");
  const [UserAgentData, setUserAgentData] = useState([]);
  const [Page, setPage] = useState(1);
  const [TotalPage, setTotalPage] = useState("");
  const HandleGetUSerAgents = () => {
    const Payload = {
      workProfile: workProfile,
      status: "",
      page: Page,
    };
    axios
      .post(POST_USER_AGENTS_ADMIN(), Payload, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
      .then(({ data }) => {
        console.log(data);
        setPage(data?.pagination?.users?.currentPage);
        setTotalPage(data?.pagination?.users?.totalPages);
        setUserAgentData(data?.data);
      });
  };

  useEffect(() => {
    HandleGetUSerAgents();
  }, [workProfile, Page]);
  console.log(UserAgentData?.total_users?.length);

  return (
    <React.Fragment>
      <DefaultLayout>
        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
          Users <span>({UserAgentData?.users?.length})</span>
        </Typography>
        <div
          className=""
          style={{
            maxWidth: "150px",
            marginTop: 2,
          }}
        >
          <label
            htmlFor="leaveId"
            style={{
              fontSize: ".9em",
            }}
            className="mb-2 fw500"
          >
            Work Profile
          </label>
          <FormControl fullWidth className="CustomSelectDropSmallGray">
            <InputLabel id="leaveId">Select</InputLabel>
            <Select
              labelId="leaveId"
              id="demo-simple-leave"
              label="Select"
              value={workProfile}
              name="loan_type"
              className="Custom_Select"
            >
              <MenuItem
                value={"Reset"}
                key={"Reset"}
                onClick={() => {
                  setworkProfile("");
                }}
              >
                Reset
              </MenuItem>
              {[
                "Architect",
                "Interior Designer",
                "Civil Engineer",
                "Carpenter",
                "Marketing Agent",
                "Client",
                "Others",
              ]?.map((Elem) => {
                return (
                  <MenuItem
                    value={Elem}
                    key={Elem}
                    onClick={() => {
                      setworkProfile(Elem);
                    }}
                  >
                    {Elem}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>

        <UserTable
          Data={UserAgentData?.users}
          TotalPage={TotalPage}
          setTotalPage={setTotalPage}
          Page={Page}
          setPage={setPage}
        />
        {/* <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            //autoHeight
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box> */}
      </DefaultLayout>
    </React.Fragment>
  );
};

export default Users;

import React from "react";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
//MUI
import {
  Box,
  Grid,
  Typography,
  Link as MuiLink,
  Button,
  List,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  OutlinedInput,
  Select,
} from "@mui/material";
//Custome Components
import PageBreadcrumbs from "../Breadcrumbs";
import IMG2 from "../../../Assets/images/partner/Im2.png";
//Images and Icons
import Dummy from "../../../Assets/dummy.jpg";
import theme from "../../../Theme";
import SectionTitle from "../SectionTitle";

const InnerBanner = ({
  commingSoon,
  type,
  title,
  title2,
  title3,
  title4,
  desc,
  image,
  bannertext,
  backgroundPosition,
  hideBread,
  isMobile,
  ToggleRefer,
  HandleRefer,
  maxWidth,
  margin,
}) => {
  console.log(title2);
  if (type === "fullimage") {
    return (
      <React.Fragment>
        <Grid
          container
          sx={{
            flexDirection: "column",
            marginLeft: "24px",
            marginRight: {
              xs: "24px",
              md: "0px",
            },
            marginBottom: { xs: "24px", md: "0px" },
            maxWidth: {
              xs: "calc(100% - 48px)",
              md: "calc(100% - 24px)",
            },
          }}
        >
          <Grid sx={{ marginTop: "120px" }}>
            <PageBreadcrumbs />
          </Grid>
          <Grid>
            <Box
              sx={{
                backgroundImage: `url(${image})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: backgroundPosition,
                backgroundSize: "cover",
                minHeight: "450px",
                marginLeft: {
                  xs: "0",
                  md: "0%",
                },
                boxShadow: "0px 60px 15px -48px rgba(0, 0, 0, 0.4)",
              }}
            ></Box>
          </Grid>
          {bannertext ? (
            <Grid>
              <Box
                sx={{
                  margin: margin,
                  marginTop: "50px",
                  maxWidth: maxWidth || "780px",
                }}
              >
                <Typography variant="h3">{title}</Typography>
                <Typography>{desc}</Typography>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </React.Fragment>
    );
  } else if (type === "fullimagePartner") {
    return (
      <React.Fragment>
        <Grid
          container
          sx={{
            flexDirection: "column",
            marginLeft: "0px",
            marginRight: {
              xs: "24px",
              md: "0px",
            },
            marginBottom: { xs: "0px", md: "0px" },
            maxWidth: {
              xs: "calc(100% - 0px)",
              md: "calc(100% - 0px)",
            },
          }}
        >
          <Grid>
            <Box
              sx={{
                backgroundImage: `url(${image})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: {
                  lg: backgroundPosition,
                  xs: "left",
                },
                backgroundSize: {
                  lg: "cover",
                  xs: "cover",
                },
                minHeight: "600px",
                "@media (max-width: 600px)": {
                  minHeight: "500px",
                  maxHeight: "500px",
                },
                marginLeft: {
                  xs: "0",
                  md: "0%",
                },
                // boxShadow: "0px 60px 15px -48px rgba(0, 0, 0, 0.4)",
              }}
            >
              <div
                className="FlexCenter"
                style={{
                  minHeight: "600px",
                  maxWidth: "100%",
                  margin: "auto",
                  "@media (max-width: 600px)": {
                    minHeight: "400px",
                    maxHeight: "400px",
                  },
                }}
              >
                {" "}
                {bannertext ? (
                  <Box
                    className="FlexCenter"
                    sx={{
                      minHeight: "450px",
                      "@media (max-width: 600px)": {
                        minHeight: "400px",
                        maxHeight: "400px",
                      },
                    }}
                  >
                    <Box
                      className=" h-100"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "0px",
                        maxWidth: "80%",
                        margin: "auto",
                        flexDirection: "column",
                        "@media (max-width: 600px)": {
                          maxWidth: "90%",
                        },
                      }}
                    >
                      <Typography
                        align="center"
                        variant={isMobile ? "h6" : "h3"}
                        sx={{
                          color: theme.palette.text.white1,
                        }}
                      >
                        {title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "1.3em",
                          color: theme.palette.text.white2,
                          "@media (max-width: 600px)": {
                            fontSize: ".9em",
                            mt: 2,
                          },
                        }}
                      >
                        {desc}
                      </Typography>
                      <button className="btnRed mt-2">SING UP NOW</button>
                    </Box>
                  </Box>
                ) : null}
              </div>
            </Box>

            <Box maxWidth={"75%"} m={"2em auto 0em auto"}>
              <PageBreadcrumbs />
            </Box>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else if (type === "fullimageMore") {
    return (
      <React.Fragment>
        <Grid
          container
          sx={{
            flexDirection: "column",
            marginLeft: "0px",
            marginRight: {
              xs: "24px",
              md: "0px",
            },
            marginBottom: { xs: "0px", md: "0px" },
            maxWidth: {
              xs: "calc(100% - 0px)",
              md: "calc(100% - 0px)",
            },
          }}
        >
          <Grid>
            <Box
              sx={{
                backgroundImage: `url(${image})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: {
                  lg: backgroundPosition,
                  xs: "left",
                },
                backgroundSize: {
                  lg: "cover",
                  xs: "cover",
                },
                minHeight: "500px",
                marginLeft: {
                  xs: "0",
                  md: "0%",
                },
                // boxShadow: "0px 60px 15px -48px rgba(0, 0, 0, 0.4)",
              }}
            >
              <div
                className="Grid2Custom"
                style={{
                  minHeight: "500px",
                  maxWidth: "95%",
                  margin: "auto",
                }}
              >
                {" "}
                {bannertext ? (
                  <Box
                    className="FlexCenter"
                    sx={{
                      minHeight: "450px",
                      "@media (max-width: 600px)": {
                        minHeight: "230px",
                        alignItems: "end",
                      },
                    }}
                  >
                    <Box
                      className=" h-100"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "start",
                        marginTop: "0px",
                        maxWidth: "800px",
                        flexDirection: "column",
                        "@media (max-width: 600px)": {
                          height: "auto",
                        },
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          color: theme.palette.text.orange,
                        }}
                      >
                        {title} {title2} {title3} {title4}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "1.4em",
                          mt: 1,
                          color: theme.palette.text.white1,
                        }}
                      >
                        {desc}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                <Box className="mt-4 ">
                  <Box
                    className=" px-2 h-100"
                    sx={{
                      marginTop: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      "@media (max-width: 600px)": {
                        marginTop: "-20px",
                        alignItems: "start",
                        width: "100%",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        background: "white",
                        padding: "2em 1em",
                        gap: "1em",
                        minHeight: "50%",
                        width: "400px",
                        borderRadius: "10px",
                        "@media (max-width: 600px)": {
                          width: "auto",
                          // padding: "1em",
                        },
                      }}
                    >
                      <Typography
                        align="center"
                        sx={{
                          fontSize: "1.2em",
                          fontWeight: 700,
                          textTransform : "capitalize"
                        }}
                      >
                        {/* Fill In your Friends Detail */}
                        {/* Join Us To Become a Agent */}
                        Join us an agent
                      </Typography>
                      <Typography
                        align="center"
                        sx={{
                          fontSize: ".8em",
                          // fontWeight: 600,
                        }}
                      >
                        Log in to unlock your referral benefits
                        {/* Please ensure you log in to enjoy the referral benefits */}
                        {/* Please ensure you log in to enjoy the referral benefits */}
                      </Typography>
                      <div className="FlexCenter  me-0 w-100 mt-4">
                        <button
                          // type="submit"
                          className="btnRed fw700"
                          type="button"
                          onClick={HandleRefer}
                        >
                          Refer As Agent
                        </button>
                      </div>
                      {/* <Formik initialValues={{}}>
                        {({ setFieldValue, handleSubmit }) => (
                          <Form>
                            <div className=" gap-3 m-0 mt-3">
                              <div className="">
                                <div className="fieldBox">
                                  <Field name="name">
                                    {({ field }) => (
                                      <TextField
                                        {...field}
                                        className="My_fieldBoxNormalBlack"
                                        fullWidth
                                        autoComplete="off"
                                        id="outlined-basic"
                                        label="Your Friend Name"
                                        variant="outlined"
                                      />
                                    )}
                                  </Field>
                                  <ErrorMessage
                                    name="employee_id"
                                    component="div"
                                    className="errors"
                                  />
                                </div>
                              </div>
                              <div className="">
                                <div className="fieldBoxCustom">
                                  <span
                                    style={{
                                      border:
                                        "2px solid rgba(47, 47, 47, 0.613)",
                                      padding: "9.1px",
                                      marginTop: ".6em",
                                      position: "absolute",
                                      borderTopLeftRadius: "5px",
                                      borderBottomLeftRadius: "5px",
                                    }}
                                  >
                                    +91
                                  </span>
                                  <Field name="number">
                                    {({ field }) => (
                                      <TextField
                                        {...field}
                                        className="My_fieldBoxNormalBlack1"
                                        fullWidth
                                        autoComplete="off"
                                        id="outlined-basic"
                                        label=""
                                        sx={{
                                          paddingLeft: "2.8em",
                                          borderLeft: 0,
                                        }}
                                        variant="outlined"
                                      />
                                    )}
                                  </Field>
                                  <ErrorMessage
                                    name="employee_id"
                                    component="div"
                                    className="errors"
                                  />
                                </div>
                              </div>

                              <div className="">
                                <Typography
                                  align="center"
                                  variant="body1"
                                  className="FlexCenter"
                                  sx={{
                                    mt: 3,
                                    fontSize: ".9em",
                                    fontWeight: 500,
                                    mb: 2,
                                  }}
                                >
                                  <AddIcon
                                    style={{
                                      fontSize: "1.2em",
                                    }}
                                  />{" "}
                                  Refer another friend
                                </Typography>
                                <div className="FlexCenter h-100 me-0 w-100 mt-0">
                                  <button
                                    // type="submit"
                                    className="btnRed fw700"
                                    type="button"
                                    onClick={(e) => {}}
                                  >
                                    Refer Now
                                  </button>
                                </div>
                                <Typography
                                  align="center"
                                  sx={{
                                    mt: 2,
                                    fontSize: ".8em",
                                    fontWeight: 700,
                                  }}
                                >
                                  Already Referred ?{" "}
                                  <Box
                                    sx={{
                                      display: "inline",
                                      color: "rgba(239, 46, 51, 1)",
                                    }}
                                  >
                                    Track Now
                                  </Box>
                                </Typography>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik> */}
                    </Box>
                  </Box>
                </Box>
              </div>
            </Box>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Grid
          container
          sx={{
            justifyContent: "space-between",
            marginLeft: "24px",
            marginRight: {
              xs: "24px",
              md: "0px",
            },
            marginBottom: { xs: "50px", md: "0px" },
            maxWidth: {
              xs: "calc(100% - 48px)",
              md: "calc(100% - 24px)",
            },
          }}
        >
          <Grid
            item
            xs="12"
            md="5"
            sx={{
              alignSelf: "end",
              marginBottom: "50px",
              marginTop: {
                xs: "120px",
              },
            }}
          >
            {commingSoon && (
              <Box
                height={"20vh"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                fontSize={"2em"}
                fontWeight={600}
              >
                Coming Soon
              </Box>
            )}
            <PageBreadcrumbs />
            <Typography
              variant="h3"
              sx={{
                textWrap: "balance",
                lineHeight: "1.2",
                marginBottom: "30px",
              }}
            >
              {title}
            </Typography>
            <Typography sx={{ textWrap: "balance" }}>{desc}</Typography>
          </Grid>
          <Grid
            item
            xs="12"
            md="6"
            sx={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              aspectRatio: "1/.7",
              boxShadow: {
                xs: "0px 60px 15px -48px rgba(0, 0, 0, 0.4)",
                md: "0px 60px 15px -48px rgba(0, 0, 0, 0.0)",
              },
            }}
          ></Grid>
        </Grid>
      </React.Fragment>
    );
  }
};

InnerBanner.defaultProps = {
  title: "Page Title",
  desc: "Page desc",
  image: Dummy,
  bannertext: true,
  backgroundPosition: "center",
};

export default InnerBanner;

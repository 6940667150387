import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

//MUI
import { Box, Container, Grid, SvgIcon, Typography } from "@mui/material";

//Custome Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBanner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import Section from "../../Component/HelperComponents/Section";
import SectionTitle, {
  SectionTitleFlex,
} from "../../Component/HelperComponents/SectionTitle";
import SectionScrolling from "../../Component/HelperComponents/SectionScrolling";
import CustomCarouselMain from "../../Component/HelperComponents/CustomCarousel/CustomCarouselMain";
import CustomThreeDCarousel from "../../Component/HelperComponents/CustomCarousel/CustomThreeDCarousel";
import { FromFieldStyles } from "../../Component/HelperComponents/CustomFormElements/CustomeFormElements";
import ConsultationFormSection from "../../Component/HelperComponents/ConsultationForm/ConsultationFormSection";
import RangeSet from "../../Component/HelperComponents/RangeType/RangeSet";
import LINE from "../../Assets/line.png";
//Image and Icons
import { kanist, anyonya, uttama, dot } from "../../Assets/icons/icons";
import BannerImage from "../../Assets/Rectangle93.jpg";
import BgImage from "../../Assets/images/bg-image/kitchen-outline.jpg";
//import BgConsultationFormSection from "../../Assets/bg-image/kitchen2.jpg";
import Dummy from "../../Assets/dummy.jpg";
//Data
import {
  bannerDetails,
  kitchenLayout,
  carouselMainSettings,
  ReasonsAmbienceKitchen,
  kitchenMaterials,
  ShutterFinishes,
  Handles,
  CountertopsBacksplash,
  PrallexDetails,
  Hardware,
  Appliances,
  SinkFaucet,
  DesignApproach,
  consultationFormSectionDetails,
} from "../../Data/ModularInteriors/Kitchens";
import theme from "../../Theme";

import {
  BannerDetails as BannerDetailsModularKitchenDesigns,
  Gallery as galleryModularKitchenDesigns,
} from "../../Data/DesignIdeas/ModularKitchenDesigns";

import {
  BannerDetails as BannerDetailsMasterBedroomDesigns,
  Gallery as galleryMasterBedroomDesigns,
} from "../../Data/DesignIdeas/MasterBedroomDesigns";
import {
  BannerDetails as BannerDetailsLivingRoomDesigns,
  Gallery as galleryLivingRoomDesigns,
} from "../../Data/DesignIdeas/LivingRoomDesigns";

import {
  BannerDetails as BannerDetailsTvUnitDesigns,
  Gallery as galleryTvUnitDesigns,
} from "../../Data/DesignIdeas/TvUnitDesigns";
import {
  BannerDetails as BannerDetailsKidsBedroomDesigns,
  Gallery as galleryKidsBedroomDesigns,
} from "../../Data/DesignIdeas/KidsBedroomDesigns";
import {
  BannerDetails as BannerDetailsDiningRoomDesigns,
  Gallery as galleryDiningRoomDesigns,
} from "../../Data/DesignIdeas/DiningRoomDesigns";
import {
  BannerDetails as BannerDetailsGuestBedroomDesigns,
  Gallery as galleryGuestBedroomDesigns,
} from "../../Data/DesignIdeas/GuestBedroomDesigns";
import {
  BannerDetails as BannerDetailsWorkstations,
  Gallery as galleryWorkstations,
} from "../../Data/DesignIdeas/Workstations";
import {
  BannerDetails as BannerDetailsOfficeTables,
  Gallery as galleryOfficeTables,
} from "../../Data/DesignIdeas/OfficeTables";
import {
  BannerDetails as BannerDetailsStorageUnits,
  Gallery as galleryStorageUnits,
} from "../../Data/DesignIdeas/StorageUnits";
import CustomGallery from "../../Component/HelperComponents/CustomGallery/CustomGallery";

function Kitchens() {
  const { pathname } = useLocation();

  const UpdateData = pathname?.split("/")?.filter((item) => {
    return item !== "modular-interiors" && item !== "";
  });
  console.log(UpdateData);
  //Modal Setup
  const [openModalForm, setOpenModalForm] = useState(false);
  const handleClickOpen = () => {
    setOpenModalForm(true);
  };
  const handleClose = (event, reason) => {
    //if (reason && reason == "backdropClick") return;
    setOpenModalForm(false);
  };
  //Modal Setup End

  const galleryData = galleryModularKitchenDesigns;
  function formatString(inputString) {
    // Split the input string into an array of words using hyphens as separators
    const words = inputString.split("/");

    // Capitalize the first letter of each word and join them with spaces
    const formattedString = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return formattedString;
  }

  //Form Style
  const classes = FromFieldStyles();
  const location = useLocation();
  return (
    <React.Fragment>
      <DefaultLayout>
        <InnerBanner
          type={bannerDetails.type}
          title={bannerDetails.title}
          desc={bannerDetails.desc}
          image={bannerDetails.image}
          bannertext={bannerDetails.bannertext}
        />

        <Section bgcolor="white" padding="100px 24px 20px 24px">

          <RangeSet
            link={location.pathname}
            title={bannerDetails.title}
            desc={bannerDetails.desc}
          />
        </Section>
      

        <Section bgcolor="255, 255,255" padding="0px 0 100px 24px">
          <SectionTitle
            title="Familiarize yourself with your kitchen layout"
            variant="h5"
          />
          <Typography sx={{ maxWidth: "480px" }}>
            Gain a comprehensive understanding of kitchen layouts, as it serves
            as the initial step towards designing the perfect kitchen
          </Typography>
          <CustomCarouselMain
            type="imagetitle"
            data={kitchenLayout}
            showbutton={true}
          />
          {/*kitchenMaterials */}
          <SectionTitle title="Key Module Materials" variant="h5" />
          <Typography sx={{ maxWidth: "480px" }}>
          Endure a lifetime with premium core materials, ensuring your kitchen withstands the test of time
          </Typography>
          <CustomCarouselMain
            type="imagetitlecont"
            data={kitchenMaterials}
            showbutton={true}
            showdesc={true}
          />
          {/*Captivating Aesthetics */}
          <SectionTitle
            title="Enhance Elegance With Captivating Aesthetics"
            variant="h5"
          />
          <Typography sx={{ maxWidth: "480px" }}>
            Personalize your kitchen's appearance to match your preferences.
            Select from a variety of finishes, handles, and backsplashes for a
            unique touch
          </Typography>
          <SectionTitle title="Shutter Finishes" variant="h6" />
          <CustomCarouselMain
            type="imagetitlecont"
            data={ShutterFinishes}
            showbutton={true}
            showdesc={true}
          />
          <SectionTitle title="Handles" variant="h6" />
          <CustomCarouselMain
            type="imagetitle"
            data={Handles}
            showbutton={true}
          />
          <SectionTitle title="Countertops and Backsplash" variant="h6" />
          <CustomCarouselMain
            type="imagetitlecont"
            data={CountertopsBacksplash}
            showbutton={true}
            showdesc={true}
          />
        </Section>

        {/* <SectionScrolling
          title={PrallexDetails.title}
          highlight={PrallexDetails.highlight}
          bgimg={PrallexDetails.image}
        /> */}

        <Section bgcolor="255, 255,255" padding="0px 0 100px 24px">
          <SectionTitle
            title="Enhance Elegance With Captivating Aesthetics."
            variant="h3"
          />

          <Typography sx={{ maxWidth: "480px" }}>
            Personalize your kitchen's appearance to match your preferences.
            Select from a variety of finishes, handles, and backsplashes for a
            unique touch
          </Typography>
          <SectionTitle title="Cabinet and Hardware" variant="h5" />
          <CustomCarouselMain
            type="imagetitlecont"
            data={Hardware}
            showbutton={true}
            showdesc={true}
          />
          <SectionTitle title="Appliances" variant="h5" />
          <CustomCarouselMain
            type="imagetitle"
            data={Appliances}
            showbutton={true}
          />
          <SectionTitle title="Sink and Faucet" variant="h5" />
          <CustomCarouselMain
            type="imagetitle"
            data={SinkFaucet}
            showbutton={true}
          />
        </Section>

        {/* <Section bgimg={BgImage}>
          <SectionTitle title="Unravel the inner workings of your kitchen" />
        </Section> */}

        {galleryData ? (
          <Section bgcolor="white" padding="0 24px 50px 24px">
            <SectionTitle
              title={UpdateData && `Ideas For Modular ${UpdateData[0]}`}
              shadowtext="Idea"
              // title2={UpdateData && `Ideas For Modular ${UpdateData[0]} Design`}
            />

            <CustomGallery
              data={galleryData}
              type="slideimage"
              handleClickOpen={handleClickOpen}
              loadmore={true}
            />
          </Section>
        ) : null}
        {/* <Section bgcolor="255,255,255">
          <SectionTitle title="The 5 kitchen zones" />
          <Typography sx={{ maxWidth: "780px" }}>
            Efficiently designed to streamline your cooking process, our kitchen
            plans ensure that task-related items are strategically positioned
            within their respective zones, minimizing unnecessary footsteps.
          </Typography>
        </Section> */}
        {/* <Section bgcolor="255,255,255" padding="100px 0 100px 24px">
          <SectionTitle
            title="Functional and Sophisticated Kitchen Design"
            variant="h5"
          />
          <Typography sx={{ maxWidth: "780px" }}>
            Experience a seamless and stress-free kitchen journey with the
            kitchen triangle, enabling effortless movement between cooking,
            cleaning, and storage zones.
          </Typography>
          <CustomCarouselMain
            type="imagetitle"
            data={DesignApproach}
            showbutton={true}
          />
        </Section> */}
        {/* <Section bgimg={BgImage} padding="100px 0 100px 24px">
          <Container>
            <Grid container sx={{ gap: "40px", marginBottom: "20px" }}>
              <Grid>
                <Box
                  sx={{
                    backgroundImage: `url(${Dummy})`,
                    width: {
                      xs: 220,
                      sm: 220,
                      md: 300,
                    },
                    aspectRatio: 1 / 1,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "300px 0px 0px 0px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      backgroundColor: "#B29054",
                      padding: "7px 14px 7px 7px",
                      borderRadius: "40px",
                      color: `rgba(${theme.palette.text.white},1)`,
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    <SvgIcon component={dot} />
                    Some text Some text
                  </Box>
                </Box>
              </Grid>
              <Grid>
                <Box
                  sx={{
                    backgroundImage: `url(${Dummy})`,
                    width: {
                      xs: 220,
                      sm: 220,
                      md: 300,
                    },
                    aspectRatio: 1 / 1,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "0px 0px 50px 0px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "20px",
                      right: "-50px",
                      backgroundColor: "#A72024",
                      padding: "7px 14px 7px 7px",
                      borderRadius: "40px",
                      color: `rgba(${theme.palette.text.white},1)`,
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    <SvgIcon component={dot} />
                    Some text
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                gap: "40px",
                margin: { xs: "0px 10px", sm: "0px 0px", md: "0px 00px" },
              }}
            >
              <Grid>
                <Box
                  sx={{
                    backgroundImage: `url(${Dummy})`,
                    width: {
                      xs: 220,
                      sm: 220,
                      md: 300,
                    },
                    aspectRatio: 1 / 1,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "0px 0px 0px 220px",
                  }}
                ></Box>
              </Grid>
              <Grid sx={{ flex: 1 }}>
                <Typography variant="h3" sx={{ maxWidth: "480px" }}>
                  Crafted with your comfort in mind.
                </Typography>
                <Typography sx={{ maxWidth: "670px" }}>
                  Bid farewell to back pains with our ergonomically designed
                  kitchens, featuring cabinets positioned at your ideal height
                  for optimal comfort.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Section> */}
        <Section
          bgimg={consultationFormSectionDetails.image}
          bgcolor={consultationFormSectionDetails.bgcolor}
        >
          <Container>
            <ConsultationFormSection
              title={consultationFormSectionDetails.title}
            />
          </Container>
        </Section>
        <Section bgimg={BgImage}>
          <SectionTitle
            title="Projects By Modulin"
            align="center"
            variant="h3"
          />
          <CustomThreeDCarousel data={ReasonsAmbienceKitchen} />
        </Section>
      </DefaultLayout>
    </React.Fragment>
  );
}
export default Kitchens;

import React from "react";

//Images and Icons
import bannerImage from "../../Assets/images/pooja-room/Pr06.webp";

const BannerDetails = {
  type: "fullimage",
  image: bannerImage,
  title: "Modular kitchen designs",
  desc: "Create a tranquil and sacred space for your prayers with Modulin’s modular Pooja Mandir designs. Crafted with care and attention to detail, our mandirs blend traditional aesthetics with modern modular functionality. Whether you need a small corner unit or a larger standalone structure, our designs offer elegance and serenity, providing the perfect space for daily worship.",
  bannertext: false,
};

////////////////////////////////////////////////
// List of image filenames to hide
const imagesToHide = ["Ushape06.jpg"];

// Function to import all images from a given context and exclude specified images
const importAllImages = (requireContext) =>
  requireContext
    .keys()
    .filter((key) => {
      const imageName = key.split("/").pop(); // Get the image name from the path
      return !imagesToHide.includes(imageName);
    })
    .map((key, index) => {
      const imageName = key.split("/").pop(); // Get the image name from the path
      const pathParts = key.split("/");
      const folderName =
        pathParts.length >= 2
          ? pathParts[pathParts.length - 2]
          : "Unknown Folder"; // Get the folder name or use "Unknown Folder"
      return {
        id: index + 1, // Generate unique ID using the index
        title: imageName.replace(/\.[^/.]+$/, ""), // Remove file extension for title
        imageUrl: requireContext(key),
        category: folderName,
      };
    });

// Import all images from the specified context
const Gallery = importAllImages(
  require.context(
    "../../Assets/images/pooja-room/new",
    true, // Include subdirectories
    /\.(png|jpe?g|webp)$/
  )
);
////////////////////////////////////////////////

export { BannerDetails, Gallery };

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
  useParams,
  Navigate,
} from "react-router-dom";
import "./App.css";
import "./Modal.scss";
//ScrollToTopOnRouteChange
import ScrollToTopOnRouteChange from "./ScrollToTopOnRouteChange";

//MUI
import theme from "./Theme";
import { ThemeProvider, createTheme } from "@mui/material";
//import { ThemeProvider, createTheme } from "@mui/material/styles";

// Pages ModularInteriors
// import ModularInteriors from "./Pages/ModularInteriors/ModularInteriors";
// import Kitchens from "./Pages/ModularInteriors/Kitchens";
// import Wardrobes from "./Pages/ModularInteriors/Wardrobes";
// import PoojaMandir from "./Pages/ModularInteriors/PoojaMandir";
// import StudyArea from "./Pages/ModularInteriors/StudyArea";
// import CrockeryUnits from "./Pages/ModularInteriors/CrockeryUnits";
// import TvUnits from "./Pages/ModularInteriors/TvUnits";
// import ModularInteriorsFilter from "./Pages/ModularInteriors/ModularInteriorsFilter";

// Pages FullHomeInteriors
// import FullHomeInteriors from "./Pages/FullHomeInteriors/FullHomeInteriors";
// import HomePlanning from "./Pages/FullHomeInteriors/HomePlanning";
// import HomeDesign from "./Pages/FullHomeInteriors/HomeDesign";
// import ProjectManagement from "./Pages/FullHomeInteriors/ProjectManagement";

//Pages Residence / DesignIdeas
// import DesignIdeas from "./Pages/DesignIdeas/DesignIdeas";
// import DesignIdeasPage from "./Pages/DesignIdeas/DesignIdeasPage";

//Pages Other Links
// import AboutUs from "./Pages/Others/AboutUs";
// import Careers from "./Pages/Others/Careers";
// import ContactUs from "./Pages/Others/ContactUs";
// import HowItWorks from "./Pages/Others/HowItWorks";
// import LoginRegister from "./Pages/Others/LoginRegister";
// import Offerings from "./Pages/Others/Offerings";
// import PartnerWithUs from "./Pages/Others/PartnerWithUs";
// import Policies from "./Pages/Others/Policies";
// import PriceCalculations from "./Pages/Others/PriceCalculations";
// import ReferAFriend from "./Pages/Others/ReferAFriend";

//Pages Commercial
// import Commercial from "./Pages/Commercial/Commercial";
// import Office from "./Pages/Commercial/Office";
// import Retails from "./Pages/Commercial/Retails";

// //Pages City
// import CityPages from "./Pages/Cities/CityPages";

//Dashboard
// import Dashboard from "./Pages/Dashboard/Dashboard";
// import Enquiries from "./Pages/Dashboard/Enquiries";
// import { UserReferAFriend } from "./Pages/Dashboard/ReferAFriend";
// import Profile from "./Pages/Dashboard/Profile";

//Pages
import {
  Home,
  ModularInteriors,
  Kitchens,
  Wardrobes,
  PoojaMandir,
  StudyArea,
  CrockeryUnits,
  TvUnits,
  ModularInteriorsFilter,
  FullHomeInteriors,
  HomePlanning,
  HomeDesign,
  ProjectManagement,
  DesignIdeas,
  DesignIdeasPage,
  AboutUs,
  Careers,
  ContactUs,
  HowItWorks,
  LoginRegister,
  Offerings,
  PartnerWithUs,
  Policies,
  PriceCalculations,
  ReferAFriend,
  Commercial,
  Office,
  Retails,
  CityPages,
  Dashboard,
  Enquiries,
  //UserReferAFriend,
  Profile,
} from "./Pages";
import DesignIdeasPagePerticular from "./Pages/DesignIdeas/DesignIdeasPagePerticular";
// tositfy
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Retial from "./Pages/Retail/Retial";
import { WebProfile } from "./Pages/Others/Profile/WebProfile";
import Users from "./Pages/Dashboard/users/Users";
import Consultation from "./Pages/Dashboard/Consultation/Consultation";
import Agent from "./Pages/Dashboard/Agent/Agent";
import Career from "./Pages/Dashboard/Career/Career";
import { NotFound } from "./NotFound";
function App() {
  const Token = localStorage.getItem("TOKEN");
  const Role = localStorage.getItem("ROLE");
  console.log(Token);
  console.log(Role);
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <Router>
        <ScrollToTopOnRouteChange />
        <Routes>
          <Route path="/" element={<Home />} />

          {/* Modular Interiors */}
          {/* <Route path="/modular-interiors" element={<ModularInteriors />} /> */}
          <Route path="/kitchens" element={<Kitchens />} />
          <Route path="/wardrobes" element={<Wardrobes />} />
          <Route path="/pooja-mandir" element={<PoojaMandir />} />
          <Route path="/study-area" element={<StudyArea />} />
          <Route path="/tv-units" element={<TvUnits />} />
          <Route path="/crockery-units" element={<CrockeryUnits />} />
          <Route
            path="/modular-interiors/:page/:category?/:layout?"
            element={<ModularInteriorsFilter />}
          />

          {/* Full Home Interiors */}
          <Route path="/full-home-interiors" element={<FullHomeInteriors />} />
          <Route
            path="/full-home-interiors/home-planning"
            element={<HomePlanning />}
          />
          <Route
            path="/full-home-interiors/home-design"
            element={<HomeDesign />}
          />
          <Route
            path="/full-home-interiors/project-management"
            element={<ProjectManagement />}
          />
          {/* Residence Links/ Design Ideas */}
          <Route path="/design-ideas" element={<DesignIdeas />} />
          <Route
            path="/design-ideas/:innerPages"
            element={<DesignIdeasPage />}
          />
          <Route
            path="/design-ideas/:innerPages/:name"
            element={<DesignIdeasPagePerticular />}
          />

          {/* Other Links */}
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          {console.log(Token && Role === "Super Admin")}
          <Route
            path="/login"
            element={Token ? <Navigate to={"/"} /> : <LoginRegister />}
          />
          <Route path="/offerings" element={<Offerings />} />
          <Route path="/partner-with-us" element={<PartnerWithUs />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="/price-calculations" element={<PriceCalculations />} />
          <Route path="/refer-a-customer" element={<ReferAFriend />} />

          {/* City */}
          <Route path="/city/:innerPages" element={<CityPages />} />
          {/* Commercial */}
          <Route path="/offices" element={<Commercial />} />
          <Route path="/offices/offices" element={<Office />} />
          <Route path="/offices/retails" element={<Retails />} />
          {/* Profile */}
          <Route path="/profile" element={<WebProfile />} />

          {/*  Retail */}
          <Route path="/retail" element={<Retial />} />
          {/* Dashboard */}
          <Route
            element={!Token ? <Navigate to="/login" /> : <Dashboard />}
            path="/user/dashboard"
          />
          <Route path="/user/enquiries" element={<Enquiries />} />
          {/* <Route path="/user/refer-a-friend" element={<UserReferAFriend />} /> */}
          {/* <Route path="/user/profile" element={<Profile />} /> */}
          {/*  Admin Routes */}
          {/* {Role === "Super Admin" && ( */}
          <>
            <Route
              element={
                !Token ? (
                  <Navigate to="/login" />
                ) : Role === "Super Admin" ? (
                  <Dashboard />
                ) : (
                  <NotFound />
                )
              }
              path="/admin/dashboard"
            />
            <Route
              path="/admin/enquiries"
              element={
                !Token ? (
                  <Navigate to="/login" />
                ) : Role === "Super Admin" ? (
                  <Enquiries />
                ) : (
                  <NotFound />
                )
              }
            />
            {console.log(!Token)}
            {console.log(Role !== "Super Admin")}
            {console.log(Role)}
            <Route
              path="/admin/users"
              element={
                !Token ? (
                  <Navigate to="/login" />
                ) : Role !== "Super Admin" ? (
                  <NotFound />
                ) : (
                  <Users />
                )
              }
            />
            <Route
              path="/admin/agents"
              element={
                !Token && Role !== "Super Admin" ? (
                  <Navigate to="/login" />
                ) : Role !== "Super Admin" ? (
                  <NotFound />
                ) : (
                  <Agent />
                )
              }
            />
            <Route
              path="/admin/consultations"
              element={
                !Token && Role !== "Super Admin" ? (
                  <Navigate to="/login" />
                ) : Role !== "Super Admin" ? (
                  <NotFound />
                ) : (
                  <Consultation />
                )
              }
            />
            <Route
              path="/admin/career"
              element={
                !Token && Role !== "Super Admin" ? (
                  <Navigate to="/login" />
                ) : Role !== "Super Admin" ? (
                  <NotFound />
                ) : (
                  <Career />
                )
              }
            />
            <Route path="/*" element={<NotFound />} />
          </>
          {/* )} */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;

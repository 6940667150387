import React, { useState } from "react";
//MUI
import theme from "../Theme";
import { Box, Container, Grid, Button, Typography } from "@mui/material";
import "../Component/HelperComponents/BannerComponent/Banner.scss";
//Custom Componemts
import DefaultLayout from "../Component/Layout/DefaultLayout";
import SectionTitle from "../Component/HelperComponents/SectionTitle";
import HomeBanner from "../Component/HelperComponents/BannerComponent/HomeBanner";
import HomeBannerNew from "../Component/HelperComponents/BannerComponent/HomeBannerNew";
import Section from "../Component/HelperComponents/Section";
import LineHeader from "../Component/HelperComponents/LineHeader";
import SectionScrolling from "../Component/HelperComponents/SectionScrolling";
import CustomCarouselMain from "../Component/HelperComponents/CustomCarousel/CustomCarouselMain";
import ConsultationForm from "../Component/HelperComponents/ConsultationForm/ConsultationForm";
import ExpertHands from "../Component/HelperComponents/ExpertHands/ExpertHands";
import ModalSection from "../Component/HelperComponents/ModalSection";
//Images and Icons
import SideLamp from "../Assets/images/bg-image/slide-lamps.png";
import BgForInspiration from "../Assets/images/bg-image/kitchen-outline.jpg";
import BgReasonsEntrust from "../Assets/images/kitchen/K2.jpg";

//Data
import {
  modularInteriors,
  fullHomeInteriors,
  PrallexDetails,
  forInspiration,
  TestimonialsSection,
  carouselSettingsHomeSlider,
} from "../Data/Home";

//Data
import {
  carouselSettings,
  whatWeOffer,
} from "../Data/ModularInteriors/ModularInteriors";

import {
  testimonialsSettings,
  Testimonials,
  whyChoosesSettings,
} from "../Data/Testimonials";
import { whyChooseSettings, WhyChooseUs } from "../Data/WhyChooseUsData";
import LayoutWhyChooseUs from "../Component/HelperComponents/CustomCarousel/Layouts/LayoutWhyChooseUs";
import useDeviceSize from "../Service/device-size";

const Home = () => {
  const { isMobile } = useDeviceSize();
  //Modal Setup
  const [openModalForm, setOpenModalForm] = useState(false);
  const handleClickOpen = () => {
    setOpenModalForm(true);
  };
  const handleClose = (event, reason) => {
    //if (reason && reason == "backdropClick") return;
    setOpenModalForm(false);
  };
  //Modal Setup End

  const sideLampBackgroundsize = {
    xs: "90px",
    sm: "100px",
    md: "auto",
    lg: "auto",
    xl: "auto",
  };

  return (
    <React.Fragment>
      <DefaultLayout>
        <Container maxWidth={false} disableGutters={true}>
          {/* <HomeBanner handleClickOpen={handleClickOpen} /> */}
          {/* OLD Banner <HomeBannerNew /> */}
          <HomeBannerNew />
          {/* Modular Interiors and Full Home Interiors */}
          <Section padding="70px 24px 0 24px" margin="0px 0 0 0" backimg={SideLamp} backgroundsize={sideLampBackgroundsize}>
            <Typography variant="h3" textAlign={"center"}>
              Your Ultimate Modular Destination
            </Typography>

            <SectionTitle
              title="What we Offer"
              shadowtext="What we Offer"
              variant="h5"
              margin="10px 0 0 0"
              padding="30px 0 0 0 "
              colors={"rgba(234, 190, 163, .2)"}
            />
            {/* <CustomCarouselMain
              data={modularInteriors}
              settings={carouselSettingsHomeSlider}
            /> */}
            <CustomCarouselMain
            sectionmargin={"40px 0 0 0 "}
              type="imagetitlecont"
              data={whatWeOffer}
              showcategories={true}
              showbutton={false}
              settings={carouselSettingsHomeSlider}
            />

            {/* <SectionTitle
              title="Full Home Interiors"
              shadowtext="Full Home Interiors"
              variant="h5"
              />
              <CustomCarouselMain
              data={fullHomeInteriors}
              settings={carouselSettingsHomeSlider}
              /> */}
          </Section>
          {/* Modular Interiors and Full Home Interiors End */}
          {/* Parallax Image Scrolling */}
          {/* <Section bgcolor="white" padding="">
            <SectionScrolling
              title={PrallexDetails.title}
              highlight={PrallexDetails.highlight}
              bgimg={PrallexDetails.image}
            />
          </Section> */}
          {/* Parallax Image Scrolling End */}
          {/* Inspiration */}
          <Section padding='100px 24px' margin="0" bgimg={BgForInspiration}>
            <SectionTitle title="For Inspiration" shadowtext="Ideas" />
            <CustomCarouselMain
              type="imagetitlecontOne" // add from what we offer
              data={forInspiration}
              showcategories={true}
              linkcategories={true}
              settings={carouselSettingsHomeSlider}
            />
          </Section>
          {/* Inspiration End */}
          {/* Reasons Entrust */}
          <Section
            bgimg={BgReasonsEntrust}
            bgcolor="transparent"
            // padding="0"
            padding="20px"
            bgposition="center center"
          >
            <Grid container>
              <Grid item xs={12} md={5} lg={7}></Grid>
              <Grid item xs={12} md={7} lg={5} sx={{ position: "relative" }}>
                <Box
                  sx={{
                    width: "calc(100% - 30px)",
                    maxWidth: "450px",
                    margin: "0px auto",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      backgroundColor: `rgba(${theme.palette.text.default},.9)`,
                      // bottom: "-50px",
                      bottom: "0px",
                      width: "100%",
                      minHeight: "200px",
                      // padding: "60px 35px",
                      padding: "40px 35px",
                      boxSizing: "border-box",
                      color: `rgba(${theme.palette.text.white},1)`,
                      borderRadius: "10px",
                      fontSize: ".9em",
                    }}
                  >
                    <LineHeader
                      fontSize={isMobile ? "16px" : ""}
                      title="Your"
                      title2="Interior Dreams in Our Expert Hands"
                      type="light"
                    />
                    <ExpertHands
                      fontSize1={isMobile ? "45px" : ""}
                      fontSize={isMobile ? "14px" : ""}
                    />

                    <Box sx={{ textAlign: "center", margin: "20px 0 0 0" }}>
                      <Button
                        variant="contained"
                        size={isMobile ? "small" : "large"}
                        sx={{
                          color: `rgba(${theme.palette.text.white},1)`,
                          padding: isMobile ? "12px 30px" : "12px 40px",
                          boxSizing: "border-box",
                          textWrap : "nowrap"
                        }}
                        onClick={handleClickOpen}
                      >
                        Get free consultation
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Section>
          {/* Reasons Entrust End */}
          {/* Why Choose Us */}
          <Section
            backimgposition={TestimonialsSection.backimgposition}
            backgroundsize={TestimonialsSection.backgroundsize}
          >
            <Box maxWidth={"1250px"} m="auto">
              <Grid container>
                <Grid item xs={13} md={6}>
                  <SectionTitle title="Why Choose Us" />
                </Grid>
                <Grid></Grid>
              </Grid>
              <Grid container m="auto" columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                {WhyChooseUs?.map((item) => (
                  <Grid item lg={3} xs={12} md={6}>
                    <LayoutWhyChooseUs
                      name={item?.name}
                      ICON={item?.imageUrl}
                      rating={item?.rating}
                      mainTitle={item?.mainTitle}
                      title={item?.title}
                      image={item?.data?.image}
                      content={item?.content}
                      desc={item?.desc}
                      minH={"230px"}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Section>
          {/* Why Choose US */}
          {/* Testimonials */}
          <Section
            padding={"0 0px 50px 0px"}
            backimg={TestimonialsSection.backimg}
            backimgposition={TestimonialsSection.backimgposition}
            backgroundsize={TestimonialsSection.backgroundsize}
          >
            <Container>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <LineHeader
                    fontSize={isMobile ? "14px" : ""}
                    title="Hear the Stories and Testimonials"
                    title2="That Showcase Our Commitment to Excellence."
                    col="primary"
                  />
                  <SectionTitle title="Let Our Clients Speak" />
                </Grid>
                <Grid></Grid>
              </Grid>
              <Grid container>
                <Grid>
                  <CustomCarouselMain
                    data={Testimonials}
                    type={"testimonials"}
                    settings={testimonialsSettings}
                  />
                </Grid>
              </Grid>
            </Container>
          </Section>
          {/* Testimonials End */}
        </Container>
      </DefaultLayout>
      <ModalSection open={openModalForm} onClose={handleClose}>
        <ConsultationForm onClose={handleClose} />
      </ModalSection>
    </React.Fragment>
  );
};
export default Home;

import React, { useState } from "react";
import { Link } from "react-router-dom";
//MUI
import theme from "../../../../Theme";
import {
  Box,
  Typography,
  List,
  ListItem,
  SvgIcon,
  Button,
  Rating,
  Avatar,
  CardHeader,
} from "@mui/material";
//Owl Carousel
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { makeStyles } from "@mui/styles";
import ModaalDark from "../../Modal/ModalDark";
import useDeviceSize from "../../../../Service/device-size";

const useStyles = makeStyles({
  linkWrapper: {
    color: `rgba(${theme.palette.text.default},1)`,
    textDecoration: "none",
    display: "block",
    height: "100%",
  },
  imgContentDivWrapper: {
    marginBottom: "40px",
    height: "calc(100% - 40px)",
    backgroundColor: `rgba(${theme.palette.text.white},.8)`,
    "&:hover $imgDiv": { transform: "scale(1.2) rotate(3deg)" },
  },
  imgDivWrapper: {
    overflow: "hidden",
    position: "relative",
  },
  imgDiv: {
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover", // or 'contain' for different scaling behavior
    backgroundPosition: "center center",
    transition: "all ease 1s",
  },
  titleOver: {
    position: "absolute",
    bottom: "40px",
    padding: "10px 30px 10px 10px",
    backgroundColor: `rgba(${theme.palette.text.white},.8)`,
    borderRadius: "0 5px 5px 0",
    color: `rgba(${theme.palette.text.default},1)`,
  },
  contentDivWrapper: {
    padding: "20px",

    boxSizing: "border-box",
  },
  StyledList: {
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    marginBottom: "40px",
    "& li": {
      listStyleType: "none",

      "& span, a": {
        display: "block",
        fontSize: "14px",
        listStyleType: "none",
        padding: "10px 25px",
        backgroundColor: "#D3DCDC",
        borderRadius: "50px",
        color: `rgba(${theme.palette.text.default},1)`,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      },
      "& a": {
        textDecoration: "none",
        backgroundColor: "#A8BFBF",
        transition: "all ease 1s",
        "&:hover": {
          backgroundColor: "#D3DCDC",
        },
      },
    },
  },
});

const LayoutImageOnlyOfRet = ({ link, image, asratio, data }) => {
  console.log(link);
  const classes = useStyles(); //Custom Style
  const { isMobile } = useDeviceSize();
  const [isView, setisView] = useState(false);
  const ToggleIsView = () => setisView(!isView);
  console.log(data);
  console.log(image);
  return (
    <React.Fragment>
      {isView && (
        <ModaalDark
          anchorEl={true}
          borderRadius={"5px"}
          handleClose={ToggleIsView}
        >
          <div className="outer">
            <div
              className=""
              style={{
                position: "relative",
                // maxWidth: "80%",
                // maxWidth: "cal(90vw - 200px)",
                maxWidth: isMobile ? "90vw" : "80vw",
              }}
            >
              <button
                style={{
                  margin: 0,
                  position: "absolute",
                  right: 0,
                  top: 0,
                  fontSize: "1.1em",
                  color: "black",
                  padding: "5px",
                }}
                onClick={ToggleIsView}
              >
                Close
              </button>

              <OwlCarousel
                className="owl-theme GallryOwl"
                items={1}
                dots={false}
                nav={true}
              >
                {data?.map((imgs) => (
                  <div
                    style={{
                      width: isMobile ? "100%" : "100%",
                      minWidth: isMobile ? "400px" : "1200px",
                    }}
                  >
                    <img
                      src={imgs?.imageUrl}
                      alt={""}
                      width={isMobile ? "auto" : "100%"}
                      minWidth={isMobile ? "400px" : "1200px"}
                      // height={isMobile ? "300px" : "94vh"}
                      style={{
                        height: isMobile ? "300px" : "80vh",
                      }}
                    />
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </ModaalDark>
      )}

      <Box
        onClick={ToggleIsView}
        component={link ? Link : "div"}
        to={link}
        className={classes.linkWrapper}
      >
        <Box
          className={classes.imgContentDivWrapper}
          sx={{
            boxShadow: "0px 60px 15px -48px rgba(0, 0, 0, 0.4)",
          }}
        >
          <Box
            className={classes.imgDivWrapper}
            sx={{
              aspectRatio: asratio,
              margin: "0px",
              width: "100%",
            }}
          >
            <Box
              className={classes.imgDiv}
              sx={{
                backgroundImage: `url(${image})`,
              }}
            ></Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default LayoutImageOnlyOfRet;

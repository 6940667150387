import React from "react";
//MUI
import theme from "../../Theme";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import LINE from "../../Assets/line.png";
import useDeviceSize from "../../Service/device-size";
const SectionTitle = (props) => {
  const { isMobile } = useDeviceSize();
  console.log(props.colors);
  return (
    <Typography
      variant={props.variant}
      sx={{
        position: "relative",
        lineHeight: "2",
        // overflow: "clip",
        overflow: "visible",
        textAlign: props.align,
        margin: props.margin,
        padding: props.padding,
        textTransform: "capitalize",
        fontSize: props.fontSize,
        fontWeight: props.fontWeight,
        "&::after": {
          content: `"${props.shadowtext}"`,
          position: "absolute",
          color: props.colors || `rgba(${theme.palette.text.default},.05)`,
          pointerEvents: "none",
          whiteSpace: "nowrap",
          fontSize: isMobile ? "35px" : props.fontSize || "80px",
          fontWeight: "700",
          zIndex: 1,
          bottom: isMobile ? "17px" : "-30px",
          left: props.align === "center" ? "50%;" : "-5px;",
          transform:
            props.align === "center" ? "translateX(-50%)" : "translateX(0)",
        },
      }}
    >
      {props.title}
    </Typography>
  );
};

SectionTitle.defaultProps = {
  variant: "h3",
  align: "left",
  shadowtext: "",
  margin: "0 0 0 0",
};

export default SectionTitle;

export const SectionTitleFlex = (props) => {
  SectionTitleFlex.defaultProps = {
    variant: "h3",
    align: "left",
    shadowtext: "",
    margin: "0 0 0 0",
  };

  return (
    <Box display={"flex"} alignItems={"center"} gap={2}>
      <Typography
        variant={"h3"}
        sx={{
          position: "relative",
          lineHeight: "2",
          overflow: "clip",
          textAlign: "left",
          margin: "0",
          textTransform: "capitalize",
          "&::after": {
            content: `"${props.shadowtext}"`,
            position: "absolute",
            color: `rgba(${theme.palette.text.default},.05)`,
            pointerEvents: "none",
            whiteSpace: "nowrap",
            fontSize: "80px",
            fontWeight: "700",
            zIndex: 1,
            bottom: "-30px",
            left: props.align === "center" ? "50%;" : "-5px;",
            transform:
              props.align === "center" ? "translateX(-50%)" : "translateX(0)",
          },
        }}
      >
        {props.title}
      </Typography>
      <img src={LINE} alt="" width={"auto"} height={"30px"} />
      <Typography
        variant={"h3"}
        sx={{
          position: "relative",
          lineHeight: "2",
          overflow: "clip",
          textAlign: "left",
          margin: "0",
          "&::after": {
            content: `"${props.shadowtext}"`,
            position: "absolute",
            color: `rgba(${theme.palette.text.default},.05)`,
            pointerEvents: "none",
            whiteSpace: "nowrap",
            fontSize: "80px",
            fontWeight: "700",
            zIndex: 1,
            bottom: "-30px",
            left: props.align === "center" ? "50%;" : "-5px;",
            transform:
              props.align === "center" ? "translateX(-50%)" : "translateX(0)",
          },
        }}
      >
        {props.title2}
      </Typography>
    </Box>
  );
};

import React from "react";
//MUI
import { Box, Container, Grid, SvgIcon, Typography } from "@mui/material";
import RangeType from "./RangeType";

//Image and Icons
import { kanist, uttama, athyuttama, dot } from "../../../Assets/icons/icons";
import ATHI from "../../../Assets/slide/rightLogo/blackLogo/Ath.png";
import UTTHAM from "../../../Assets/slide/rightLogo/Uttam.png";
import KANISHT from "../../../Assets/slide/rightLogo/blackLogo/kan.png";
import useDeviceSize from "../../../Service/device-size";
const RangeSet = (props, { link, title, desc }) => {
  const { isMobile } = useDeviceSize();
  const kanistLink = `${props?.link}/kanist`;
  const anyonyaLink = `${props?.link}/anyonya`;
  const uttamaLink = `${props?.link}/uttama`;
  console.log(props?.title);

  const MobileHead = {
    one: (
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: ".9em",
          mt: 2,
          mb: 1,
        }}
      >
        CORE MATERIAL :
      </Typography>
    ),
    two: (
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: ".9em",
          mt: 2,
          mb: 1,
        }}
      >
        FINISHES :
      </Typography>
    ),
    three: (
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: ".9em",
          mt: 2,
          mb: 1,
        }}
      >
        HARD WARE :
      </Typography>
    ),
    four: (
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: ".9em",
          mt: 2,
          mb: 1,
        }}
      >
        ACCESSORIES :
      </Typography>
    ),
  };
  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Container sx={{ marginBottom: "20px" }}>
          <Typography
            variant="h5"
            textAlign={"center"}
            sx={{
              textWrap: "balance",
              marginBottom: "20px",
              textTransform: "capitalize",
              "@media (max-width: 600px)": {
                fontSize: "20px",
              },
            }}
          >
            {props?.title}
          </Typography>
          <Typography
            textAlign={"center"}
            sx={{
              fontWeight: "500",
              mb: 4,
              fontSize: ".9em",
            }}
          >
            {props?.desc}
          </Typography>
        </Container>
        <div
          className=""
          style={{
            position: "relative",
          }}
        >
          <div style={props?.flexSet}>
            {!isMobile && (
              <div
                className=""
                style={{
                  position: "absolute",
                  top: "160px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: ".9em",
                    mb: 2,
                    minHeight: "50px",
                  }}
                >
                  CORE MATERIAL :
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: ".9em",
                    mb: 2,
                    minHeight: "60px",
                  }}
                >
                  FINISHES :
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: ".9em",
                    mb: 2,
                    minHeight: "90px",
                  }}
                >
                  HARD WARE :
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: ".9em",
                    mb: 2,
                    minHeight: "30px",
                  }}
                >
                  ACCESSORIES :
                </Typography>
              </div>
            )}
            <Grid
              container
              maxWidth={!isMobile ? "85%" : "100%"}
              margin={"auto"}
            >
              <Grid item xs={12} sm={12} md={4} sx={{ textAlign: "center" }}>
                <RangeType
                  icon={kanist}
                  // title="Kanist"

                  IMG={KANISHT}
                  head={isMobile && MobileHead}
                  desc="The affordable category that delivers value through simplicity and robustness, making it a practical choice for budget-conscious customers who need durable and efficient solutions"
                  pathto={kanistLink}
                  Finish={
                    "Pu -Gloss/Matt, Veneer, Corain Shutters, Lacquered Glass, Acrylic, Laminate - Matt pro, Laminate - Matt/Glossy"
                  }
                  Hardware={
                    "Basic handles, Basic Profile Handles, Soft Closing hindges, HUWIL DIO Liftups"
                  }
                  Access={
                    " Baisc Tandoms, Stainless steel Baskets - Cups / Thali / Glass, Bottle Pullouts, Dustbin / Detergent Holders, PVC Cutlery organisers"
                  }
                  coreMaterial={"BWP PLY, HDHMR"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} sx={{ textAlign: "center" }}>
                <RangeType
                  head={isMobile && MobileHead}
                  icon={uttama}
                  // title="Uttam"
                  width={"96px"}
                  IMG={UTTHAM}
                  desc="A moderate category ideal for those seeking refined design and dependable quality within a moderate budget, combining style and practicality for a sophisticated yet cost-effective home transformation"
                  col2="#FEAB66"
                  pathto={anyonyaLink}
                  Hardware={
                    "Stylish profile handles, Surface Mounted stylish handles, soft Closing hindges, Liftups"
                  }
                  Finish={
                    "Laminate Matt / Glossy, Laminate matt pro, Acrylic, Acryshine, PU Finish - Gloss / Matt, Veneer, Aluminium Profile Shutters"
                  }
                  Access={
                    "Tandoms-plane, Stainless Baskets- cups / Thali / Glass, Wicker Baskets, Bottle Pullouts, Single foldable Pantry, Shutter foldable-Dustbin, Detergents Holders / Pullouts, Corner-Courosel, S Corners, PVC Cutlery Organisers, Mid-way Holders"
                  }
                  coreMaterial={"BWR, BWP, MDF, HDHMR"}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} sx={{ textAlign: "center" }}>
                <RangeType
                  head={isMobile && MobileHead}
                  icon={uttama}
                  width={"140px"}
                  // title="Athyuttama"
                  IMG={ATHI}
                  desc="Experience luxury with Athyuttam’s premium modular interiors, where high-quality materials, expert craftsmanship, and advanced technology come together to create a unique and stunning living space"
                  col2="#FF7C7C"
                  pathto={uttamaLink}
                  Finish={
                    "Matt Laminate, Glossy laminate, Acrylic Finish, Matt pro, Prestine PU Matt, Prestine PU Gloss"
                  }
                  Access={
                    "Tandems-plane/Glass, Stainless Baskets- cups / Thali / Glass, Wicker Baskets,Premium Bottle Pullouts, Premium Pantry, Premium Wet / Dry Dustbin Pullouts, Detergents Pullouts, Corner-Courosel, Cutlery Organisers, Plate Organisers, Mid-way Holders, Wooden Organisers in Tandoms"
                  }
                  Hardware={
                    "Handles less - Gola Profile, Premium profile, Stainless Steel Soft Closing Hinges, I Move Mechanism, German Liftups, Sensor-Touch Controls"
                  }
                  coreMaterial={"Architect Ply, BWP, HDF, MDF"}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

RangeSet.defaultProps = {
  title: "add title",
  desc: "add desc",
  // css
  flexSet: {
    display: "flex",
  },
  bgcolor: "241,241,241,.85",
  padding: "100px 24px",
  bgimg: "none",
  backimg: "none",
  backimgrepeat: "no-repeat",
  // backimgposition: "50px 0px",
  backimgposition: "top right",
  backgroundsize: "auto",
  bgposition: "top center",
  height: "auto",
  margin: "auto",
};

export default RangeSet;

import React from "react";

import Slide01 from "../../Assets/dummy.jpg";

import BannerImage from "../../Assets/images/pooja-room/Pr03.jpg";
import BgPrallexDetails from "../../Assets/images/pooja-room/Pr01.jpg";

//typesPoojaroom
import typesPoojaroomSlide1 from "../../Assets/images/pooja-room/Pr01.jpg";
import typesPoojaroomSlide2 from "../../Assets/images/pooja-room/Pr02.jpg";

// Bg Consultation Form Section
import BgConsultationFormSection from "../../Assets/images/pooja-room/Pr10.jpg";

// Parallex
import BgScrolling from "../../Assets/images/pooja-room/Pr07.jpg";

const bannerDetails = {
  type: "fullimage",
  title: "Transform Your Space with Divine Pooja Mandir Designs",
  desc: "Infuse spirituality into your home with Modulin’s divine modular Pooja Mandir designs. Crafted with elegance and reverence, our mandirs blend traditional charm with contemporary style, creating a serene space for your daily prayers",
  image: BannerImage,
  bannertext: false,
};

const typesPoojaroom = [
  {
    id: 1,
    title: "Traditional Pooja Mandir Style",
    imageUrl: typesPoojaroomSlide1,
    desc: "Embrace the timeless charm of a traditional pooja room style. This style often incorporates ornate carvings, intricate detailing, and rich wooden finishes. Traditional elements like brass lamps, incense holders, and a sacred idol form the focal point of the room.",
  },
  {
    id: 2,
    title: "Traditional Pooja Mandir Style",
    imageUrl: typesPoojaroomSlide2,
    desc: "space optimization. Our wall-mounted crockery units not only provide a stylish storage solution but also free up valuable floor space, making them an ideal choice for smaller kitchens or dining areas.",
  },
];

const PrallexDetails = {
  title:
    "Upgrade your kitchen to modular perfection, where convenience and style harmonize flawlessly.",
  highlight: "modular perfection",
  image: BgScrolling,
};

const consultationFormSectionDetails = {
  image: BgConsultationFormSection,
  title: "Transform your dream interiors into reality with a single click",
  bgcolor: "0,0,0,.2",
};

export {
  bannerDetails,
  typesPoojaroom,
  PrallexDetails,
  consultationFormSectionDetails,
};

import React from "react";
//Custom Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBannner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import Section from "../../Component/HelperComponents/Section";
import { Container, Typography, Grid } from "@mui/material";
import About from "../../Assets/about.jpg";
import CeoBoy from "../../Assets/about/CeoBoy.png";
import CeoGirl from "../../Assets/about/CeoGirl.png";
import vision1 from "../../Assets/about/vision1.png";
import vision2 from "../../Assets/about/vision2.png";
import { Box } from "@mui/system";
import useDeviceSize from "../../Service/device-size";
const AboutUs = () => {
  const { isMobile } = useDeviceSize();
  return (
    <React.Fragment>
      <DefaultLayout>
        {/* <InnerBannner
          image={About}
          title={"About Us"}
          desc={
            "Modular interiors have become a game-changer in the Construction Industry, offering unmatched versatility, functionality & affordability. At Modulin, we fully embrace the potential of modular design to transform spaces into extraordinary works of art. Our modular interior solutions are meticulously crafted to optimize space utilization, enhance flexibility, and bring efficiency to your environment. "
          }
        /> */}
        <Section bgcolor={"218,93,13,75%"} padding={"150px 24px 50px 24px"}>
          <Typography align="center" variant="h3" color={"white"}>
            Who We Are
          </Typography>
          <Box className={"Grid2 gap-4 mt-4"}>
            <Box className="FlexCenter" flexDirection={"column"}>
              <img
                src={CeoBoy}
                alt=""
                style={{
                  marginBottom: 5,
                  width: "100px",
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 1,
                  color: "white",
                }}
              >
                Co-Founder and CEO
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  maxWidth: isMobile ? "90%" : "70%",
                  margin: "auto",
                  fontWeight: 500,
                  fontSize: ".9em",
                  color: "white",
                  textAlign: "center",
                }}
                >
                As the co-founder and CEO of Modulin, Mailapalli Krishna Prasad
                brings over 15 years of extensive experience in the interiors,
                architecture, and modular interiors industry. His deep
                understanding of design and craftsmanship drives our commitment
                to excellence. Krishna’s visionary leadership inspires our team
                to innovate and create spaces that are not only functional but
                also reflect the unique style of our clients. His goal is to
                push the boundaries of modular design, making it accessible and
                appealing to all.
              </Typography>
            </Box>
            <Box
              className="FlexCenter"
              flexDirection={"column"}
              justifyContent={"start"}
              >
              <img
                src={CeoGirl}
                alt=""
                style={{
                  marginBottom: 5,
                  width: "100px",
                }}
                />
              <Typography variant="h6" sx={{ color: "white" }}>
                Co-Founder 
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  maxWidth: isMobile ? "90%" : "70%",
                  margin: "0 auto",
                  fontWeight: 500,
                  fontSize: ".9em",
                  marginTop: 1,
                  textAlign: "center",
                  color: "white",
                }}
              >
                As a Co-founder  of Modulin, Mailapalli Kavitha is a new
                leader in the modular interiors field, overseeing administration
                and operations. With support from Krishna Prasad, they drive
                Modulin’s vision for quality and innovation
              </Typography>
            </Box>
          </Box>
        </Section>
        <Section bgcolor={"255, 255,255,1"} padding={"100px 24px 50px 24px"}>
          <Box className={"Grid2 gap-4 mt-4"}>
            <Box className="FlexCenter" flexDirection={"column"}>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 1,
                  fontSize: "29px",
                  fontWeight: 500,
                  color: "#333333",
                }}
              >
                Our Vision
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  maxWidth: "400px",
                  margin: "10px auto",
                  fontWeight: 600,
                  fontSize: "1em",
                  textAlign: "center",
                }}
              >
                Crafting innovative & affordable modulars with exceptional
                design & modern technology
              </Typography>
              <img
                src={vision1}
                alt=""
                style={{
                  marginBottom: 5,
                  width: "150px",
                }}
              />
            </Box>
            <Box className="FlexCenter" flexDirection={"column"}>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 1,
                  fontSize: "29px",
                  fontWeight: 500,
                  color: "#333333",
                }}
              >
                Our Mission
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  maxWidth: "400px",
                  margin: "10px auto",
                  fontWeight: 600,
                  fontSize: "1em",
                  textAlign: "center",
                }}
              >
                Driven to integrate Industry experts, strategic materials &
                precise machinery
              </Typography>
              <img
                src={vision2}
                alt=""
                style={{
                  marginBottom: 5,
                  width: "150px",
                }}
              />
            </Box>
          </Box>
        </Section>

        <Section bgcolor={"241, 241,241,85%"} padding={"100px 24px 50px 24px"}>
          <Box className={"Grid2 gap-0 mt-4"}>
            <Box className="FlexCenter" flexDirection={"column"}>
              <img
                src={About}
                alt=""
                style={{
                  marginBottom: 5,
                  width: isMobile ? "100%" : "70%",
                }}
              />
            </Box>
            <Box
              className="FlexCenter"
              flexDirection={"column"}
              justifyContent={""}
              sx={{
                marginLeft: "20px",
                alignItems: "flex-start",
                "@media (max-width: 600px)": {
                  marginLeft: "0px",
                  alignItems: "start",
                  marginTop: 2,
                },
              }}
            >
              <Typography
                // variant="h3"
                sx={{
                  marginBottom: 1,
                  // fontSize: "29px",
                  color: "#333333",
                  fontWeight: 600,
                  fontSize: isMobile ? "24px" : "30px",
                }}
              >
                The Modulin Experience
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  maxWidth: "450px",
                  fontWeight: 500,
                  fontSize: ".9em",
                  textAlign: "start",
                }}
              >
                At Modulin, we believe in delivering an exceptional customer
                experience from start to finish. From your first consultation to
                the final installation, we’re with you every step of the way.
                Our personalized approach ensures that we understand your needs,
                deliver on our promises, and leave you with an interior you’ll
                love for years to come.
              </Typography>
            </Box>
          </Box>
        </Section>

        {/* <Section bgcolor={""}>
          <Container maxWidth={"xl"}>
            <Typography></Typography>
            <Typography>
              We draw inspiration from diverse sources to create spaces that are
              as distinctive as they are functional. Whether it's a residential
              masterpiece or a commercial marvel, our team of talented
              professionals will transform your vision into reality.
            </Typography>
            <Typography>
              ID & Er. Krishna Prasad M. (Member IIID/ACCEI/CREDAI) is the
              visionary behind Modulin, is an esteemed Interior Designer with an
              extensive career spanning over 15+ years. His expertise in the
              field of interiors and architecture encompasses a wide range of
              domestic and international projects.
            </Typography>
            <Typography>
              Our success lies in our exceptional workforce. At Modulin, we have
              always sought out the most inspired and talented professionals in
              the industry to represent our brand. It is through this collective
              endeavour that our projects will continue to stand as lasting
              testimonials to our success.
            </Typography>
          </Container>

          <Container maxWidth={"xl"} sx={{ marginTop: "70px" }}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Typography variant="h5">Vision</Typography>
                <Typography>
                  Creating Beautiful, Functional, Sustainable & affordable
                  EXCLUSIVE MODULAR INTERIORS to every Home & Commercial Spaces.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Typography variant="h5">Mission</Typography>
                <Typography>
                  To achieve our vision, we prioritize customer satisfaction,
                  foster collaborations, embrace sustainability, offer
                  competitive pricing, and leverage the power of Artificial
                  Intelligence and professional expertise.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Section> */}
      </DefaultLayout>
    </React.Fragment>
  );
};

export default AboutUs;

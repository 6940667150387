import React, { useEffect, useState } from "react";
import DefaultLayout from "../Layout/DefaultLayout";
import {
  Typography,
  Box,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Grid,
} from "@mui/material";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { EnquiriesTable } from "./EnquiriesTable";
import axios from "axios";
import {
  GET_AGENTS_ADMIN,
  GET_ENQUIRY_AGENT,
  POST_ENQUIRY_ADMIN,
  POST_USER_AGENTS_ADMIN,
} from "../../../Service/ApiRoutes";
import { AddEnquiryModal } from "./AddEnquiryModal";

const Enquiries = () => {
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "slno",
      headerName: "Sl.No",
      minWidth: 150,
      editable: true,
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 120,
    },
    {
      field: "formtype",
      headerName: "Form Type",
      minWidth: 120,
      //editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
    },
  ];

  const rows = [
    {
      id: 1,
      slno: "bd125364854823",
      date: "10/07/2023",
      formtype: "Consultation",
      status: "In Progress",
    },
    {
      id: 2,
      slno: "c125364853987",
      date: "09/07/2023",
      formtype: "Quote",
      status: "Completed",
    },
    {
      id: 3,
      slno: "aa564857845632",
      date: "08/07/2023",
      formtype: "Consultation",
      status: "Cancelled",
    },
    {
      id: 4,
      slno: "ef125364854154",
      date: "07/07/2023",
      formtype: "Quote",
      status: "In Progress",
    },
    {
      id: 5,
      slno: "bd125364854823",
      date: "06/07/2023",
      formtype: "Consultation",
      status: "Completed",
    },
    {
      id: 6,
      slno: "gd235469823641",
      date: "05/07/2023",
      formtype: "Quote",
      status: "In Progress",
    },
    {
      id: 7,
      slno: "cd125364854135",
      date: "04/07/2023",
      formtype: "Consultation",
      status: "Cancelled",
    },
    {
      id: 8,
      slno: "bh125364857896",
      date: "03/07/2023",
      formtype: "Quote",
      status: "Completed",
    },
    {
      id: 9,
      slno: "ed125364854823",
      date: "02/07/2023",
      formtype: "Consultation",
      status: "In Progress",
    },
    {
      id: 10,
      slno: "bd125364854823",
      date: "01/07/2023",
      formtype: "Quote",
      status: "Completed",
    },
    {
      id: 11,
      slno: "bc125364854821",
      date: "30/06/2023",
      formtype: "Consultation",
      status: "In Progress",
    },
    {
      id: 12,
      slno: "ca125364856503",
      date: "29/06/2023",
      formtype: "Quote",
      status: "Cancelled",
    },
    {
      id: 13,
      slno: "bg125364854821",
      date: "28/06/2023",
      formtype: "Consultation",
      status: "Completed",
    },
    {
      id: 14,
      slno: "be125364854820",
      date: "27/06/2023",
      formtype: "Quote",
      status: "In Progress",
    },
    {
      id: 15,
      slno: "ba125364856784",
      date: "26/06/2023",
      formtype: "Consultation",
      status: "Completed",
    },
  ];
  const Token = localStorage.getItem("TOKEN");
  const Role = localStorage.getItem("ROLE");
  const [EnquiryData, setEnquiryData] = useState([]);
  const [AgenetFilter, setAgenetFilter] = useState("");
  const [AgentDrop, setAgentDrop] = useState("");
  const [TypeDrop, setTypeDrop] = useState("");
  const [Page, setPage] = useState(1);
  const [TotalPage, setTotalPage] = useState("");
  const HandleGetEnquiry = () => {
    if (Role === "Super Admin") {
      const Payload = {
        agent: AgenetFilter,
        type: TypeDrop,
        page: Page,
      };
      axios
        .post(POST_ENQUIRY_ADMIN(), Payload, {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        })
        .then(({ data }) => {
          console.log(data);
          setPage(data?.pagination?.currentPage);
          setTotalPage(data?.pagination?.totalPages);
          setEnquiryData(data?.data);
        });
    } else {
      axios
        .post(
          GET_ENQUIRY_AGENT(),
          {
            page: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          }
        )
        .then(({ data }) => {
          console.log(data);
          setEnquiryData(data?.data);
        });
    }
  };

  useEffect(() => {
    HandleGetEnquiry();
  }, [AgenetFilter, TypeDrop, Page]);
  console.log(EnquiryData);

  const [AgentList, setAgentList] = useState([]);
  const HandleGetUSerAgents = () => {
    const Payload = {
      workProfile: "",
      status: "",
      page: 1,
    };
    axios
      .get(GET_AGENTS_ADMIN(), {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
      .then(({ data }) => {
        console.log(data);
        setAgentList(data?.data);
      });
  };

  useEffect(() => {
    HandleGetUSerAgents();
  }, []);

  console.log(AgentList);
  console.log(Page);
  const [isAdd, setisAdd] = useState(false);
  const ToggleIsAdd = () => setisAdd(!isAdd);
  return (
    <React.Fragment>
      <DefaultLayout>
        {isAdd && (
          <AddEnquiryModal Toggler={ToggleIsAdd} Refetch={HandleGetEnquiry} />
        )}
        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
          Enquiries
        </Typography>
        <Grid container>
          {Role === "Super Admin" && (
            <Grid item lg={6} md={6}>
              {" "}
              <div
                className="FleLeft gap-3"
                style={{
                  width: "270px",
                }}
              >
                <div
                  className=""
                  style={{
                    maxWidth: "50%",
                    marginTop: 2,
                    minWidth: "50%",
                  }}
                >
                  <label
                    htmlFor="leaveId"
                    style={{
                      fontSize: ".9em",
                    }}
                    className="mb-2 fw500"
                  >
                    Agent
                  </label>
                  <FormControl fullWidth className="CustomSelectDropSmallGray">
                    <InputLabel id="leaveId">Select</InputLabel>
                    <Select
                      labelId="leaveId"
                      id="demo-simple-leave"
                      label="Select"
                      name="loan_type"
                      value={AgentDrop}
                      className="Custom_Select"
                    >
                      <MenuItem
                        value={"Reset"}
                        key={"Reset"}
                        onClick={() => {
                          setAgenetFilter("");
                          setAgentDrop("");
                        }}
                      >
                        Reset
                      </MenuItem>
                      {[
                        // "Architect",
                        // "Interior Designer",
                        // "Civil Engineer",
                        // "Carpenter",
                        // "Marketing Agent",
                        // "Client",

                        ...AgentList,
                      ]?.map((Elem) => {
                        return (
                          <>
                            <MenuItem
                              value={Elem?.first_name + " " + Elem?.last_name}
                              key={Elem}
                              onClick={() => {
                                setAgenetFilter(Elem?._id);
                                setAgentDrop(
                                  Elem?.first_name + " " + Elem?.last_name
                                );
                              }}
                            >
                              {Elem?.first_name + " " + Elem?.last_name}
                            </MenuItem>
                          </>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div
                  className=""
                  style={{
                    maxWidth: "50%",
                    marginTop: 2,
                    minWidth: "50%",
                  }}
                >
                  <label
                    htmlFor="leaveId"
                    style={{
                      fontSize: ".9em",
                    }}
                    className="mb-2 fw500"
                  >
                    Type
                  </label>
                  <FormControl fullWidth className="CustomSelectDropSmallGray">
                    <InputLabel id="leaveId">Select</InputLabel>
                    <Select
                      labelId="leaveId"
                      id="demo-simple-leave"
                      label="Select"
                      name="loan_type"
                      value={TypeDrop}
                      className="Custom_Select"
                    >
                      <MenuItem
                        value={"Reset"}
                        key={"Reset"}
                        onClick={() => {
                          setTypeDrop("");
                        }}
                      >
                        Reset
                      </MenuItem>
                      {["Quote", "Consult"]?.map((Elem) => {
                        return (
                          <MenuItem
                            value={Elem}
                            key={Elem}
                            onClick={() => {
                              setTypeDrop(Elem);
                            }}
                          >
                            {Elem}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </Grid>
          )}

          {Role !== "Super Admin" && (
            <Grid item lg={12} md={12} className="FlexEnd">
              <button
                className="btnYellow"
                style={{
                  padding: "8px 25px",
                }}
                onClick={ToggleIsAdd}
              >
                Add
              </button>
            </Grid>
          )}
        </Grid>
        <EnquiriesTable
          Data={EnquiryData}
          Refetch={HandleGetEnquiry}
          TotalPage={TotalPage}
          setTotalPage={setTotalPage}
          Page={Page}
          setPage={setPage}
        />
        {/* <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            //autoHeight
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box> */}
      </DefaultLayout>
    </React.Fragment>
  );
};

export default Enquiries;

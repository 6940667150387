import React from "react";
//MUI
import { Box, Container, Grid, SvgIcon, Typography } from "@mui/material";

//Custome Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBanner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import Section from "../../Component/HelperComponents/Section";
import SectionTitle from "../../Component/HelperComponents/SectionTitle";
import SectionScrolling from "../../Component/HelperComponents/SectionScrolling";
import CustomCarouselMain from "../../Component/HelperComponents/CustomCarousel/CustomCarouselMain";
import { FromFieldStyles } from "../../Component/HelperComponents/CustomFormElements/CustomeFormElements";
import ConsultationFormSection from "../../Component/HelperComponents/ConsultationForm/ConsultationFormSection";
import RangeType from "../../Component/HelperComponents/RangeType/RangeType";

//Image and Icons
import { kanist, uttama, athyuttama } from "../../Assets/icons/icons";
import BannerImage from "../../Assets/Rectangle93.jpg";
import BgImage from "../../Assets/images/bg-image/kitchen-outline.jpg";
import BgConsultationFormSection from "../../Assets/images/bg-image/kitchen2.jpg";
//Data
import {
  carouselSettings,
  bannerDetails,
  Layout,
  Reasons,
} from "../../Data/FullHomeInteriors/HomeDesign";

function HomeDesign() {
  //Form Style
  const classes = FromFieldStyles();

  return (
    <React.Fragment>
      <DefaultLayout>
        <InnerBanner
          type={bannerDetails.type}
          title={bannerDetails.title}
          desc={bannerDetails.desc}
          image={bannerDetails.image}
        />
        <Section bgcolor="white">
          <Grid
            container
            sx={{
              gap: "20px",

              "& div": {
                flex: 1,
                textAlign: "center",
              },
            }}
          >
            <Grid>
              <RangeType
                icon={kanist}
                title="Kanist"
                desc="Discover our budget-friendlv collection that offers everything you need for a fresh start,"
              />
            </Grid>
            <Grid>
              <RangeType
                icon={uttama}
                title="Attama"
                desc="Experience the epitome of elegance and sophistication with our
                exquisite finishes on modular products, designed to make your
                home truly stand out in style."
                col2="#FEAB66"
              />
            </Grid>

            <Grid>
              <RangeType
                icon={athyuttama}
                title="Athyuttama"
                desc="Crafted with reinforced aluminum, our range offers effortless
                installation under fixed countertops, showcasing unparalleled
                flexibility at its finest."
                col2="#FF7C7C"
              />
            </Grid>
          </Grid>
        </Section>
        <Section bgimg={BgImage}>
          <SectionTitle
            title="The reasons to choose a Modulin Home Design"
            align="center"
            variant="h3"
          />

          <CustomCarouselMain type="" data={Reasons} />
        </Section>

        <Section bgcolor="255, 255,255" padding="100px 0 100px 24px">
          <SectionTitle title="Layout Title" variant="h5" />
          <Typography sx={{ maxWidth: "480px" }}>Layout Desc</Typography>
          <CustomCarouselMain
            type="imagetitle"
            data={Layout}
            settings={carouselSettings}
            showbutton={true}
          />
        </Section>

        <SectionScrolling
          title="Upgrade your kitchen to modular perfection, where convenience and style harmonize flawlessly."
          highlight="modular perfection"
        />

        <Section bgcolor="255, 255,255" padding="100px 0 100px 24px">
          <SectionTitle
            title="Enhance Elegance With Captivating Aesthetics."
            variant="h3"
          />

          <Typography sx={{ maxWidth: "480px" }}>
            Personalize your kitchen's appearance to match your preferences.
            Select from a variety of finishes, handles, and backsplashes for a
            unique touch.
          </Typography>
          <SectionTitle title="Cabinet and Hardware" variant="h5" />
          <CustomCarouselMain
            type="imagetitle"
            data={Reasons}
            settings={carouselSettings}
            showbutton={true}
          />
        </Section>

        <Section bgimg={BgConsultationFormSection} bgcolor="0,0,0,.2">
          <Container>
            <ConsultationFormSection title="Transform your dream interiors into reality with a single click" />
          </Container>
        </Section>
      </DefaultLayout>
    </React.Fragment>
  );
}
export default HomeDesign;

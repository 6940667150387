import React from "react";
import { useNavigate } from "react-router-dom";
export const NotFound = () => {
  const nav = useNavigate();
  const handleBack = () => {
    nav(-1);
  };

  return (
    <div className="ErrorPage position-relative">
      <div class="error-container">
        <h1> 404 </h1>
        <p>Oops! The page you're looking for is not here.</p>
        {/* <button className="BackTO" onClick={handleBack}>
          Retun To Menu
        </button> */}
      </div>
    </div>
  );
};

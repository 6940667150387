import React, { useState } from "react";
import { Link } from "react-router-dom";
//Custom Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBannner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import Section from "../../Component/HelperComponents/Section";
import theme from "../../Theme";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  SvgIcon,
  Button,
  TextField,
  Select,
  InputAdornment,
  Switch,
  FormControl,
  InputLabel,
  FormControlLabel,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
//Custome Components
import Dummy from "../../Assets/dummy.jpg";
import { indflag, Creativity, chair } from "../../Assets/icons/icons";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhonelinkRingIcon from "@mui/icons-material/PhonelinkRing";
import MailIcon from "@mui/icons-material/Mail";
import axios from "axios";
import { POST_CONTACT_US } from "../../Service/ApiRoutes";
import { whatWeOffer } from "../../Data/ModularInteriors/ModularInteriors";
import { toast } from "react-toastify";
import MainContact from "../../Assets/contact/main.png";
import SecondContact from "../../Assets/contact/Image2.png";
import verify from "../../Assets/contact/verify.png";
import phone from "../../Assets/contact/phone.png";
import map from "../../Assets/contact/map.png";
import email from "../../Assets/contact/mail.png";
import phoneWhite from "../../Assets/contact/phoneWhite.png";
import mapWhite from "../../Assets/contact/mapWhite.png";
import mailWhite from "../../Assets/contact/mailWhite.png";
import "../../Global.scss";
import ModalSection from "../../Component/HelperComponents/ModalSection";
import ConsultationForm from "../../Component/HelperComponents/ConsultationForm/ConsultationForm";
const useStyles = makeStyles({
  boxSet: {
    display: "flex",
    gap: "30px",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
    //transition: "all  ease 1s",
    "& div": {
      " & a ": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        alignItems: "center",
        justifyContent: "center",
        color: `rgba(${theme.palette.text.default},1)`,
        width: "100px",
        height: "100px",
        borderRadius: "80px",
        transition: "all  ease 1s",
      },
      "&:hover": {
        " & a ": {
          transition: "all  ease 1s",
          color: `rgba(${theme.palette.primary.default},1)`,
          backgroundColor: `rgba(${theme.palette.text.default},.1)`,
        },
      },
    },
  },
});

const ContactUs = () => {
  // const Validation = Yup.object({
  //   name: Yup.string().required(" Enter your name "),
  //   email: Yup.string().email().required("Enter your email"),
  //   phone: Yup.string()
  //     .required("Phone is required")
  //     .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
  //   description: Yup.string().required(" Enter your descriptiom "),
  // });
  const [selectedOption, setSelectedOption] = React.useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  //Custom Styles
  const classes = useStyles(); //Custom Style

  //Form submission
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    description: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const HandleSubmit = () => {
    if (formData?.name && formData?.email)
      axios.post(POST_CONTACT_US(), formData).then(({ data }) => {
        toast.success("Submit", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(data);
        setFormData({
          name: "",
          email: "",
          number: "",
          description: "",
        });
      });
    console.log(formData);
  };

  //Modal Setup
  const [openModalForm, setOpenModalForm] = useState(false);
  const handleClickOpen = () => {
    setOpenModalForm(true);
  };
  const handleClose = (event, reason) => {
    //if (reason && reason == "backdropClick") return;
    setOpenModalForm(false);
  };
  //Modal Setup End
  return (
    <React.Fragment>
      <ModalSection open={openModalForm} onClose={handleClose}>
        <ConsultationForm onClose={handleClose} />
      </ModalSection>
      <DefaultLayout>
        <div className="ContactUs">
          <div className="Section1">
            <div className="Leftside">
              <img src={MainContact} alt="" />
            </div>
            <div className="Rightside">
              <div className="content">
                <h3>Consult The Service You</h3>
                <h5>Need Now !</h5>
                <p>
                  We’re here to help you turn your space into a personal
                  story—whether through a full redesign or a simple
                  enhancement—so consult our dedicated team today to bring your
                  vision to life!
                </p>
                <div className="service">
                  <div className="contBox">
                    <img src={verify} alt="" />
                    Fast Delivery
                  </div>
                  <div className="contBox">
                    <img src={verify} alt="" />
                    Competitive Price
                  </div>
                  <div className="contBox">
                    <img src={verify} alt="" />
                    Wide Delivery Area
                  </div>
                </div>
                {/* <div className="btnBox">
                  <button onClick={handleClickOpen}>Get in Touch</button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="Section2">
            <div className="Sec2Cont Grid2">
              <div className="Left">
                <h1>
                  Get In{" "}
                  <span
                    style={{
                      color: "rgba(200, 116, 62, 1)",
                    }}
                  >
                    Touch
                  </span>
                </h1>
                <p>
                  We’re eager to help you turn your vision into reality. Get in
                  touch with us today to explore how we can bring your ideas to
                  life together!
                </p>
                <div className="Grid2 mt-4">
                  <div className="infoBox">
                    <img src={phone} alt="" />
                    <div className=" w-100">
                      <p>Phone Number</p>
                      <p>+91 9281482112</p>
                    </div>
                  </div>
                  <div className="infoBox">
                    <img src={email} alt="" />
                    <div className=" w-100">
                      <p>Email ID</p>
                      <p>info@modulin.in</p>
                    </div>
                  </div>
                </div>
                <div className="infoBox mt-2">
                  <img src={map} alt="" />
                  <div className=" w-100">
                    <p>Address</p>
                    <p>
                      SURVAY NO-292, INDUSTRIAL PARK, PLOT NO 32/33,
                      RAMANAYYAPETA, SARPAVARAM, Kakinada, Andhra Pradesh 533005
                    </p>
                  </div>
                </div>
              </div>
              <div className="Right">
                <Grid item>
                  <form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <div className="FlexCenter gap-4">
                      <div className="fieldBox">
                        <label htmlFor="" className="mb-1 fs-7 fw500">
                          Name
                        </label>
                        <TextField
                          label="Name"
                          name="name"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={formData.name}
                          onChange={handleInputChange}
                          className="My_fieldBoxNormal"
                        />
                      </div>
                      <div className="fieldBox">
                        <label htmlFor="" className="mb-1 fs-7 fw500">
                          Phone No
                        </label>
                        <TextField
                          label="Phone Number"
                          name="number"
                          type="number"
                          variant="outlined"
                          fullWidth
                          value={formData.number}
                          onChange={handleInputChange}
                          className="My_fieldBoxNormal"
                          margin="normal"
                        />
                      </div>
                      <div className="fieldBox">
                        <label htmlFor="" className="mb-1 fs-7 fw500">
                          Email ID
                        </label>
                        <TextField
                          label="Email"
                          name="email"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={formData.email}
                          onChange={handleInputChange}
                          className="My_fieldBoxNormal"
                        />
                      </div>
                    </div>
                    <div className="sec_subBox">
                      <label htmlFor="" className="mb-2 fs-7 fw500">
                        Description
                      </label>
                      <TextField
                        multiline
                        rows={5}
                        label="Description"
                        name="description"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.description}
                        onChange={handleInputChange}
                        className="My_fieldBoxNormal"
                      />
                    </div>

                    <div
                      className="btnBox"
                      onClick={(e) => {
                        e.preventDefault();
                        HandleSubmit();
                      }}
                    >
                      <button>Get in Touch</button>
                    </div>
                  </form>
                </Grid>
              </div>
            </div>
          </div>
          <div className="section3"></div>
          <div className="Section2 section4">
            <div className="Sec2Cont Grid2 gap-4">
              <div className="Left">
                <div className="Boxs">
                  <h1>Design Studio & Factory</h1>
                  <h3>Head Office</h3>
                  <div className=" mt-4">
                    <div className="infoBox">
                      <img src={phoneWhite} alt="" />
                      <div className=" w-100 ">
                        <p></p>
                        <p className="white fw300">+91 9281482112</p>
                      </div>
                    </div>
                    <div className="infoBox mt-1">
                      <img src={mailWhite} alt="" />
                      <div className=" w-100 ">
                        <p></p>
                        <p className="white fw300">info@modulin.in</p>
                      </div>
                    </div>
                    <div className="infoBox mt-1">
                      <img src={mapWhite} alt="" />
                      <div className=" w-100">
                        <p></p>
                        <p className="white fw300">
                          1-2-3456 abc Street,Ramaraopet,Kakinada
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Right FlexCenter">
                <div className="setMove">
                  <h1>We proudly serve In</h1>
                  <h2>Andhra Pradesh & Telangana</h2>
                  <p>Craft Your Perfect Space—Talk to Our Experts!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DefaultLayout>
    </React.Fragment>
  );
};

export default ContactUs;

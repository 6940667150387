import React from "react";

//MUI Theme
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material"; // Import MUI useMediaQuery

//modular interiors
import Mi01 from "../Assets/images/modularinteriors/modularinterior.jpg";
import Mi02 from "../Assets/images/modularinteriors/modularinteriors.jpg";
import Mi03 from "../Assets/images/modularinteriors/Mi03.jpg";
import Mi04 from "../Assets/images/modularinteriors/Mi04.jpg";
import Mi05 from "../Assets/images/modularinteriors/Mi05.jpg";

//full home interiors
import Fhi01 from "../Assets/images/fullhomeinteriors/Fhi01.jpg";
import Fhi02 from "../Assets/images/fullhomeinteriors/Fhi02.jpg";
import Fhi03 from "../Assets/images/fullhomeinteriors/Fhi03.jpg";
import Fhi04 from "../Assets/images/fullhomeinteriors/Fhi04.jpg";
import Fhi05 from "../Assets/images/fullhomeinteriors/Fhi05.jpg";

//Design Ideas_ for Inspiration
import Di01 from "../Assets/images/kitchen/parallel/Pa11.jpg";
import Di02 from "../Assets/images/wardrobe/Wd04.jpg";
import Di03 from "../Assets/images/master-bed-room/Mb01.jpg";
import Di04 from "../Assets/images/bedroom/Br04.jpg"
;
import Di05 from "../Assets/images/living-area/La02.jpg";
import Di06 from "../Assets/images/pooja-room/Pr05.jpg";
import Di07 from "../Assets/images/tv-units/Tvu02.jpg";
import Di08 from "../Assets/images/crockeryunits/Cu03.jpg";
import Di09 from "../Assets/images/storage-units/Su01.jpg";
import Di010 from "../Assets/images/studyarea/Sa03.jpg";
import Di011 from "../Assets/images/homeOffices/3c6150a7faf67448dec557fc738eff52.jpg";
import Di012 from "../Assets/images/entanceDoor/02d8fed6fbcbc3d7625308d275f114d7.jpg";
import Di013 from "../Assets/images/shoeRack/6a67d64eff5587c33ff2d55e1504364c.jpg";


// Parallex
//import BgScrolling from "../Assets/images/kitchen/K5.jpg";
import BgScrolling from "../Assets/images/kitchen/K1.jpg";

//BgTestimonials
import BgTestimonials from "../Assets/modular1.png";

//carouselMainSettings
const carouselMainSettings = {
  // margin: 120,
  // loop: true,
  // center: false,
  // mouseDrag: false,
  // touchDrag: false,
  // nav: false,
  // dots: true,
  // autoplay: true,
  // autoplayTimeout: 1000,
  // autoplayHoverPause: true,
  // // responsive: {
  //   0: {
  //     items: 1,
  //   },
  //   400: {
  //     items: 2,
  //   },
  //   601: {
  //     items: 3,
  //   },
  //   901: {
  //     items: 5,
  //   },
  //   1200: {
  //     items: 9,
  //   },
  // },
};

const modularInteriors = [
  {
    id: 1,
    title: "Modular Interiors 01",
    imageUrl: Mi01,
    link: "/modular-interiors",
  },
  {
    id: 2,
    title: "Modular Interiors 02",
    imageUrl: Mi02,
    link: "/modular-interiors",
  },
  {
    id: 3,
    title: "Modular Interiors 03",
    imageUrl: Mi03,
    link: "/modular-interiors",
  },
  {
    id: 4,
    title: "Modular Interiors 04",
    imageUrl: Mi04,
    link: "/modular-interiors",
  },
  {
    id: 5,
    title: "Modular Interiors 05",
    imageUrl: Mi05,
    link: "/modular-interiors",
  },
];
const fullHomeInteriors = [
  {
    id: 1,
    title: "Full Home Interiors 01",
    imageUrl: Fhi01,
    link: "/full-home-interiors",
  },
  {
    id: 2,
    title: "Full Home Interiors 02",
    imageUrl: Fhi02,
    link: "/full-home-interiors",
  },
  {
    id: 3,
    title: "Full Home Interiors 03",
    imageUrl: Fhi03,
    link: "/full-home-interiors",
  },
  {
    id: 4,
    title: "Full Home Interiors 04",
    imageUrl: Fhi04,
    link: "/full-home-interiors",
  },
  {
    id: 5,
    title: "Full Home Interiors 05",
    imageUrl: Fhi05,
    link: "/full-home-interiors",
  },
];

const PrallexDetails = {
  title:
    "Upgrade your kitchen to modular perfection, where convenience and style harmonize flawlessly.",
  highlight: "modular perfection",
  image: BgScrolling,
};

const forInspiration = [
  {
    id: 1,
    title: "Kitchen",
    // title: "For Inspiration 01",
    imageUrl: Di01,
    category: "Modular Kitchen",
    link: "/design-ideas",
    categoryLink: "/design-ideas/modular-kitchen-designs/kitchen",
  },
  {
    id: 2,
    // title: "For Inspiration 02",
    title: "Wardrobes",
    imageUrl: Di02,
    category: "Wardrobe",
    link: "/design-ideas",
    categoryLink: "/design-ideas/wardrobe-designs/wardrobe",
  },
  {
    id: 3,
    title: "Master Bedroom",
    // title: "For Inspiration 03",
    imageUrl: Di03,
    category: "Master Bedroom ",
    link: "/design-ideas",
    categoryLink: "/design-ideas/master-bedroom-designs/bedroom",
  },
  {
    id: 4,
    // title: "For Inspiration 04",
    title: "Kids Bedroom",
    imageUrl: Di04,
    category: "Kids Bedroom ",
    link: "/design-ideas",
    categoryLink: "/design-ideas/kids-bedroom-designs/kids",
  },
  {
    id: 5,
    // title: "For Inspiration 05",
    title: "Living Room ",
    imageUrl: Di05,
    category: "Living Room ",
    link: "/design-ideas",
    categoryLink: "/design-ideas/living-room-designs/living",
  },
  {
    id: 6,
    // title: "For Inspiration 06",
    title: "Pooja Room ",
    imageUrl: Di06,
    category: "Pooja Room ",
    link: "/design-ideas",
    categoryLink: "/design-ideas/pooja-room-designs/pooja",
  },
  {
    id: 7,
    title: "TV Unit",
    // title: "For Inspiration 07",
    imageUrl: Di07,
    category: "TV Unit ",
    link: "/design-ideas",
    categoryLink: "/design-ideas/tv-unit-designs/tv",
  },
  {
    id: 8,
    // title: "For Inspiration 08",
    title: "Crockery units",
    imageUrl: Di08,
    // category: "Dining Room Designs",
    category: "Crockery units",
    link: "/design-ideas",
    categoryLink: "/design-ideas/crockery-unit-designs/crockery",
  },
  {
    id: 9,
    // title: "For Inspiration 08",
    title: "Storage units",
    imageUrl: Di09,
    // category: "Dining Room Designs",
    category: "Storage units",
    link: "/design-ideas",
    categoryLink: "/design-ideas/storage-unit-designs/storage",
  },
  {
    id: 10,
    // title: "For Inspiration 08",
    title: "Study area ",
    imageUrl: Di010,
    // category: "Dining Room Designs",
    category: "Study area ",
    link: "/design-ideas",
    categoryLink: "/design-ideas/study-area/study",
  },
  {
    id: 8,
    // title: "For Inspiration 08",
    title: "Home offices ",
    imageUrl: Di011,
    // category: "Dining Room Designs",
    category: "Home offices ",
    // link: "/design-ideas",
    categoryLink: "/design-ideas/home-office-designs/home",
  },
  {

    id: 8,
    // title: "For Inspiration 08",
    title: "Entrance Door ",
    imageUrl: Di012,
    // category: "Dining Room Designs",
    category: "Entrance Door ",
    link: "/design-ideas",
    categoryLink: "/design-ideas/entrance-door-designs/entrance",
  },
  {
    id: 8,
    // title: "For Inspiration 08",
    title: "Shoe rack ",
    imageUrl: Di013,
    // category: "Dining Room Designs",
    category: "Shoe rack ",
    link: "/design-ideas",
    categoryLink: "/design-ideas/shoe-rack-designs/shoe",
  },
];

const TestimonialsSection = {
  backimg: BgTestimonials,
  backimgposition: "0 bottom",
  backgroundsize: {
    xs: "300px",
    sm: "auto",
    md: "auto",
    lg: "auto",
    xl: "auto",
  },
};

const carouselSettingsHomeSlider = {
  // margin: 120,
  // loop: true,
  // center: false,
  // mouseDrag: false,
  // touchDrag: false,
  // nav: false,
  // dots: true,
  // autoplay: true,
  // autoplayTimeout: 1000,
  // autoplayHoverPause: true,
  responsive: {
    0: {
      items: 1.2,
    },
    601: {
      items: 2.2,
    },
    901: {
      items: 4,
    },
    1200: {
      items: 5,
    },
  },
};

export {
  modularInteriors,
  fullHomeInteriors,
  PrallexDetails,
  forInspiration,
  TestimonialsSection,
  carouselSettingsHomeSlider,
};

import React from "react";
import Header from "../Component/Header";
import Sidebar from "../Component/Sidebar";
import Footer from "../Component/Footer";
import { Grid, Box, Container } from "@mui/material";
import theme from "../../../Theme";
import { AdminRoutes, AgentRoutes } from "./DashboarMenu";

const DefaultLayout = (props) => {
  const { children } = props;
  const Role = localStorage.getItem("ROLE");
  console.log(Role);
  const Menu = Role === "Super Admin" ? AdminRoutes : AgentRoutes;
  return (
    <React.Fragment>
      <Grid container sx={{ height: "100vh" }}>
        {/* Sidebar */}
        <Grid item lg="auto">
          <Sidebar Menu={Menu} />
        </Grid>
        {/* Main Content */}
        <Grid
          flex={1}
          item
          // lg={9}
          sx={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <Header />
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",

              backgroundColor: `rgba(${theme.palette.text.default},.05)`,
            }}
          >
            <Container maxWidth={false} sx={{ margin: "10px 0 40px 0" }}>
              {children}
            </Container>
          </Box>

          <Footer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DefaultLayout;

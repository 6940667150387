export const POST_CONSULTANT = () =>
  `https://api.modulin.in/api/enquire/create`;
export const POST_CONTACT_US = () =>
  `https://api.modulin.in/api/enquire/contact`;

//   LOGNI
export const POST_SEND_OTP = () => `https://api.modulin.in/api/otp/send`;
export const POST_RESEND_OTP = () => `https://api.modulin.in/api/otp/resend`;
export const POST_VERIFY_OTP = () => `https://api.modulin.in/api/otp/verify`;

// User
export const PUT_UPDATE_IMAGE = () => `https://api.modulin.in/api/user/image`;
export const POST_UPDATE_ROLE = () =>
  `https://api.modulin.in/api/user/updateRole`;
export const POST_USER_DETAIL = () =>
  `https://api.modulin.in/api/user/userdetails`;
export const POST_UPDATE_PROFILE = () =>
  `https://api.modulin.in/api/user/profile`;

// Admin User & Agents
export const GET_AGENTS_ADMIN = () =>
  `https://api.modulin.in/api/admin/agents`;
export const POST_USER_AGENTS_ADMIN = () =>
  `https://api.modulin.in/api/admin/users`;
export const POST_UPDATE_AGENTS_STATUS = () =>
  `https://api.modulin.in/api/admin/updateagentstatus`;

// Enquiry
export const POST_UPDATE_ENQUIRY_STATUS = () =>
  `https://api.modulin.in/api/admin/updatestatus`;
export const GET_ENQUIRY_AGENT = () =>
  `https://api.modulin.in/api/agent/getusers`;
export const POST_ENQUIRY_ADMIN = () =>
  `https://api.modulin.in/api/admin/enquiries`;
export const POST_ADD_ENQUIRY = () =>
  `https://api.modulin.in/api/agent/addusers`;

// Career
export const POST_ADD_JOB = () => `https://api.modulin.in/api/enquire/careers`;
export const GET_CAREER = () => `https://api.modulin.in/api/admin/careers`;

// Consulation
export const GET_CONSULT = () => `https://api.modulin.in/api/admin/consultants`;
export const POST_UPDATE_STATUS_CONSULT = () =>
  `https://api.modulin.in/api/admin/updateconsultant`;

import React, { useState } from "react";
import ModaalDark from "../../../Component/HelperComponents/Modal/ModalDark";
import { Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  OutlinedInput,
  Select,
} from "@mui/material";
import { IoIosAddCircleOutline } from "react-icons/io";
import * as Yup from "yup";
import { IoCloseCircleOutline } from "react-icons/io5";
import theme from "../../../Theme";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { POST_ADD_ENQUIRY } from "../../../Service/ApiRoutes";
export const AddEnquiryModal = ({ Toggler, Initial, Refetch }) => {
  const Validation = Yup.object({
    users: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required("Please choose an option"),
        name: Yup.string().required("Enter your name"),
        address: Yup.string().required("Enter your address"),
        email: Yup.string().email("Invalid email").required("Enter your email"),
        phone_number: Yup.string()
          .required("Phone is required")
          .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
      })
    ),
  });
  const Token = localStorage.getItem("TOKEN");
  const nav = useNavigate();
  const handleFormSubmit = (values) => {
    console.log(values);
    axios
      .post(POST_ADD_ENQUIRY(), values, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
      .then(({ data }) => {
        console.log(data);
        if (data?.status === "Success") {
          toast.success("Success", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          Toggler();
          Refetch();
        } else {
          toast.error("Token Expired", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          nav("../login");
        }
      });
  };

  const [workProfile, setworkProfile] = useState(Initial?.work_profile);
  const [Gender, setGender] = useState(Initial?.gender);
  const [Marrit, setMarrit] = useState(Initial?.marital_status);
  const handleAddDynamicField = (setFieldValue, dynamicFields) => {
    const newField = {
      name: "",
      email: "",
      phone_number: "",
      type: "",
      address: "",
    };
    setFieldValue("users", [...dynamicFields, newField]);
  };
  return (
    <ModaalDark anchorEl={true}>
      <div className="Modal600">
        <Typography
          variant="body2"
          align="left"
          sx={{
            //   fontSize: "1.2em",
            fontWeight: 700,
          }}
        >
          Add Enquiry
        </Typography>
        <span
          onClick={() => Toggler()}
          id="closeIcon"
          style={{
            display: "block",
            borderRadius: "50px",
            position: "absolute",
            top: "20px",
            right: "20px",
            background: "rgb(239 91 65)",
            fontSize: ".9em",
            width: "14px",
            height: "14px",
            zIndex: 10,
          }}
        />
        <div className="modalContent">
          <Formik
            initialValues={{
              users: [
                {
                  name: "",
                  email: "",
                  phone_number: "",
                  type: "",
                  address: "",
                },
              ],
            }}
            validationSchema={Validation}
            // enableReinitialize
            onSubmit={handleFormSubmit}
          >
            {({ setFieldValue, handleSubmit, values, errors }) => (
              <Form>
                <div className="  m-0 mt-0 p-2">
                  {values?.users?.map((item, ind) => {
                    console.log(errors);
                    return (
                      <>
                        <div className="Grid4 gap-3">
                          <div className="col-lg-6 ">
                            <label htmlFor="leaveId" className="mb-2 text">
                              Name
                            </label>
                            <Field name={`users[${ind}].name`}>
                              {({ field }) => (
                                <TextField
                                  {...field}
                                  className="My_fieldBoxNormalGray"
                                  fullWidth
                                  autoComplete="off"
                                  id="outlined-basic"
                                  label="Enter Name"
                                  variant="outlined"
                                />
                              )}
                            </Field>

                            <ErrorMessage
                              name={`users[${ind}].name`}
                              component="div"
                              className="errors"
                              style={{
                                marginTop: "0px",
                              }}
                            />
                          </div>
                          <div className="col-lg-5 ">
                            <label htmlFor="leaveId" className="text mb-2">
                              Email
                            </label>
                            <Field name={`users[${ind}].email`}>
                              {({ field }) => (
                                <TextField
                                  {...field}
                                  type="email"
                                  className="My_fieldBoxNormalGray"
                                  fullWidth
                                  // value={item?.subtotal}
                                  autoComplete="off"
                                  id="outlined-basic"
                                  label="Enter Email"
                                  variant="outlined"
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name={`users[${ind}].email`}
                              component="div"
                              className="errors"
                              style={{
                                marginTop: "0px",
                              }}
                            />
                          </div>
                          <div className="col-lg-5 ">
                            <label htmlFor="leaveId" className="text mb-2">
                              Phone
                            </label>
                            <Field name={`users[${ind}].phone_number`}>
                              {({ field }) => (
                                <TextField
                                  {...field}
                                  type="number"
                                  className="My_fieldBoxNormalGray"
                                  fullWidth
                                  // value={item?.subtotal}
                                  autoComplete="off"
                                  id="outlined-basic"
                                  label="Enter Number"
                                  variant="outlined"
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name={`users[${ind}].phone_number`}
                              component="div"
                              className="errors"
                              style={{
                                marginTop: "0px",
                              }}
                            />
                          </div>

                          <div className="">
                            <label
                              htmlFor="leaveId"
                              className="mb-2 fs-7 fw600"
                            >
                              Type
                            </label>
                            <FormControl
                              fullWidth
                              className="CustomSelectDropNormalGray"
                            >
                              <InputLabel id="leaveId">Select</InputLabel>
                              <Select
                                labelId="leaveId"
                                id="demo-simple-leave"
                                label="Select"
                                name={`users[${ind}].type`}
                                // value={workProfile}
                                className="Custom_Select"
                              >
                                {["Quote", "Consult"]?.map((Elem) => {
                                  return (
                                    <MenuItem
                                      value={Elem}
                                      key={Elem}
                                      onClick={() => {
                                        setFieldValue(
                                          `users[${ind}].type`,
                                          Elem
                                        );
                                        setworkProfile(Elem);
                                      }}
                                    >
                                      {Elem}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <ErrorMessage
                              name={`users[${ind}].type`}
                              component="div"
                              className="errors"
                              style={{
                                marginTop: "0px",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="FlexBetween gap-3 m-0 mt-2 p-2 mb-3"
                          style={{ alignItems: "end" }}
                        >
                          <div
                            className=""
                            style={{
                              position: "relative",
                              width: "75%",
                            }}
                          >
                            <div className="sec_subBox">
                              <label htmlFor="" className="mb-2 fs-7 fw600">
                                Address
                              </label>
                              <Field name={`users[${ind}].address`}>
                                {({ field }) => (
                                  <TextField
                                    {...field}
                                    multiline
                                    rows={4}
                                    className="My_fieldBoxNormalGray"
                                    fullWidth
                                    autoComplete="off"
                                    id="outlined-basic"
                                    label="Enter Text"
                                    variant="outlined"
                                  />
                                )}
                              </Field>
                              <ErrorMessage
                                name={`users[${ind}].address`}
                                component="div"
                                className="errors"
                              />
                            </div>
                            <div
                              className=""
                              style={{
                                position: "absolute",
                                top: 0,
                                right: "-50px",
                              }}
                            >
                              {ind === 0 && (
                                <div className="col-lg-1 mt-4 pt-2 FlexCenter p-0">
                                  <div
                                    className=" "
                                    style={{
                                      zIndex: 10,
                                    }}
                                  >
                                    <IoIosAddCircleOutline
                                      style={{
                                        marginTop: 10,
                                        fontSize: "1.8em",
                                        zIndex: 10,
                                      }}
                                      className=" text-gold cursor "
                                      onClick={() =>
                                        handleAddDynamicField(
                                          setFieldValue,
                                          values.users
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {ind > 0 && (
                                <div className="col-lg-1 mt-4 pt-2 FlexCenter p-0">
                                  <div className="  ">
                                    <IoCloseCircleOutline
                                      style={{
                                        fontSize: "1.8em",
                                      }}
                                      className="fs-3 mt-2 text-danger cursor"
                                      onClick={() => {
                                        const updatedFields = [...values.users];
                                        updatedFields.splice(ind, 1);
                                        setFieldValue("users", updatedFields);
                                        // HandleRemove(ind)
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {ind + 1 === values?.users?.length && (
                            <div className="FlexEnd">
                              <button
                                type="submit"
                                className="btnYellow mt-3 "
                                style={{
                                  padding: ".8em 2em",
                                }}
                              >
                                Add
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModaalDark>
  );
};

import React from "react";
import { Link } from "react-router-dom";
//MUI
import theme from "../../../Theme";
import { Box, Container, Grid, SvgIcon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

//Image and Icons
import { kanist, anyonya, uttama } from "../../../Assets/icons/icons";
import useDeviceSize from "../../../Service/device-size";

const useStyles = makeStyles({
  linkWrapper: {
    color: `rgba(${theme.palette.text.default},1)`,
    textDecoration: "none",
    display: "block",
    height: "100%",
    transition: "all ease 1s",
    backgroundColor: "transparent",
    "&>:hover": {
      // transition: "all ease 1s",
      // backgroundColor: `rgba(${theme.palette.text.default},.05)`,
    },
  },
  holder: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    height: "100%",
  },
  iconset: {
    paddingTop: "20px",
  },
  textset: { padding: "0 20px 50px" },
});

const RangeType = ({
  head,
  width,
  title,
  desc,
  icon,
  col,
  col2,
  pathto,
  IMG,
  Finish,
  Hardware,
  coreMaterial,
  Access,
}) => {
  const { isMobile } = useDeviceSize();
  const classes = useStyles(); //Custom Style
  return (
    <React.Fragment>
      {/* <Box component={Link} to={pathto} className={classes.linkWrapper}> */}
      <Box className={classes.linkWrapper}>
        <Box className={classes.holder}>
          {/* <Box className={classes.iconset}>
            <SvgIcon
              component={icon}
              sx={{ fontSize: "120px" }}
              col={col}
              col2={col2}
            />
          </Box> */}
          <Box className={!isMobile && classes.textset}>
            <img src={IMG} alt="" width={width || "120px"} />
            {/* <Typography variant="h6">{title}</Typography> */}
            {head?.one}
            <Typography
              sx={{
                fontWeight: 500,
                minHeight: "110px",
                fontSize: ".9em",
              }}
            >
              {desc}
            </Typography>
            {head?.two}
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: ".9em",

                minHeight: "50px",
              }}
            >
              {coreMaterial}
            </Typography>
            {head?.three}
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: ".9em",

                minHeight: "90px",
              }}
            >
              {Finish}
            </Typography>
            {head?.four}
            <Typography
              sx={{
                fontWeight: 500,
                minHeight: "100px",
                fontSize: ".9em",
              }}
            >
              {Hardware}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                minHeight: "40px",
                fontSize: ".9em",
              }}
            >
              {Access}
            </Typography>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

RangeType.defaultProps = {
  title: "add title",
  desc: "add desc",
  col: "#000000",
  col2: "#FECE78",
  pathto: false,
};

export default RangeType;

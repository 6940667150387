import React from "react";

//Images and Icons
import bannerImage from "../../Assets/images/shoeRack/mian.jpg";

const BannerDetails = {
  type: "fullimage",
  image: bannerImage,
  title: "Modular kitchen designs",
  desc: "Say goodbye to the clutter of shoes piled at the door. Modulin’s modular shoe racks offer a chic, organized solution for your entrance. Designed to blend with your home’s aesthetic, our shoe racks provide ample space for every family member’s footwear, making it easy to keep your entrance neat and tidy while adding a touch of style.",
  bannertext: false,
};

////////////////////////////////////////////////
// List of image filenames to hide
const imagesToHide = ["Ushape06.jpg"];

// Function to import all images from a given context and exclude specified images
const importAllImages = (requireContext) =>
  requireContext
    .keys()
    .filter((key) => {
      const imageName = key.split("/").pop(); // Get the image name from the path
      return !imagesToHide.includes(imageName);
    })
    .map((key, index) => {
      const imageName = key.split("/").pop(); // Get the image name from the path
      const pathParts = key.split("/");
      const folderName =
        pathParts.length >= 2
          ? pathParts[pathParts.length - 2]
          : "Unknown Folder"; // Get the folder name or use "Unknown Folder"
      return {
        id: index + 1, // Generate unique ID using the index
        title: imageName.replace(/\.[^/.]+$/, ""), // Remove file extension for title
        imageUrl: requireContext(key),
        category: folderName,
      };
    });

// Import all images from the specified context
const Gallery = importAllImages(
  require.context(
    "../../Assets/images/shoeRack",
    true, // Include subdirectories
    /\.(png|jpe?g|webp)$/
  )
);
////////////////////////////////////////////////

export { Gallery, BannerDetails };

import React from "react";

//Images and Icons
import bannerImage from "../../Assets/images/dining-room/Dr04.jpg";

const BannerDetails = {
  type: "fullimage",
  image: bannerImage,
  title: "Modular kitchen designs",
  desc: "The dining room is the heart of every gathering, and it should be as functional as it is elegant. Modulin’s modular dining room designs are tailored to maximize space, improve functionality, and create a seamless dining experience. From sophisticated storage solutions to stylish, space-saving furniture, we ensure that every detail of your dining room is optimized for comfort and efficiency while maintaining a contemporary, refined look.",
  bannertext: false,
};

////////////////////////////////////////////////
// List of image filenames to hide
const imagesToHide = ["Ushape06.jpg"];

// Function to import all images from a given context and exclude specified images
const importAllImages = (requireContext) =>
  requireContext
    .keys()
    .filter((key) => {
      const imageName = key.split("/").pop(); // Get the image name from the path
      return !imagesToHide.includes(imageName);
    })
    .map((key, index) => {
      const imageName = key.split("/").pop(); // Get the image name from the path
      const pathParts = key.split("/");
      const folderName =
        pathParts.length >= 2
          ? pathParts[pathParts.length - 2]
          : "Unknown Folder"; // Get the folder name or use "Unknown Folder"
      return {
        id: index + 1, // Generate unique ID using the index
        title: imageName.replace(/\.[^/.]+$/, ""), // Remove file extension for title
        imageUrl: requireContext(key),
        category: folderName,
      };
    });

// Import all images from the specified context
const Gallery = importAllImages(
  require.context(
    "../../Assets/images/crockeryunits",
    true, // Include subdirectories
    /\.(png|jpe?g|webp)$/
  )
);
////////////////////////////////////////////////

export { Gallery, BannerDetails };

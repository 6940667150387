import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//Custom Components
import DefaultLayout from "../../../Component/Layout/DefaultLayout";
import InnerBannner from "../../../Component/HelperComponents/BannerComponent/InnerBanner";
import Section from "../../../Component/HelperComponents/Section";
import SectionTitle from "../../../Component/HelperComponents/SectionTitle";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  SvgIcon,
} from "@mui/material";
import theme from "../../../Theme";
import refer1 from "../../../Assets/Refer/refer1.png";
import refer2 from "../../../Assets/Refer/refer2.png";
import refer3 from "../../../Assets/Refer/refer3.png";
//data
import { bannerDetails, howItWorks } from "../../../Data/Others/ReferAFriend";
import { border, fontSize } from "@mui/system";
import { color } from "framer-motion";
import ModaalDark from "../../../Component/HelperComponents/Modal/ModalDark";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { FaCircle, FaRegCircle } from "react-icons/fa6";
import axios from "axios";
import { POST_UPDATE_ROLE } from "../../../Service/ApiRoutes";
import { toast } from "react-toastify";
import useDeviceSize from "../../../Service/device-size";

const ReferAFriend = () => {
  const nav = useNavigate();
  const { isMobile } = useDeviceSize();
  const Token = localStorage.getItem("TOKEN");
  const [isOpen, setisOpen] = useState(false);
  const label = { inputProps: { "Continue as User": "Checkbox demo" } };
  const HandleToggler = () => setisOpen(!isOpen);
  const [selectedOption, setSelectedOption] = useState(""); // State to store the selected checkbox

  const handleCheckboxChange = (option) => {
    setSelectedOption(option); // Update the state with the selected option
  };
  const HandleNavigate = () => {
    nav("/user/dashboard");
  };
  console.log(isOpen);
  console.log(Token);
  const HandleRefer = () => {
    if (Token) {
      axios
        .post(
          POST_UPDATE_ROLE(),
          {},
          {
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          }
        )
        .then(({ data }) => {
          if (data?.status === "Success") {
            nav("/user/enquiries");
            console.log("Response:", data);
          } else {
            console.log(data);
            toast.error(data?.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            nav("/login");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      nav("/login");
    }
  };
  return (
    <React.Fragment>
      {isOpen && (
        <ModaalDark anchorEl={true}>
          <div className="Modal300">
            <span
              onClick={() => HandleToggler()}
              id="closeIcon"
              style={{
                display: "block",
                borderRadius: "50px",
                position: "absolute",
                top: "20px",
                right: "20px",
                background: "rgb(239 91 65)",
                fontSize: ".9em",
                width: "14px",
                height: "14px",
                zIndex: 10,
              }}
            />
            <div className="modalContent">
              <Typography
                align="center"
                sx={{
                  fontSize: "1.2em",
                  fontWeight: 700,
                }}
              >
                Login For Refferal
              </Typography>
              <Box className="FlexCenter mt-3" sx={{ minWidth: "200px" }}>
                <Checkbox
                  {...label}
                  id="Continue as User"
                  label="Label"
                  icon={<FaRegCircle />}
                  checkedIcon={<FaCircle />}
                  checked={selectedOption === "user"} // Only check if "user" is selected
                  onChange={() => handleCheckboxChange("user")}
                />
                <label
                  htmlFor="Continue as User"
                  className="m-0"
                  style={{ minWidth: "150px" }}
                >
                  Continue as User
                </label>
              </Box>

              <Box className="FlexCenter" sx={{ minWidth: "200px" }}>
                <Checkbox
                  {...label}
                  id="Continue as Agent"
                  label="Label"
                  icon={<FaRegCircle />}
                  checkedIcon={<FaCircle />}
                  checked={selectedOption === "agent"} // Only check if "agent" is selected
                  onChange={() => handleCheckboxChange("agent")} // Update state to "agent"
                />
                <label
                  htmlFor="Continue as Agent"
                  className="m-0"
                  style={{ minWidth: "150px" }}
                >
                  Continue as Agent
                </label>
              </Box>
              <div className="FlexCenter">
                <button className="btnRed mt-3 px-3" onClick={HandleNavigate}>
                  Continue
                </button>
              </div>
            </div>
          </div>
        </ModaalDark>
      )}
      <DefaultLayout>
        <InnerBannner
          HandleRefer={HandleRefer}
          ToggleRefer={HandleToggler}
          hideBread={true}
          title={bannerDetails.title}
          title2={bannerDetails.title2}
          title3={bannerDetails.title3}
          title4={bannerDetails.title4}
          desc={bannerDetails.desc}
          image={bannerDetails.bannerimage}
          type={bannerDetails.type}
        />
        {/* <Section bgcolor="1,1,0,0.02" margin={"40px 0"} padding={"70px 24px"}>
          <Container maxWidth={"xl"}>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              spacing={5}
            >
              <Grid
                item
                sx={{
                  textAlign: {
                    xs: "center",
                    md: "left",
                  },
                }}
                xs={12}
                md={6}
              >
                <Typography
                  variant="h4"
                  sx={{ color: `rgba(${theme.palette.primary.default},1)` }}
                >
                  Unlock earnings upto ₹ 20,000 by recommending Ambience Decorio
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: "center" }} xs={12} md={6}>
                <Link to="/login">
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      color: `rgba(${theme.palette.text.white},1)`,
                      padding: "15px 40px",
                      boxSizing: "border-box",
                      cursor: "inherit",
                    }}
                  >
                    Sign up Now
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Container>
        </Section> */}
        <Section
          bgcolor="243, 224, 212"
          margin={"0px 0"}
          padding={"20px 24px 70px 24px"}
        >
          <SectionTitle align="center" title="How it Works" />
          <Container maxWidth={"xl"}>
            <Grid container>
              {howItWorks.map((item, key) => (
                <Grid md="4" sx={{ textAlign: "center" }} key={key}>
                  <Box sx={{ padding: isMobile ? "20px" : "50px" }}>
                    <img
                      src={item.icon}
                      style={{ width: "90px" }}
                      col={item.col}
                      col2={item.col2}
                    />
                    <Typography
                      variant="h6"
                      sx={{ margin: "10px", fontWeight: 700 }}
                    >
                      {item.title}
                    </Typography>
                    <Typography sx={{ fontWeight: 500, fontSize: "15px" }}>
                      {item.desc}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Section>
        <Section
          bgcolor="255,255,255"
          margin={"-80px 0 20px 0"}
          padding={"20px 24px 70px 24px"}
        >
          {/* <SectionTitle
            align="center"
            title="Here's how you'll earn your referral amount"
          /> */}
          <Typography
            variant="h6"
            textAlign={"center"}
            sx={{
              margin: "10px",
              fontWeight: 700,
              fontSize: "1.8em",
              marginBottom: 6,
              "@media (max-width: 600px)": {
                fontSize: "1.5em",
              },
            }}
          >
            Here's how you'll earn your referral amount
          </Typography>
          <Box
            maxWidth={"75%"}
            margin={"auto"}
            // border={"1px solid gray"}
            borderRadius={"5px"}
          >
            <Grid container spacing={4} mt={1}>
              <Grid item lg={4} md={4} xs={12} className="h-100">
                <div className="referBox">
                  <p>when your referred friend visits the experience center</p>
                  <div className="FlexCenter">
                    <img src={refer1} alt="" />
                    <span>₹2,000</span>
                  </div>
                </div>
              </Grid>
              <Grid item lg={4} md={4} xs={12} className="h-100">
                <div className="referBox">
                  <p>when they book a project</p>
                  <div className="FlexCenter">
                    <img src={refer2} alt="" className="m-0" />
                    <span>₹7,000</span>
                  </div>
                </div>
              </Grid>
              <Grid item lg={4} md={4} xs={12}>
                <div className="referBox">
                  <p>
                    Receive the remaining amount once the final payment is made
                  </p>
                  <div className="FlexCenter">
                    <div className="imgBox">
                      <img src={refer3} alt="" className="m-0" />
                    </div>
                    <span className="smallText">Maximize Your Earnings!</span>
                  </div>
                </div>
              </Grid>
            </Grid>
            {/* <TableContainer className="m-0 p-0">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ ...cellStyleHeader, width: "50%" }}>
                      Rewards payout for referrer
                    </TableCell>
                    <TableCell style={{ ...cellStyleHeader, minWidth: "50%" }}>
                      Referrer get
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ ...cellStyle, borderLeft: 0 }}>
                      {" "}
                      when your referred friend visits the experience center.
                    </TableCell>
                    <TableCell style={{ ...cellStyle, borderRight: 0 }}>
                      ₹1,500
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ ...cellStyle, borderLeft: 0 }}>
                      {" "}
                      when they book a project.
                    </TableCell>{" "}
                    <TableCell style={{ ...cellStyle, borderRight: 0 }}>
                      ₹1,500
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ ...cellStyle, borderLeft: 0, borderBottom: 0 }}
                    >
                      {" "}
                      Receive the remaining amount once the final payment is
                      made.
                    </TableCell>
                    <TableCell
                      style={{ ...cellStyle, borderRight: 0, borderBottom: 1 }}
                    >
                      Maximize Your Earnings!
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer> */}
          </Box>
          <Box maxWidth={"90%"} m="4em auto">
            <Typography
              variant="h6"
              textAlign={"left"}
              sx={{
                margin: "10px",
                fontWeight: 700,
                fontSize: "15px",
                marginBottom: 5,
              }}
            >
              Terms & Conditions Apply
            </Typography>
            <Typography
              variant="h6"
              textAlign={"left"}
              sx={{
                margin: "10px 10px 0px 10px",
                fontWeight: 700,
                fontSize: "15px",
                marginBottom: 1,
              }}
            >
              Referral Program Eligibility
            </Typography>
            <Typography
              variant="h6"
              textAlign={"left"}
              sx={{
                margin: "0px 10px 10px 10px",
                fontWeight: 500,
                fontSize: "12.5px",
                marginBottom: 5,
              }}
            >
              The referral program is open to all existing Modulin customers who
              have completed a project with a valid Project ID. The person you
              refer must be a new customer and book a project with Modulin for a
              minimum value of ₹3.5 lakh.
            </Typography>
            <Typography
              variant="h6"
              textAlign={"left"}
              sx={{
                margin: "0px 10px 0px 10px",
                fontWeight: 700,
                fontSize: "15px",
                marginBottom: 1,
              }}
            >
              Earning Referral Rewards
            </Typography>
            <Typography
              variant="h6"
              textAlign={"left"}
              sx={{
                margin: "0px 10px 10px 10px",
                fontWeight: 600,
                fontSize: "12.5px",
                marginBottom: 1,
              }}
            >
              You will receive your referral rewards in three stages:
            </Typography>
            <ul
              style={{
                padding: 0,
                margin: 0,
                marginLeft: "2em",
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "20px",
              }}
            >
              <li style={{}}>
                ₹2,000 once your referred friend visits the Modulin Experience
                Center.
              </li>
              <li>₹7,000 once they book their project.</li>
              <li>
                The remaining amount will be transferred after the full project
                payment is completed.
              </li>
            </ul>
            <Typography
              variant="h6"
              textAlign={"left"}
              sx={{
                margin: "30px 10px 0px 10px",
                fontWeight: 700,
                fontSize: "15px",
                marginBottom: 1,
              }}
            >
              Unlimited Referrals
            </Typography>
            <Typography
              variant="h6"
              textAlign={"left"}
              sx={{
                margin: "0px 10px 10px 10px",
                fontWeight: 500,
                fontSize: "12.5px",
                marginBottom: 5,
              }}
            >
              There’s no cap on the number of friends you can refer. The more
              you refer, the more you earn.
            </Typography>
            <Typography
              variant="h6"
              textAlign={"left"}
              sx={{
                margin: "30px 10px 0px 10px",
                fontWeight: 700,
                fontSize: "15px",
                marginBottom: 1,
              }}
            >
              Reward Disbursement
            </Typography>
            <Typography
              variant="h6"
              textAlign={"left"}
              sx={{
                margin: "0px 10px 10px 10px",
                fontWeight: 500,
                fontSize: "12.5px",
                marginBottom: 5,
              }}
            >
              Payouts are processed on the 15th and 20th of each month. If your
              friend completes a milestone between the 1st and 14th, the payout
              will be made in the current month. For milestones achieved between
              the 15th and end of the month, payouts will be disbursed the
              following month.
            </Typography>
            <Typography
              variant="h6"
              textAlign={"left"}
              sx={{
                margin: "30px 10px 0px 10px",
                fontWeight: 700,
                fontSize: "15px",
                marginBottom: 1,
              }}
            >
              Payout Method
            </Typography>
            <Typography
              variant="h6"
              textAlign={"left"}
              sx={{
                margin: "0px 10px 10px 10px",
                fontWeight: 500,
                fontSize: "12.5px",
                marginBottom: 5,
              }}
            >
              All payouts will be processed via bank transfer. Our team will
              reach out to you for your bank details once your referral reaches
              the Experience Center.
            </Typography>
            <Typography
              variant="h6"
              textAlign={"left"}
              sx={{
                margin: "30px 10px 0px 10px",
                fontWeight: 700,
                fontSize: "15px",
                marginBottom: 1,
              }}
            >
              Referral Validity
            </Typography>
            <Typography
              variant="h6"
              textAlign={"left"}
              sx={{
                margin: "0px 10px 10px 10px",
                fontWeight: 500,
                fontSize: "12.5px",
                marginBottom: 5,
              }}
            >
              Referrals remain valid only if your friend completes the project
              with Modulin. Canceled or incomplete projects will not be eligible
              for rewards.
            </Typography>
          </Box>
        </Section>
      </DefaultLayout>
    </React.Fragment>
  );
};

export default ReferAFriend;

const cellStyle = {
  padding: "10px",
  border: "1px solid gray",
  fontSize: ".8em",
  fontWeight: 600,
  textAlign: "center",
};
const cellStyleHeader = {
  padding: "10px 8px",
  // borderColor: `rgba(${Theme.palette.text.white},.5)`, // You can change 'red' to your desired border color
  fontSize: 16.5,
  fontWeight: 600,
  backgroundColor: `rgba(233, 158, 110, 1)`,
  textAlign: "center",
  color: "white",
  // background: `rgba(${Theme.palette.background.tabBg1},1)`,
};

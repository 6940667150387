import React from "react";
import { FaAngleLeft, FaAngleRight, FaChevronLeft } from "react-icons/fa";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

export const Paginate = ({ Page, TotalPage, setTotalPage, setPage }) => {
  const HandlePrevious = () => {
    if (Page <= 1) {
      console.log(Page);
      return;
    } else {
      setPage(Page - 1);
    }
  };
  console.log(TotalPage);
  console.log(Page);
  const HandleNext = () => {
    if (TotalPage === Page) {
      console.log("run");
      return;
    } else {
      setPage(Page + 1);
    }
  };
  return (
    <div className="PageBox">
      <span className="">
        Page <span className="number">{Page || 0}</span> of{" "}
        <span className="number"> {TotalPage || 0}</span>
      </span>
      <MdChevronLeft onClick={HandlePrevious} />
      <MdChevronRight onClick={HandleNext} />
    </div>
  );
};

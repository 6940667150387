import React from "react";
//Custom Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBannner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
//data
import { bannerDetails } from "../../Data/Others/PartnerWithUs";

//Image and Icons
import Dummy from "../../Assets/dummy.jpg";
import Section from "../../Component/HelperComponents/Section";
import IMG2 from "../../Assets/images/partner/Im2.png";
import wh1 from "../../Assets/images/partner/wh1.png";
import wh2 from "../../Assets/images/partner/wh2.png";
import wh3 from "../../Assets/images/partner/wh3.png";

import St1 from "../../Assets/images/partner/St1.png";
import St2 from "../../Assets/images/partner/St2.png";
import St3 from "../../Assets/images/partner/St3.png";
import St4 from "../../Assets/images/partner/St4.png";
import St5 from "../../Assets/images/partner/St5.png";
import St6 from "../../Assets/images/partner/St6.png";

import one from "../../Assets/images/partner/1.png";
import two from "../../Assets/images/partner/2.png";
import three from "../../Assets/images/partner/3.png";
import four from "../../Assets/images/partner/4.png";
import five from "../../Assets/images/partner/5.png";
import {
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  Button,
} from "@mui/material";
import theme from "../../Theme";
import { Box } from "@mui/system";
import SectionTitle from "../../Component/HelperComponents/SectionTitle";
import useDeviceSize from "../../Service/device-size";
const PartnerWithUs = () => {
  const { isMobile } = useDeviceSize();
  return (
    <React.Fragment>
      <DefaultLayout>
        <InnerBannner
          isMobile={isMobile}
          title={bannerDetails.title}
          desc={bannerDetails.desc}
          image={bannerDetails.bannerimage}
          type={bannerDetails.type}
        />
        <Box maxWidth={"75%"} m={"auto"}>
          <Grid container>
            <Grid
              lg={6}
              md="6"
              className="FlexCenter"
              flexDirection={"column"}
              alignItems={"start"}
            >
              {/* <Box className="FlexCenter"> */}
              <SectionTitle
                title="Why Partner with Us?"
                shadowtext=""
                variant={"h5"}
              />
              <ul
                style={{
                  marginLeft: "1.6em",
                  lineHeight: "25px",
                  fontWeight: 500,
                  // color:
                }}
              >
                <li>Access cutting-edge modular solutions.</li>
                <li>
                  Collaborate with a trusted brand recognized for quality.
                </li>
                <li>
                  Expand your business with industry-leading support and
                  resources.
                </li>
              </ul>
              {/* </Box> */}
            </Grid>

            <Grid lg={6} md="6" m={"auto"} className="FlexCenter">
              <img
                src={IMG2}
                alt=""
                style={{
                  width: "80%",
                  margin: "auto",
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Section
          bgcolor="255, 170, 115"
          margin={"0px 0"}
          padding={"40px 24px 70px 24px"}
        >
          <SectionTitle
            align="center"
            title="Benefits you get as a Partner"
            fontSize="30px"
            fontWeight={600}
          />
          <Box maxWidth={isMobile ? "90%" : "80%"} m={"auto"}>
            <Grid container className="mt-4">
              <Grid
                item
                lg="4"
                md="4"
                xs="12"
                className="FlexCenter gap-2"
                flexDirection={"column"}
                marginTop={isMobile ? 3 :0}
              >
                <img
                  src={one}
                  alt=""
                  
                  style={{
                    width: "75px",
                  }}
                />
                <Typography fontSize={".98em"} fontWeight={600}>
                  Seamless Integration
                </Typography>
                <Typography
                  fontSize={".85em"}
                  maxWidth={isMobile ? "100%" : "80%"}
                  align="center"
                >
                  Join hands with Modulin for a smoothand hassle-free
                  collaboration.
                </Typography>
              </Grid>
              <Grid
                item
                lg="4"
                md="4"
                xs="12"
                className="FlexCenter gap-2"
                flexDirection={"column"}
                marginTop={isMobile ? 3 :0}
              >
                <img
                  src={two}
                  alt=""
                  style={{
                    width: "75px",
                  }}
                />
                <Typography fontSize={".98em"} fontWeight={600}>
                  Exclusive Resources
                </Typography>
                <Typography
                  fontSize={".85em"}
                  maxWidth={isMobile ? "100%" : "80%"}
                  align="center"
                >
                  Access advanced tools, design resources, and project support
                  for maximum efficiency.
                </Typography>
              </Grid>
              <Grid
                item
                lg="4"
                md="4"
                xs="12"
                className="FlexCenter gap-2"
                flexDirection={"column"}
                marginTop={isMobile ? 3 :0}
              >
                <img
                  src={three}
                  alt=""
                  style={{
                    width: "75px",
                  }}
                />
                <Typography fontSize={".98em"} fontWeight={600}>
                  Co-Marketing Opportunities
                </Typography>
                <Typography
                  fontSize={".85em"}
                  maxWidth={isMobile ? "100%" : "80%"}
                  align="center"
                >
                  Benefit from joint marketing campaigns and grow your
                  visibility.
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box maxWidth={isMobile ? "90%" : "60%"} m={"auto"}>
            <Grid container className="mt-4">
              <Grid
                item
                lg="6"
                md="6"
                xs="12"
                className="FlexCenter gap-2"
                flexDirection={"column"}
                marginTop={isMobile ? 3 :0}
              >
                <img
                  src={four}
                  alt=""
                  style={{
                    width: "75px",
                  }}
                />
                <Typography fontSize={".98em"} fontWeight={600}>
                  Dedicated Support
                </Typography>
                <Typography
                  fontSize={".85em"}
                  maxWidth={isMobile ? "100%" : "80%"}
                  align="center"
                >
                  Our dedicated team is here to assist you at every step,
                  ensuring success in every project.
                </Typography>
              </Grid>
              <Grid
                item
                lg="6"
                md="6"
                xs="12"
                className="FlexCenter gap-2"
                flexDirection={"column"}
                marginTop={isMobile ? 3 :0}
              >
                <img
                  src={five}
                  alt=""
                  style={{
                    width: "75px",
                  }}
                />
                <Typography fontSize={".98em"} fontWeight={600}>
                  Growth Potential
                </Typography>
                <Typography
                  fontSize={".85em"}
                  maxWidth={isMobile ? "100%" : "80%"}
                  align="center"
                >
                  Unlock the potential for expansion through new projects and
                  partnerships.
                </Typography>
              </Grid>
            </Grid>
            <div className="FlexCenter mt-4">
              <button className="btnRed mt-4">Ready to get started ?</button>
            </div>
          </Box>
        </Section>
        <Section
          bgcolor="255, 255, 255"
          margin={"20px 0"}
          padding={"40px 24px 70px 24px"}
        >
          <SectionTitle
            align="center"
            title="Why Choose us"
            fontSize="26px"
            fontWeight={700}
          />
          <Box maxWidth={"80%"} m={"2em auto"}>
            <Grid container>
              <Grid lg={4} md="4" xs="12">
                <Box className="FlexCenter" flexDirection={"column"}>
                  <img
                    src={wh1}
                    alt=""
                    className="h-100"
                    style={{ width: "200px" }}
                  />
                  <h5 className="mt-2 fw500">Build with Modular Excellence</h5>
                </Box>
              </Grid>
              <Grid lg={4} md="4" xs="12">
                <Box className="FlexCenter" flexDirection={"column"}>
                  <img
                    src={wh2}
                    alt=""
                    // className="h-100"
                    style={{ width: "137px" }}
                  />
                  <h5 className="mt-2 fw500">
                    Collaborating with the Best Brands
                  </h5>
                </Box>
              </Grid>
              <Grid lg={4} md="4" xs="12">
                <Box className="FlexCenter" flexDirection={"column"}>
                  <img
                    src={wh3}
                    alt=""
                    // className="h-100"
                    style={{ width: "137px" }}
                  />
                  <h5 className="mt-2 fw500">
                    Grow with Expert Modular Support
                  </h5>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Section>
        <Section
          bgcolor="243, 224, 212"
          margin={"0px 0"}
          padding={"40px 24px 70px 24px"}
        >
          <SectionTitle
            align="center"
            title="6 steps to become a Partner. See how it works."
            fontSize="27px"
            fontWeight="600"
          />
          <Box
            maxWidth={"90%"}
            m="3em auto"
            sx={{
              position: "relative",
            }}
          >
            <Box
              position={"absolute"}
              top={"100px"}
              maxWidth={"85%"}
              m={"auto"}
              sx={{
                borderTop: "1px dashed gray",
              }}
            ></Box>
            <Grid container m={"auto"}>
              <Grid
                lg="2"
                md="2"
                xs="12"
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <img
                  src={St1}
                  alt=""
                  style={{
                    width: "130px",
                  }}
                />
                <Typography fontWeight={600}>Submit Your Details</Typography>
              </Grid>
              <Grid
                lg="2"
                md="2"
                xs="12"
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <img
                  src={St2}
                  alt=""
                  style={{
                    width: "130px",
                  }}
                />
                <Typography fontWeight={600}>Eligibility Check</Typography>
              </Grid>
              <Grid
                lg="2"
                md="2"
                xs="12"
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <img
                  src={St3}
                  alt=""
                  style={{
                    width: "130px",
                  }}
                />
                <Typography fontWeight={600}>Consultation</Typography>
              </Grid>
              <Grid
                lg="2"
                md="2"
                xs="12"
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <img
                  src={St4}
                  alt=""
                  style={{
                    width: "130px",
                  }}
                />
                <Typography fontWeight={600}>Contract Agreement</Typography>
              </Grid>
              <Grid
                lg="2"
                md="2"
                xs="12"
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <img
                  src={St5}
                  alt=""
                  style={{
                    width: "130px",
                  }}
                />
                <Typography fontWeight={600}>Project Kickoff</Typography>
              </Grid>
              <Grid
                lg="2"
                md="2"
                xs="12"
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <img
                  src={St6}
                  alt=""
                  style={{
                    width: "130px",
                  }}
                />
                <Typography fontWeight={600}>Grow Together</Typography>
              </Grid>
            </Grid>
          </Box>
        </Section>
        <Box maxWidth={"90%"} m="3em auto">
          <Typography
            variant="h6"
            textAlign={"left"}
            sx={{
              margin: "10px",
              fontWeight: 700,
              fontSize: "15px",
              marginBottom: 5,
            }}
          >
            Terms & Conditions Apply
          </Typography>
          <Typography
            variant="h6"
            textAlign={"left"}
            sx={{
              margin: "10px 10px 0px 10px",
              fontWeight: 700,
              fontSize: "15px",
              marginBottom: 1,
            }}
          >
            Eligibility for Partnership
          </Typography>
          <Typography
            variant="h6"
            textAlign={"left"}
            sx={{
              margin: "0px 10px 10px 10px",
              fontWeight: 500,
              fontSize: "12.5px",
              marginBottom: 5,
            }}
          >
            The partnership program is open to businesses, contractors, interior
            designers, and industry professionals interested in collaborating
            with Modulin Interiors. Each partnership application will be subject
            to eligibility and background checks.
          </Typography>
          <Typography
            variant="h6"
            textAlign={"left"}
            sx={{
              margin: "0px 10px 0px 10px",
              fontWeight: 700,
              fontSize: "15px",
              marginBottom: 1,
            }}
          >
            Contract and Agreement
          </Typography>
          <Typography
            variant="h6"
            textAlign={"left"}
            sx={{
              margin: "0px 10px 10px 10px",
              fontWeight: 600,
              fontSize: "12.5px",
              marginBottom: 1,
            }}
          >
            Upon approval, both parties must sign a formal agreement outlining
            the scope of collaboration, responsibilities, and revenue-sharing
            model. Any breach of contract by either party may result in
            termination of the partnership.
          </Typography>

          <Typography
            variant="h6"
            textAlign={"left"}
            sx={{
              margin: "30px 10px 0px 10px",
              fontWeight: 700,
              fontSize: "15px",
              marginBottom: 1,
            }}
          >
            Project Assignment
          </Typography>
          <Typography
            variant="h6"
            textAlign={"left"}
            sx={{
              margin: "0px 10px 10px 10px",
              fontWeight: 500,
              fontSize: "12.5px",
              marginBottom: 5,
            }}
          >
            All projects referred by Modulin or initiated under the partnership
            will be governed by mutually agreed-upon timelines, budgets, and
            deliverables. Any changes to these must be communicated in writing
            and approved by both parties.
          </Typography>
          <Typography
            variant="h6"
            textAlign={"left"}
            sx={{
              margin: "30px 10px 0px 10px",
              fontWeight: 700,
              fontSize: "15px",
              marginBottom: 1,
            }}
          >
            Exclusivity Clause
          </Typography>
          <Typography
            variant="h6"
            textAlign={"left"}
            sx={{
              margin: "0px 10px 10px 10px",
              fontWeight: 500,
              fontSize: "12.5px",
              marginBottom: 5,
            }}
          >
            Partners may be asked to work exclusively with Modulin on certain
            projects, depending on the collaboration's nature and scale.
          </Typography>
          <Typography
            variant="h6"
            textAlign={"left"}
            sx={{
              margin: "30px 10px 0px 10px",
              fontWeight: 700,
              fontSize: "15px",
              marginBottom: 1,
            }}
          >
            Confidentiality and Intellectual Property
          </Typography>
          <Typography
            variant="h6"
            textAlign={"left"}
            sx={{
              margin: "0px 10px 10px 10px",
              fontWeight: 500,
              fontSize: "12.5px",
              marginBottom: 5,
            }}
          >
            All project-related information, designs, and intellectual property
            shared between partners must remain confidential and used solely for
            partnership activities.
          </Typography>
          <Typography
            variant="h6"
            textAlign={"left"}
            sx={{
              margin: "30px 10px 0px 10px",
              fontWeight: 700,
              fontSize: "15px",
              marginBottom: 1,
            }}
          >
            Payment Terms
          </Typography>
          <Typography
            variant="h6"
            textAlign={"left"}
            sx={{
              margin: "0px 10px 10px 10px",
              fontWeight: 500,
              fontSize: "12.5px",
              marginBottom: 5,
            }}
          >
            Payment for services rendered under the partnership will be
            processed according to the agreed schedule outlined in the contract.
            Late payments may be subject to penalties.
          </Typography>
        </Box>
      </DefaultLayout>
    </React.Fragment>
  );
};

export default PartnerWithUs;

import React from "react";
import ModaalDark from "../../../Component/HelperComponents/Modal/ModalDark";
import { Avatar, Grid, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  OutlinedInput,
  Select,
} from "@mui/material";
import theme from "../../../Theme";
import { Box } from "@mui/system";
import Boy from "../../../Assets/boy.jpg";
import Girl from "../../../Assets/girl.jpg";
export const ViewProfileModal = ({ Toggler, Data }) => {
  const handleFormSubmit = () => {};
  const ProfileData = {};
  console.log(Data);
  return (
    <ModaalDark anchorEl={true}>
      <div className="Modal1200">
        <Typography
          variant="body2"
          align="left"
          sx={{
            //   fontSize: "1.2em",
            fontWeight: 700,
          }}
        >
          View Details
        </Typography>
        <span
          onClick={() => Toggler()}
          id="closeIcon"
          style={{
            display: "block",
            borderRadius: "50px",
            position: "absolute",
            top: "20px",
            right: "20px",
            background: "rgb(239 91 65)",
            fontSize: ".9em",
            width: "14px",
            height: "14px",
            zIndex: 10,
          }}
        />
        <div className="modalContent">
          <Box
            className="ProfileWeb p-0 "
            sx={{
              margin: 0,
            }}
          >
            {/* <Typography variant="h6" sx={{ marginBottom: "10px" }}>
              Profile{" "}
            </Typography> */}
            <Grid container>
              <Grid item lg="9" md="9">
                <Box
                  sx={{
                    display: "flex",
                    // flexDirection: "column",
                    gap: "20px",
                    marginRight: "70px",
                    marginBottom: "0px",
                  }}
                >
                  <Avatar
                    variant="square"
                    sx={{
                      width: "100px",
                      height: "100px",
                      aspectRatio: "1/1",
                      border: "1px solid rgb(169, 165, 165)",
                      borderRadius: 2,
                    }}
                  >
                    <div className="Pr_img">
                      <div className="Pr_box_img">
                        <img
                          src={
                            Data?.profileImage
                              ? `https://api.modulin.in${Data?.profileImage}`
                              : Data?.gender === "Male"
                              ? Boy
                              : Girl
                          }
                          width={"100%"}
                        />
                      </div>
                    </div>
                  </Avatar>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0px",
                    }}
                  >
                    <Typography variant="small">
                      {Data?._id?.slice(-8)?.toUpperCase()}
                    </Typography>
                    <Typography variant="h6">
                      {Data?.first_name + " " + Data?.last_name}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                lg="3"
                md="3"
                className="FlexEnd"
                alignItems={"center"}
              >
                {/* <button
                  className="btnYellow"
                  onClick={
                    ModalType === "User"
                      ? () => {
                          setModalType("User");
                          handleToggler();
                        }
                      : () => {
                          setModalType("Agent");
                          handleToggler();
                        }
                  }
                >
                  Add Details
                </button> */}
              </Grid>
              <div className="Candidate mt-3">
                <h6 className="heading">Personal Info</h6>
                <div className="Right_box">
                  <div className="GridCandidate mt-2">
                    <div className="box">
                      <label>Agent Id </label>
                      <p> {Data?._id?.slice(-8)?.toUpperCase()}</p>
                    </div>
                    <div className="box">
                      <label> First Name</label>
                      <p> {Data?.first_name}</p>
                    </div>
                    <div className="box">
                      <label>Last Name </label>
                      <p> {Data?.last_name}</p>
                    </div>

                    <div className="box">
                      <label>Phone No </label>
                      <p> {Data?.mobile_num?.slice(-10)}</p>
                    </div>
                    <div className="box">
                      <label>Alternate No </label>
                      <p> {Data?.alternate_num}</p>
                    </div>
                    <div className="box">
                      <label> Email ID</label>
                      <p> {Data?.email}</p>
                    </div>
                    <div className="box">
                      <label>Gender </label>
                      <p> {Data?.gender}</p>
                    </div>

                    <div className="box">
                      <label>Marital Status </label>
                      <p> {Data?.marital_status}</p>
                    </div>
                  </div>
                  <div className="Grid2 mt-2">
                    <div className="box">
                      <label
                        style={{
                          fontSize: ".8em",
                          color: "#000",
                          fontWeight: 700,
                        }}
                      >
                        Current Address{" "}
                      </label>
                      <p className="mt-1"> {Data?.current_address}</p>
                    </div>{" "}
                    <div className="box">
                      <label
                        style={{
                          fontSize: ".8em",
                          color: "#000",
                          fontWeight: 700,
                        }}
                      >
                        Parmanent Address{" "}
                      </label>
                      <p className="mt-1"> {Data?.permanent_address}</p>
                    </div>
                  </div>
                </div>
              </div>
              {Data?.role === "Agent" && (
                <div className="Candidate mt-3">
                  <h6 className="heading">Bank Details</h6>
                  <div className="Right_box">
                    <div className="GridCandidate mt-2">
                      <div className="box">
                        <label> Bank Name</label>
                        <p> {Data?.bank_name}</p>
                      </div>
                      <div className="box">
                        <label>Account Number </label>
                        <p> {Data?.account_num}</p>
                      </div>

                      <div className="box">
                        <label>IFSC Code </label>
                        <p> {Data?.ifsc_code}</p>
                      </div>
                      <div className="box">
                        <label>Holder Name </label>
                        <p> {Data?.holder_name}</p>
                      </div>
                      <div className="box">
                        <label> Branch</label>
                        <p> {Data?.branch}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
            <Grid flex={1}></Grid>
          </Box>
        </div>
      </div>
    </ModaalDark>
  );
};

import React from "react";
import CopyRights from "../../../Component/HelperComponents/CopyRights";
import { Box, Container, AppBar, Toolbar, Typography } from "@mui/material";
import theme from "../../../Theme";

const Footer = () => {
  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          bottom: "0",
          backgroundColor: `rgba(${theme.palette.text.darktheme},.9)`,
          padding: "10px 0",
       
        }}
      >
        {/* <Container maxWidth={false}> */}
          <CopyRights />
        {/* </Container> */}
      </Box>
    </React.Fragment>
  );
};

export default Footer;

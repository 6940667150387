import React, { useEffect, useState } from "react";
import DefaultLayout from "../Layout/DefaultLayout";
import {
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { AgentTable, UserTable } from "./AgentTable";
import { POST_USER_AGENTS_ADMIN } from "../../../Service/ApiRoutes";
import axios from "axios";

const Agent = () => {
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "slno",
      headerName: "Sl.No",
      minWidth: 150,
      editable: true,
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 120,
    },
    {
      field: "formtype",
      headerName: "Form Type",
      minWidth: 120,
      //editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
    },
  ];

  const rows = [
    {
      id: 1,
      slno: "bd125364854823",
      date: "10/07/2023",
      formtype: "Consultation",
      status: "In Progress",
    },
    {
      id: 2,
      slno: "c125364853987",
      date: "09/07/2023",
      formtype: "Quote",
      status: "Completed",
    },
    {
      id: 3,
      slno: "aa564857845632",
      date: "08/07/2023",
      formtype: "Consultation",
      status: "Cancelled",
    },
    {
      id: 4,
      slno: "ef125364854154",
      date: "07/07/2023",
      formtype: "Quote",
      status: "In Progress",
    },
    {
      id: 5,
      slno: "bd125364854823",
      date: "06/07/2023",
      formtype: "Consultation",
      status: "Completed",
    },
    {
      id: 6,
      slno: "gd235469823641",
      date: "05/07/2023",
      formtype: "Quote",
      status: "In Progress",
    },
    {
      id: 7,
      slno: "cd125364854135",
      date: "04/07/2023",
      formtype: "Consultation",
      status: "Cancelled",
    },
    {
      id: 8,
      slno: "bh125364857896",
      date: "03/07/2023",
      formtype: "Quote",
      status: "Completed",
    },
    {
      id: 9,
      slno: "ed125364854823",
      date: "02/07/2023",
      formtype: "Consultation",
      status: "In Progress",
    },
    {
      id: 10,
      slno: "bd125364854823",
      date: "01/07/2023",
      formtype: "Quote",
      status: "Completed",
    },
    {
      id: 11,
      slno: "bc125364854821",
      date: "30/06/2023",
      formtype: "Consultation",
      status: "In Progress",
    },
    {
      id: 12,
      slno: "ca125364856503",
      date: "29/06/2023",
      formtype: "Quote",
      status: "Cancelled",
    },
    {
      id: 13,
      slno: "bg125364854821",
      date: "28/06/2023",
      formtype: "Consultation",
      status: "Completed",
    },
    {
      id: 14,
      slno: "be125364854820",
      date: "27/06/2023",
      formtype: "Quote",
      status: "In Progress",
    },
    {
      id: 15,
      slno: "ba125364856784",
      date: "26/06/2023",
      formtype: "Consultation",
      status: "Completed",
    },
  ];
  const Token = localStorage.getItem("TOKEN");
  const Role = localStorage.getItem("ROLE");
  const [workProfile, setworkProfile] = useState("");
  const [Status, setStatus] = useState("");
  const [UserAgentData, setUserAgentData] = useState([]);
  const [Page, setPage] = useState(1);
  const [TotalPage, setTotalPage] = useState("");
  const HandleGetUSerAgents = () => {
    const Payload = {
      workProfile: workProfile,
      status: Status,
      page: Page,
    };
    axios
      .post(POST_USER_AGENTS_ADMIN(), Payload, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
      .then(({ data }) => {
        console.log(data);
        setPage(data?.pagination?.agents?.currentPage);
        setTotalPage(data?.pagination?.agents?.totalPages);
        setUserAgentData(data?.data);
      });
  };

  useEffect(() => {
    HandleGetUSerAgents();
  }, [workProfile, Status ,Page]);
  console.log(UserAgentData);

  return (
    <React.Fragment>
      <DefaultLayout>
        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
          Agents <span>({UserAgentData?.agents?.length})</span>
        </Typography>

        <div
          className="FleLeft gap-3"
          style={{
            width: "270px",
          }}
        >
          <div
            className=""
            style={{
              maxWidth: "150px",
              marginTop: 2,
            }}
          >
            <label
              htmlFor="leaveId"
              style={{
                fontSize: ".9em",
              }}
              className="mb-2 fw500"
            >
              Work Profile
            </label>
            <FormControl fullWidth className="CustomSelectDropSmallGray">
              <InputLabel id="leaveId">Select</InputLabel>
              <Select
                labelId="leaveId"
                id="demo-simple-leave"
                label="Select"
                value={workProfile}
                name="loan_type"
                className="Custom_Select"
              >
                <MenuItem
                  value={"Reset"}
                  key={"Reset"}
                  onClick={() => {
                    setworkProfile("");
                  }}
                >
                  Reset
                </MenuItem>
                {[
                  "Architect",
                  "Interior Designer",
                  "Civil Engineer",
                  "Carpenter",
                  "Marketing Agent",
                  "Client",
                  "Others",
                ]?.map((Elem) => {
                  return (
                    <MenuItem
                      value={Elem}
                      key={Elem}
                      onClick={() => {
                        setworkProfile(Elem);
                      }}
                    >
                      {Elem}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div
            className=""
            style={{
              maxWidth: "50%",
              marginTop: 2,
              minWidth: "50%",
            }}
          >
            <label
              htmlFor="leaveId"
              style={{
                fontSize: ".9em",
              }}
              className="mb-2 fw500"
            >
              Status
            </label>
            <FormControl fullWidth className="CustomSelectDropSmallGray">
              <InputLabel id="leaveId">Select</InputLabel>
              <Select
                labelId="leaveId"
                id="demo-simple-leave"
                label="Select"
                value={Status}
                name="loan_type"
                className="Custom_Select"
              >
                <MenuItem
                  value={"Reset"}
                  key={"Reset"}
                  onClick={() => {
                    setStatus("");
                  }}
                >
                  Reset
                </MenuItem>
                {["Active", "In Active"]?.map((Elem) => {
                  return (
                    <MenuItem
                      value={Elem}
                      key={Elem}
                      onClick={() => {
                        setStatus(Elem);
                      }}
                    >
                      {Elem}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>

        <AgentTable
          Data={UserAgentData?.agents}
          Refetch={HandleGetUSerAgents}
          TotalPage={TotalPage}
          setTotalPage={setTotalPage}
          Page={Page}
          setPage={setPage}
        />
        {/* <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            //autoHeight
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box> */}
      </DefaultLayout>
    </React.Fragment>
  );
};

export default Agent;

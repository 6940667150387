import React from "react";

import Slide01 from "../../Assets/Refer/main.png";
import { kanist, anyonya, uttama } from "../../Assets/icons/icons";
import one from "../../Assets/Refer/1.png";
import two from "../../Assets/Refer/2.png";
import three from "../../Assets/Refer/3.png";
// Bg Consultation Form Section
import BgConsultationFormSection from "../../Assets/images/studyarea/Sa01.jpg";

const bannerDetails = {
  type: "fullimageMore",
  title: `Refer, Relax, Rejoice`,
  title2: (
    <span
      style={{
        fontSize: ".6em",
        color: "white",
      }}
    >
      ...with
    </span>
  ),
  title3: (
    <span
      style={{
        color: "red",
      }}
    >
      3%
    </span>
  ),
  title4: <span style={{
    fontSize: ".6em",
    color: "white",
  }}>of the project's value</span>,
  hightlight: "",
  desc: "Share the joy of premium interiors and earn with Modulin!",
  bannerimage: Slide01,
};

const howItWorks = [
  {
    id: 1,
    icon: one,
    col: "black",
    col2: "#FECE78",
    title: "Refer",
    desc: "Share your friend’s details and introduce them to Modulin’s stylish and functional interiors",
  },
  {
    id: 2,
    icon: two,
    col: "black",
    col2: "#FFB97E",
    title: "Relax",
    desc: "Sit back while your friend explores Modulin and books their project",
  },
  {
    id: 3,
    icon: three,
    col: "black",
    col2: "#FF7C7C",
    title: "Rejoice ",
    desc: "Earn rewards as your referral progresses, with payouts at each milestone!",
  },
];

const consultationFormSectionDetails = {
  image: BgConsultationFormSection,
  title: "Transform your dream interiors into reality with a single click",
  bgcolor: "0,0,0,.2",
};

export { bannerDetails, howItWorks, consultationFormSectionDetails };

import React, { useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";

import { toast } from "react-toastify";
import * as Yup from "yup";
import { MdOutlineFileUpload } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";
import ModaalDark from "../../../Component/HelperComponents/Modal/ModalDark";
import { POST_ADD_JOB } from "../../../Service/ApiRoutes";
import useDeviceSize from "../../../Service/device-size";

export const AddJobpositionModal = ({ Toggler, Refetch }) => {
  const { isMobile } = useDeviceSize();
  const [Position, setPosition] = useState("");
  const Validation = Yup.object({
    position: Yup.string().required(" Enter your designation"),
    name: Yup.string().required(" Enter your name "),
    experience: Yup.string().required("Enter your experience"),
    email: Yup.string().email().required("Enter your email"),
    phone_number: Yup.string()
      .required("Phone is required")
      .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
    // portfolio: Yup.string().required(" Enter link"),
    resume: Yup.string().required("please upload file"),
    applied_before: Yup.string().required("Choose one option"),
  });

  const [InputFile, setInputFile] = useState("");
  const HandleGetFile = (e) => {
    console.log(e.target);
    setInputFile(e.target.files);
  };

  const HandleEmpty = () => {
    setInputFile("");
  };
  // Condition
  const [ConditionCheck, setConditionCheck] = useState(null);
  const CheckboxCondition = (shift) => {
    setConditionCheck(shift);
  };

  const Initial = {
    position: "",
    name: "",
    phone_number: "",
    email: "",
    experience: "",
    applied_before: "",
    portfolio: "",
    resume: "",
  };

  const handleFormSubmit = (values) => {
    const formData = new FormData();
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        formData.append(key, values[key]);
      }
    }

    axios
      .post(POST_ADD_JOB(), formData)
      .then((data) => {
        console.log(data);
        toast.success("Applied", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        Toggler();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <>
      <ModaalDark anchorEl={true}>
        <div className="employe_view_departent AddJobPositionModal">
          <Typography
            variant="body2"
            align="left"
            sx={{
              //   fontSize: "1.2em",
              fontWeight: 700,
            }}
          >
            Add Job Position
          </Typography>
          <span
            onClick={() => Toggler()}
            id="closeIcon"
            style={{
              display: "block",
              borderRadius: "50px",
              position: "absolute",
              top: "20px",
              right: "20px",
              background: "rgb(239 91 65)",
              fontSize: ".9em",
              width: "14px",
              height: "14px",
              zIndex: 10,
            }}
          />
          <div className="modalContent">
            <Formik
              initialValues={Initial}
              validationSchema={Validation}
              // enableReinitialize
              onSubmit={handleFormSubmit}
            >
              {({ setFieldValue, handleSubmit }) => (
                <Form>
                  <div className="Grid5 gap-3 m-0 ">
                    {" "}
                    <div className="">
                      <label htmlFor="leaveId" className="mb-2 fs-7 fw600">
                        Position
                      </label>
                      <FormControl
                        fullWidth
                        className="CustomSelectDropNormalGray"
                      >
                        <InputLabel id="leaveId">Select</InputLabel>
                        <Select
                          labelId="leaveId"
                          id="demo-simple-leave"
                          label="Select"
                          name="position"
                          value={Position}
                          className="Custom_Select"
                          sx={{
                            '& .MuiSelect-icon': {
                              display: Position ? 'none' : 'block', // Hide the dropdown icon if a value is selected
                            },
                          }}
                        >
                          {[
                            "Design & Studio Manager",
                            "Operational Manager",
                            "Factory Incharge",
                            "Operational Assistant",
                            "Machine Operator",
                            "Others",
                          ]?.map((Elem) => {
                            return (
                              <MenuItem
                                value={Elem}
                                key={Elem}
                                onClick={() => {
                                  setFieldValue("position", Elem);
                                  setPosition(Elem);
                                }}
                              >
                                {Elem}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <ErrorMessage
                        name="position"
                        component="div"
                        className="errors"
                        style={{
                          marginTop: "0px",
                        }}
                      />
                    </div>
                    <div className=" ">
                      <div className="sec_subBox">
                        <label className="mb-2 nowrap fw600 fs-7" htmlFor="">
                          Enter Name
                        </label>

                        <Field name="name">
                          {({ field }) => (
                            <TextField
                              {...field}
                              className="My_fieldBoxNormalGray"
                              fullWidth
                              autoComplete="off"
                              id="outlined-basic"
                              label="Enter Name"
                              variant="outlined"
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name={`name`}
                          component="div"
                          className="errors fw500 fs-7"
                        />
                      </div>
                    </div>
                    <div className=" ">
                      <div className="sec_subBox">
                        <label className="mb-2 nowrap fw600 fs-7" htmlFor="">
                          Phone
                        </label>

                        <Field name="phone_number">
                          {({ field }) => (
                            <TextField
                              {...field}
                              className="My_fieldBoxNormalGray"
                              fullWidth
                              type="number"
                              autoComplete="off"
                              id="outlined-basic"
                              label="Enter Number"
                              variant="outlined"
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name={`phone_number`}
                          component="div"
                          className="errors  fs-7"
                        />
                      </div>
                    </div>
                    <div className=" ">
                      <div className="sec_subBox">
                        <label className="mb-2 nowrap fw600 fs-7" htmlFor="">
                          Email
                        </label>

                        <Field name="email">
                          {({ field }) => (
                            <TextField
                              {...field}
                              className="My_fieldBoxNormalGray"
                              fullWidth
                              type="email"
                              autoComplete="off"
                              id="outlined-basic"
                              label="Enter Email"
                              variant="outlined"
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name={`email`}
                          component="div"
                          className="errors fw500 fs-7"
                        />
                      </div>
                    </div>
                    <div className=" ">
                      <div className="sec_subBox">
                        <label className="mb-2 nowrap fw600 fs-7" htmlFor="">
                          Experience
                        </label>

                        <Field name="experience">
                          {({ field }) => (
                            <TextField
                              {...field}
                              className="My_fieldBoxNormalGray"
                              fullWidth
                              type="number"
                              autoComplete="off"
                              id="outlined-basic"
                              label="Enter Number"
                              variant="outlined"
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name={`experience`}
                          component="div"
                          className="errors fw500 fs-7"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row px-4 mt-3 ">
                    <div className="col-lg-12 mb-3">
                      <div className="sec_subBox">
                        <label className="mb-2 nowrap fw600 fs-7" htmlFor="">
                          Please Provide Your Portfolio Link
                        </label>

                        <Field name="portfolio">
                          {({ field }) => (
                            <TextField
                              {...field}
                              className="My_fieldBoxNormalGray"
                              fullWidth
                              autoComplete="off"
                              id="outlined-basic"
                              label="Enter Link"
                              variant="outlined"
                            />
                          )}
                        </Field>
                          {/* <ErrorMessage
                            name={`portfolio`}
                            component="div"
                            className="errors fw500 fs-7"
                          /> */}
                      </div>
                    </div>
                    <div
                      className=""
                      style={{
                        position: "relative",
                      }}
                    >
                      <div
                        className="Grid4 gap-3 m-0 mt-3  mb-3"
                        style={{ gap: isMobile ? "10px" : "15px" }}
                      >
                        <div>
                          <Button
                            variant=""
                            component="label"
                            sx={{
                              fontWeight: 500,
                              // fontSize: "1.1em",
                              marginTop: "0em",
                              textWrap: "nowrap",
                              padding: "0em 1.2em",
                            }}
                          >
                            <span
                              style={{
                                fontSize: ".8em",
                                marginTop: "-30px",
                                fontWeight: 600,
                              }}
                            >
                              Upload Resume
                            </span>
                            <input
                              type="file"
                              hidden
                              onChange={(e) => {
                                console.log(e.target.files[0]);
                                setFieldValue("resume", e.target.files[0]);
                                HandleGetFile(e);
                              }}
                              accept="application/pdf"
                              multiple
                            ></input>
                            <br />
                            <br />
                            <MdOutlineFileUpload
                              className="mt-4 position-absolute"
                              style={{
                                fontSize: "1.8em",
                                position: "absolute",
                                top: "0px",
                              }}
                            />
                          </Button>
                          {InputFile && (
                            <p className="selectFile">
                              {InputFile?.length} Files
                              <IoIosCloseCircleOutline onClick={HandleEmpty} />
                            </p>
                          )}
                          <ErrorMessage
                            name={`resume`}
                            component="div"
                            className="errors fw500 fs-7 ms-3"
                          />
                        </div>
                        <div className="col-lg-6 pt-2">
                          <label
                            htmlFor=""
                            style={{
                              fontSize: ".8em",
                              marginTop: "-30px",
                              fontWeight: 600,
                            }}
                          >
                            Have Attend Before?
                          </label>
                          <div
                            className="SetAttend Wfh_type d-flex justify-content-   "
                            style={{ gap: isMobile ? "10px" : "15px" }}
                          >
                            {["Yes", "No"].map((condition, index) => (
                              <div
                                key={index}
                                className="CheckCircleSmall mt-2"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  id={`regRequestCheckbox${index + 3}`}
                                  d
                                  checked={ConditionCheck === condition}
                                  name="applied_before"
                                  onChange={() => {
                                    setFieldValue("applied_before", condition);
                                    CheckboxCondition(condition);
                                  }}
                                />
                                <label
                                  htmlFor={`regRequestCheckbox${index + 3}`}
                                >
                                  <h6
                                    className={`m-0 p-0 fw400 fs-6  text-${condition.toLowerCase()}`}
                                  >
                                    {condition}
                                  </h6>
                                </label>
                              </div>
                            ))}
                          </div>
                          <ErrorMessage
                            style={{
                              wrap: "nowrap",
                            }}
                            name={`applied_before`}
                            component="div"
                            className="errors fw500 fs-7 mt-2"
                          />
                        </div>
                      </div>
                      <div
                        className="col-lg-6 modal_btn justify-content-end align-items-end mt-2 me-0"
                        style={{
                          position: isMobile ? "relative" : "absolute",
                          top: 0,
                          right: 2,
                        }}
                      >
                        <button
                          className="btnYellow fw600"
                          type="button"
                          onClick={(e) => {
                            handleSubmit();
                          }}
                        >
                          Submit{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </ModaalDark>
    </>
  );
};

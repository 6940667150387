import React, { useState } from "react";
import { Link } from "react-router-dom";
//MUI
import theme from "../../../Theme";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  List,
  useMediaQuery,
} from "@mui/material";
//Custom Components
import ExpertHands from "../../../Component/HelperComponents/ExpertHands/ExpertHands";

import CopyRights from "../../../Component/HelperComponents/CopyRights";

/*Images and Icons */
import Logo from "../../../Assets/logo.png";
import { ErrorMessage, Field, Form, Formik } from "formik";
import OTP from "./OTP";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { POST_SEND_OTP } from "../../../Service/ApiRoutes";
import axios from "axios";
const LoginRegister = () => {
  const [phone, setPhone] = useState("");
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const Validation = Yup.object({
    mobile_num: Yup.string().required("Enter your mobile number "),
    // .matches(/^[0-9]$/, "Mobile number must be exactly 10 digits"),
  });
  const [TenNum, setTenNum] = useState("");
  const [ShowOtp, setShowOtp] = useState(false);
  const handleFormSubmit = (values) => {
    console.log(values);
    const lastTenDigits = values?.mobile_num?.slice(-10);
    console.log(lastTenDigits);
    if (lastTenDigits) {
      setTenNum(lastTenDigits);
    }
    console.log(phone);
    if (values)
      axios.post(POST_SEND_OTP(), values).then((data) => {
        toast.success("OTP Sent Success", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setShowOtp(true);
      });
  };
  console.log(ShowOtp);
  console.log(TenNum);
  return (
    <React.Fragment>
      {/* <span>{`theme.breakpoints.up('md') matches: ${matches}`}</span> */}
      <Grid container sx={{ minHeight: "100vh", flex: 1 }}>
        {matches && (
          <Grid
            sx={{
              backgroundColor: `rgba(${theme.palette.text.default},1)`,
              flex: 1,
              position: "relative",
            }}
          >
            <Box
              sx={{
                backgroundColor: `rgba(${theme.palette.text.default},1)`,
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                width: "max-content",
              }}
            >
              <ExpertHands />
            </Box>
          </Grid>
        )}
        <Grid container sx={{ minHeight: "100vh", flex: 1 }}>
          <Grid
            container
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "100px",
              margin: "30px",
              //alignItems: "center",
              alignItems: !matches ? "center" : "",
            }}
          >
            <Grid>
              <Box component={Link} to="/">
                <img src={Logo} />
              </Box>
            </Grid>
            <Grid>
              {!ShowOtp ? (
                <Box sx={{ maxWidth: "480px" }}>
                  <Typography
                    variant="h5"
                    sx={{
                      mb: 2,
                    }}
                  >
                    Sign In / Sign Up
                  </Typography>
                  <Formik
                    initialValues={{
                      mobile_num: phone,
                    }}
                    validationSchema={Validation}
                    enableReinitialize
                    onSubmit={handleFormSubmit}
                  >
                    {({ setFieldValue, handleSubmit }) => (
                      <Form>
                        <Field name="mobile_num">
                          {({ field }) => (
                            <PhoneInput
                              country={"in"}
                              inputProps={{
                                // ...field,
                                name: "mobile_num",
                                required: true,
                                autoFocus: true,
                              }}
                              className="NewInput"
                              value={phone}
                              onChange={(phone) => {
                                setFieldValue("mobile_num", phone);
                                setPhone(phone);
                              }} // Update phone state on change
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="mobile_num"
                          component="div"
                          className="errors"
                        />
                        {/* <Link to={"/user/enquiries"}> */}
                        <Button
                          type="submit"
                          variant="contained"
                          fullWidth
                          size="large"
                          sx={{
                            color: `rgba(${theme.palette.text.white},1)`,
                            mt: 3,
                          }}
                        >
                          Continue
                        </Button>
                        {/* </Link> */}
                      </Form>
                    )}
                  </Formik>
                </Box>
              ) : (
                <OTP {...{ setShowOtp, phone ,TenNum }} />
              )}
            </Grid>
            {!matches && (
              <Grid>
                <ExpertHands
                  listcol={`rgba(${theme.palette.text.default},1)`}
                  listbordercol={`rgba(${theme.palette.primary.default},1)`}
                  iconcol={`rgba(${theme.palette.text.default},1)`}
                  iconcol2={`rgba(${theme.palette.primary.default},1)`}
                />
              </Grid>
            )}
            <Grid>
              <CopyRights textcol={`rgba(${theme.palette.text.default},1)`} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LoginRegister;

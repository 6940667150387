import React from "react";

import Slide01 from "../../Assets/images/partner/parter.png";

// Bg Consultation Form Section
import BgConsultationFormSection from "../../Assets/images/studyarea/Sa01.jpg";

const bannerDetails = {
  type: "fullimagePartner",
  title:
    "Partner with Modulin Interiors Collaborate with a leader in modular design and unlock new possibilities!",
  desc: "Lorem ipsum dolor sit amet",

  bannerimage: Slide01,
};

const consultationFormSectionDetails = {
  image: BgConsultationFormSection,
  title: "Transform your dream interiors into reality with a single click",
  bgcolor: "0,0,0,.2",
};

export { bannerDetails, consultationFormSectionDetails };

import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

//

//MUI
import theme from "../../../Theme";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  SvgIcon,
  Button,
  TextField,
  InputAdornment,
  Switch,
  FormControlLabel,
} from "@mui/material";
//Custome Components
import { FromFieldStyles } from "../CustomFormElements/CustomeFormElements";
//Images and Icons
import { indflag, Creativity } from "../../../Assets/icons/icons";
import { POST_CONSULTANT } from "../../../Service/ApiRoutes";
import { toast } from "react-toastify";
import axios from "axios";

const ConsultationForm = ({ onClose }) => {
  const classes = FromFieldStyles(); //Form Style

  // Get browser information
  //const browserInfo = window.navigator.userAgent;
  const browserInfo = `Browser: ${navigator.userAgent}`;

  // Get current URL
  const currentURL = window.location.href;

  //Form submission
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    whatsapp_approval: false,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const HandleSubmit = () => {
    axios.post(POST_CONSULTANT(), formData).then(({ data }) => {
      toast.success("Submit", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      onClose();
      console.log(data);
    });
    console.log(formData);
  };

  return (
    <React.Fragment>
      {/* <Typography sx={{ color: "white" }}>{browserInfo}</Typography>
      <Typography sx={{ color: "white" }}>{currentURL}</Typography> */}
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <TextField
          name="name"
          label="Name"
          variant="outlined"
          fullWidth
          className={classes.customTextField}
          margin="normal"
          value={formData.name}
          onChange={handleInputChange}
        />

        <TextField
          name="email"
          label="Email"
          variant="outlined"
          fullWidth
          className={classes.customTextField}
          margin="normal"
          value={formData.email}
          onChange={handleInputChange}
        />
        <TextField
          name="number"
          label="Phone Number"
          variant="outlined"
          fullWidth
          className={classes.customTextField}
          margin="normal"
          value={formData.number}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                className={classes.customAdornment}
              >
                <SvgIcon component={indflag} viewBox="0 0 30 23" />
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          name="whatsapp_approval"
          control={<Switch />}
          label="Stay updated on Whatsapp"
          onChange={handleInputChange}
          classes={{
            root: classes.customTextField,
            label: classes.labelClass,
          }}
        />
        <Button
          variant="contained"
          size="large"
          onClick={HandleSubmit}
          sx={{
            color: `rgba(${theme.palette.text.white},1)`,
            padding: "15px 40px",
            boxSizing: "border-box",
            cursor: "inherit",
          }}
        >
          Get free consultation
        </Button>
        <Box>
          <Typography
            align="center"
            sx={{
              color: `rgba(${theme.palette.text.white},1)`,
              letterSpacing: ".05em",
              margin: "0px 20px",
              "& a": {
                color: `rgba(${theme.palette.text.offtheme},1)`,
                padding: "0px 5px",
                textDecoration: "none",
                display: "inline-block",
              },
            }}
          >
            By submitting this form, you agree to the
            <Box component={Link} to="#">
              privacy policy
            </Box>
            and
            <Box component={Link} to="#">
              terms of use
            </Box>
          </Typography>
        </Box>
      </form>
    </React.Fragment>
  );
};

export default ConsultationForm;

import React from "react";

import Slide01 from "../../Assets/dummy.jpg";

import RAKitchen01 from "../../Assets/images/kitchen/ushape/Ushape04.jpg";
import RAKitchen02 from "../../Assets/images/kitchen/lshape/Lshape01.jpg";
import RAKitchen03 from "../../Assets/images/kitchen/parallel/Pa20.jpg";
import RAKitchen04 from "../../Assets/images/kitchen/iland/Il06.jpg";
import RAKitchen05 from "../../Assets/images/kitchen/K1.jpg";
import RAKitchen06 from "../../Assets/images/kitchen/iland/Il05.jpg";
import RAKitchen07 from "../../Assets/images/kitchen/parallel/Pa06.jpg";
import RAKitchen08 from "../../Assets/images/kitchen/parallel/Pa07.jpg";

//kitchenLayout
import KLayout01 from "../../Assets/images/kitchen/lshape/Lshape04.jpg";
import KLayout02 from "../../Assets/images/kitchen/ushape/Ushape04.jpg";
import KLayout03 from "../../Assets/images/kitchen/parallel/Pa08.jpg";
import KLayout04 from "../../Assets/images/kitchen/iland/Il03.jpg";
import KLayout05 from "../../Assets/images/kitchen/K2.jpg";

//kitchenMaterials
import EngineeredWood from "../../Assets/images/materials/key-module-materials/engineered_wood.jpg";
import PlyWood from "../../Assets/images/materials/key-module-materials/ply_wood.jpg";
import MDF from "../../Assets/images/materials/key-module-materials/mdf.jpg";
import WPC from "../../Assets/images/materials/key-module-materials/wpc.jpg";
import HDHMR from "../../Assets/images/materials/key-module-materials/hdhmr.jpg";

//ShutterFinishes
import LaminateFinish from "../../Assets/images/materials/shutter-finishes/laminate_finish.jpg";
import Polyurethane from "../../Assets/images/materials/shutter-finishes/polyurethane.jpg";
import AcrylicFinish from "../../Assets/images/materials/shutter-finishes/acrylic_finish.jpg";
import HPL from "../../Assets/images/materials/shutter-finishes/hpl.jpg";
import leather from "../../Assets/images/materials/shutter-finishes/leather.jpg";
import texture from "../../Assets/images/materials/shutter-finishes/texture.jpg";
import suede from "../../Assets/images/materials/shutter-finishes/suede.jpg";

//CountertopsBacksplash
//CountertopsBacksplash
import Granite from "../../Assets/images/materials/countertops-backsplash/granite.jpg";
import Marble from "../../Assets/images/materials/countertops-backsplash/marble.jpg";
import Quartz from "../../Assets/images/materials/countertops-backsplash/quartz.jpg";
import CeramicTiles from "../../Assets/images/materials/countertops-backsplash/ceramic_tiles.jpg";
import GlassTiles from "../../Assets/images/materials/countertops-backsplash/glass_tiles.jpg";
import SubwayTiles from "../../Assets/images/materials/countertops-backsplash/subway_tiles.jpg";
import MetalTiles from "../../Assets/images/materials/countertops-backsplash/metal_tiles.jpg";

//Hardware
import Cabinets from "../../Assets/images/materials/cabinet-and-hardware/cabinets.jpg";
import Countertops from "../../Assets/images/materials/countertops-backsplash/countertops.jpg";
import Sink from "../../Assets/images/materials/sink-faucet/single_bowl_sink.jpg";
import GasUnit from "../../Assets/images/kitchen/cabinate/GasUnit.jpg";
import baseUnit from "../../Assets/images/kitchen/cabinate/baseUnit.jpeg";
import KitchenLoft from "../../Assets/images/kitchen/cabinate/KitchenLoft.jpg";
import plates from "../../Assets/images/kitchen/cabinate/plates.jpg";
import SingleShutter from "../../Assets/images/kitchen/cabinate/SingleShutter.jpg";
import spicePullouts from "../../Assets/images/kitchen/cabinate/spicePullouts.jpg";
import Tallunits from "../../Assets/images/kitchen/cabinate/Tallunits.jpg";
import WallUnits from "../../Assets/images/kitchen/cabinate/WallUnits.jpg";

import RangeHood from "../../Assets/dummy.jpg";
import IslandOrPeninsula from "../../Assets/dummy.jpg";
import Lighting from "../../Assets/dummy.jpg";
import StorageSolutions from "../../Assets/dummy.jpg";
import HardwareSub from "../../Assets/dummy.jpg";
import Ventilation from "../../Assets/dummy.jpg";
import ElectricalOutlets from "../../Assets/dummy.jpg";
import DecorAndAccessories from "../../Assets/dummy.jpg";
import WasteManagement from "../../Assets/images/materials/cabinet-and-hardware/waste_management.jpg";

//Handles
import RegularImg from "../../Assets/images/kitchen/handles/regular.jpg";
import edgeImg from "../../Assets/images/kitchen/handles/edge.jpg";
import golaImg from "../../Assets/images/kitchen/handles/gola.jpg";

//SinkFaucet
import UndermountSink from "../../Assets/dummy.jpg";
import SingleBowlSink from "../../Assets/images/materials/sink-faucet/single_bowl_sink.jpg";

// applience
import Dish from "../../Assets/images/kitchen/Appli/dishwasher.jpg";
import Hood from "../../Assets/images/kitchen/Appli/hood.jpg";
import Stove from "../../Assets/images/kitchen/Appli/stove.jpg";
import Refri from "../../Assets/images/kitchen/Appli/refrigerator.jpg";
import Oven from "../../Assets/images/kitchen/Appli/oven.jpg";

// Sink
import Doublebowl from "../../Assets/images/kitchen/sink/Doublebowl.jpg";
import Faucet from "../../Assets/images/kitchen/sink/Faucet.jpg";

// Parallex
import BgScrolling from "../../Assets/images/kitchen/K5.jpg";

// Bg Consultation Form Section
import BgConsultationFormSection from "../../Assets/images/bg-image/kitchen2.jpg";

//carouselMainSettings
const carouselMainSettings = {
  //margin: 120,
  // loop: true,
  center: true,
  // mouseDrag: false,
  // touchDrag: false,
  // nav: false,
  // dots: true,
  // autoplay: true,
  // autoplayTimeout: 1000,
  // autoplayHoverPause: true,
  // // responsive: {
  //   0: {
  //     items: 1,
  //   },
  //   400: {
  //     items: 2,
  //   },
  //   601: {
  //     items: 3,
  //   },
  //   901: {
  //     items: 5,
  //   },
  //   1200: {
  //     items: 9,
  //   },
  // },
};

const bannerDetails = {
  type: "fullimage",
  image: RAKitchen02,
  // title: "Elevate your home with enduring, exquisite woodwork.",
  title: "Transform Your Space with Kitchen Designs",
  desc: "Redefine your culinary space with Modulin’s modular kitchen designs. Tailored to your needs, our kitchens offer smart storage solutions, sleek finishes, and ergonomic layouts, ensuring a stylish and efficient space for every cooking experience",
  bannertext: false,
};

const ReasonsAmbienceKitchen = [
  {
    id: 1,
    title: "Reasons Ambience Kitchen 01",
    imageUrl: RAKitchen01,
  },
  {
    id: 2,
    title: "Reasons Ambience Kitchen 03",
    imageUrl: RAKitchen02,
  },
  {
    id: 3,
    title: "Reasons Ambience Kitchen 03",
    imageUrl: RAKitchen03,
  },
  {
    id: 4,
    title: "Reasons Ambience Kitchen 04",
    imageUrl: RAKitchen04,
  },
  {
    id: 5,
    title: "Reasons Ambience Kitchen 05",
    imageUrl: RAKitchen05,
  },
  {
    id: 6,
    title: "Reasons Ambience Kitchen 06",
    imageUrl: RAKitchen06,
  },
  {
    id: 7,
    title: "Reasons Ambience Kitchen 07",
    imageUrl: RAKitchen07,
  },
  {
    id: 8,
    title: "Reasons Ambience Kitchen 08",
    imageUrl: RAKitchen08,
  },
];

const kitchenLayout = [
  {
    id: 1,
    title: "L - Shape",
    imageUrl: KLayout01,
    //link: "/home-interiors",
  },
  {
    id: 2,
    title: "Straight",
    imageUrl: KLayout05,
    //link: "/home-interiors",
  },
  {
    id: 3,
    title: "U - Shape",
    imageUrl: KLayout02,
    //link: "/home-interiors",
  },
  {
    id: 4,
    title: "Parallel",
    imageUrl: KLayout03,
    //link: "/home-interiors",
  },
  {
    id: 5,
    title: "I LAND",
    imageUrl: KLayout04,
    //link: "/home-interiors",
  },
];
const kitchenMaterials = [
  {
    id: 1,
    title: "BWR & BWP",
    imageUrl: EngineeredWood,
    desc: "Engineered beams are a great way of getting the strength while keeping the cost and size  low",
    //link: "/home-interiors",
  },
  {
    id: 2,
    title: "Ply Wood",
    imageUrl: PlyWood,
    desc: "A sheet good manufactured from thin layers (or “plies”) or wood veneer that are glued together.",
    //link: "/home-interiors",
  },
  {
    id: 3,
    title: "MDF",
    imageUrl: MDF,
    desc: "MDF is made by breaking down hardwood and softwood pieces into fibers, which are combined with wax and resin binders, and formed into panels by applying high temperatures and pressure",
    //link: "/home-interiors",
  },
  {
    id: 4,
    title: "WPC",
    imageUrl: WPC,
    desc: "Wood Plastic Composite board. Its composition is 70% virgin polymer, 15% additive chemical, and 15% wood powder.",
    //link: "/home-interiors",
  },
  {
    id: 5,
    title: "HDHMR",
    imageUrl: HDHMR,
    desc: "HDHMR stands for High-Density High-Moisture Resistance. It is essentially an upgraded version of plywood, used to develop tough and robust wooden applications",
    //link: "/home-interiors",
  },
];
const ShutterFinishes = [
  {
    id: 1,
    title: "Laminate Finish",
    imageUrl: LaminateFinish,
    desc: "Thin layer of  laminate is bonded to the substrate, providing a range of colors, patterns, and textures. Durable and easy to clean.",
    details: {
      materials: "MDF, Particle Board",
      uses: "Interior shutters; offers versatility in design options",
    },
    //link: "/home-interiors",
  },
  {
    id: 2,
    title: "Polyurethane (PU) Finish",
    imageUrl: Polyurethane,
    desc: "PU paint offers a protective and durable finish with a high-gloss or matte surface. Enhances wood's natural beauty while adding longevity",
    details: {
      materials: "Wood",
      uses: "Interior and exterior shutters; offers a polished and refined look",
    },
    //link: "/home-interiors",
  },
  {
    id: 3,
    title: "Acrylic Finish",
    imageUrl: AcrylicFinish,
    desc: "Acrylic sheets are bonded onto the substrate, creating a glass-like finish. Offers vibrant colors and a reflective surface",
    details: {
      materials: "MDF, Wood",
      uses: "Interior shutters; provides a modern and luxurious aesthetic",
    },
    //link: "/home-interiors",
  },
  {
    id: 4,
    title: "High-Pressure Laminate (HPL) Finish",
    imageUrl: HPL,
    desc: "Durable laminate with high-pressure bonding. Offers resistance to scratches, impact, and moisture. Available in various textures and patterns",
    details: {
      materials: "MDF, Particle Board",
      uses: "Interior shutters; suitable for high-traffic areas",
    },
    //link: "/home-interiors",
  },
  {
    id: 5,
    title: "Suede Finish",
    imageUrl: suede,
    desc: "A soft, velvety texture with a matte appearance, ideal for adding subtle elegance to interiors.",
    // details: {
    //   materials: "MDF, Particle Board",
    //   uses: "Interior shutters; suitable for high-traffic areas",
    // },
    //link: "/home-interiors",
  },
  {
    id: 6,
    title: "Leather finish",
    imageUrl: leather,
    desc: "A leather finish provides a textured, matte surface resembling leather, adding a natural and elegant look. It's durable, resistant to fingerprints and scratches, and is commonly used in countertops, flooring, and wall cladding for a warm, sophisticated aesthetic.",
    // details: {
    //   materials: "MDF, Particle Board",
    //   uses: "Interior shutters; suitable for high-traffic areas",
    // },
    //link: "/home-interiors",
  },
  {
    id: 7,
    title: "Textured Finish",
    imageUrl: texture,
    desc: "Adds depth and variation to surfaces, enhancing visual interest and creating unique patterns in design.",
    // details: {
    //   materials: "MDF, Particle Board",
    //   uses: "Interior shutters; suitable for high-traffic areas",
    // },
    //link: "/home-interiors",
  },
];
const Handles = [
  {
    id: 1,
    title: "Regular",
    imageUrl: RegularImg,
    //link: "/home-interiors",
  },
  {
    id: 2,
    title: "Edge Profile",
    imageUrl: edgeImg,
    //link: "/home-interiors",
  },
  {
    id: 3,
    title: "Gola",
    imageUrl: golaImg,
    //link: "/home-interiors",
  },
];

const PrallexDetails = {
  title:
    "Upgrade your kitchen to modular perfection, where convenience and style harmonize flawlessly.",
  highlight: "modular perfection",
  image: BgScrolling,
};

const CountertopsBacksplash = [
  {
    id: 1,
    title: "Granite",
    imageUrl: Granite,
    desc: "Natural stone with a durable, luxurious appearance",
    //link: "/home-interiors",
  },
  {
    id: 2,
    title: "Marble",
    imageUrl: Marble,
    desc: "Another natural stone known for its elegance and unique veining",
    //link: "/home-interiors",
  },
  {
    id: 3,
    title: "Quartz",
    imageUrl: Quartz,
    desc: "Engineered stone with a wide range of colors and patterns",
    //link: "/home-interiors",
  },
  {
    id: 4,
    title: "Ceramic Tiles",
    imageUrl: CeramicTiles,
    desc: "Durable, versatile, and available in various colors and patterns",
    //link: "/home-interiors",
  },
  {
    id: 5,
    title: "Glass Tiles",
    imageUrl: GlassTiles,
    desc: "Reflective and modern, adding depth and light to the space.",
    //link: "/home-interiors",
  },
  {
    id: 6,
    title: "Subway Tiles",
    imageUrl: SubwayTiles,
    desc: "Classic rectangular tiles often arranged in a brick-like pattern",
    //link: "/home-interiors",
  },
  {
    id: 6,
    title: "Metal Tiles",
    imageUrl: MetalTiles,
    desc: "Adds an industrial or contemporary vibe to the space",
    //link: "/home-interiors",
  },
];
const Hardware = [
  {
    id: 1,
    title: "Cabinets",
    imageUrl: Cabinets,
    desc: "Cabinets offer essential storage in a modular kitchen, keeping utensils, cookware, and food items organized and easily accessible.",
    //link: "/home-interiors",
  },
  {
    id: 2,
    title: "Countertops",
    imageUrl: Countertops,
    desc: "Work surfaces where food preparation, cooking, and other tasks take place. They come in a wide range of materials",
    //link: "/home-interiors",
  },
  {
    id: 3,
    title: "Sink",
    imageUrl: Sink,
    desc: "Used for washing dishes, food preparation, and cleaning. It's often accompanied by a faucet for water supply.",
    //link: "/home-interiors",
  },
  {
    id: 4,
    title: "Gas Unit",
    imageUrl: GasUnit,
    desc: "The gas unit provides a safe and efficient cooking surface for preparing meals, typically featuring multiple burners for versatility",
    //link: "/home-interiors",
  },
  {
    id: 5,
    title: "Kitchen Loft",
    imageUrl: KitchenLoft,
    desc: "Kitchen lofts maximize vertical space, providing additional storage for infrequently used items, decorative pieces, or seasonal cookware.",
    //link: "/home-interiors",
  },
  {
    id: 6,
    title: "Single Shutter",
    imageUrl: SingleShutter,
    desc: "Single shutter units are compact storage solutions with one door, ideal for small spaces while ensuring easy access to contents.",
    //link: "/home-interiors",
  },
  {
    id: 7,
    title: "Plates and Glass Unit",
    imageUrl: plates,
    desc: "This unit stores plates, glasses, and dishware, often designed with adjustable shelves for versatile organization and display.",
    //link: "/home-interiors",
  },
  {
    id: 8,
    title: "Spice Pull Outs",
    imageUrl: spicePullouts,
    desc: "Spice pullouts keep spices organized and easily accessible, allowing quick access while cooking, enhancing efficiency in meal preparation.",
    //link: "/home-interiors",
  },
  {
    id: 9,
    title: "Tall Units",
    imageUrl: Tallunits,
    desc: "Tall units maximize vertical storage, ideal for pantry items or kitchen appliances, offering ample space while maintaining a sleek appearance.",
    //link: "/home-interiors",
  },
  {
    id: 10,
    title: "Wall Units",
    imageUrl: WallUnits,
    desc: "Wall units provide elevated storage, keeping kitchen essentials within reach while saving floor space, enhancing the kitchen's functionality.",
    //link: "/home-interiors",
  },
  // {
  //   id: 11,
  //   title: "Base Unit",
  //   imageUrl: baseUnit,
  //   desc: "Proper ventilation, apart from the range hood, can include windows and exhaust fans to maintain a comfortable and odor-free environment.",
  //   //link: "/home-interiors",
  // },
  // {
  //   id: 12,
  //   title: "Electrical Outlets",
  //   imageUrl: ElectricalOutlets,
  //   desc: "Strategically placed outlets for appliances, charging devices, and other electrical needs.",
  //   //link: "/home-interiors",
  // },
  // {
  //   id: 13,
  //   title: " Decor and Accessories",
  //   imageUrl: DecorAndAccessories,
  //   desc: "Personal touches like artwork, plants, and decorative elements to add character and warmth.",
  //   //link: "/home-interiors",
  // },
  {
    id: 14,
    title: "Waste Management",
    imageUrl: WasteManagement,
    desc: "Trash and recycling bins, composting areas, and disposal systems for maintaining a clean kitchen",
    //link: "/home-interiors",
  },
];
const Appliances = [
  {
    id: 1,
    title: "Dish Washer",
    imageUrl: Dish,
    //link: "/home-interiors",
  },
  {
    id: 2,
    title: "Oven",
    imageUrl: Oven,
    //link: "/home-interiors",
  },
  {
    id: 3,
    title: "Range Hood",
    imageUrl: Hood,
    //link: "/home-interiors",
  },
  {
    id: 4,
    title: "Refrigerator",
    imageUrl: Refri,
    //link: "/home-interiors",
  },
  {
    id: 5,
    title: "stove",
    imageUrl: Stove,
    //link: "/home-interiors",
  },
];
const SinkFaucet = [
  {
    id: 1,
    title: "Double Bowl Sink",
    imageUrl: Doublebowl,
    desc: "Installed beneath the countertop for a seamless and easy-to-clean look.",
    //link: "/home-interiors",
  },
  {
    id: 2,
    title: "Single-Bowl Sink",
    desc: "Features a single, larger bowl for washing larger items.",
    imageUrl: SingleBowlSink,
    //link: "/home-interiors",
  },
  {
    id: 3,
    title: "Faucet For Sink",
    desc: "Features a single, larger bowl for washing larger items.",
    imageUrl: Faucet,
    //link: "/home-interiors",
  },
];
const DesignApproach = [
  {
    id: 1,
    title: "Triangle Shape ",
    imageUrl: Slide01,
    //link: "/home-interiors",
  },
  {
    id: 2,
    title: "Triangle Shape",
    imageUrl: Slide01,
    //link: "/home-interiors",
  },
  {
    id: 3,
    title: "Triangle Shape",
    imageUrl: Slide01,
    //link: "/home-interiors",
  },
  {
    id: 4,
    title: "Triangle Shape ",
    imageUrl: Slide01,
    //link: "/home-interiors",
  },
  {
    id: 5,
    title: "Triangle Shape",
    imageUrl: Slide01,
    //link: "/home-interiors",
  },
];

const consultationFormSectionDetails = {
  image: BgConsultationFormSection,
  title: "Transform your dream interiors into reality with a single click",
  bgcolor: "0,0,0,.2",
};

export {
  carouselMainSettings,
  bannerDetails,
  kitchenLayout,
  ReasonsAmbienceKitchen,
  kitchenMaterials,
  ShutterFinishes,
  Handles,
  CountertopsBacksplash,
  PrallexDetails,
  Hardware,
  Appliances,
  SinkFaucet,
  DesignApproach,
  consultationFormSectionDetails,
};

import React from "react";

//Images and Icons
import bannerImage from "../../Assets/images/workstations/Ws01.jpg";

const BannerDetails = {
  type: "fullimage",
  image: bannerImage,
  title: "Modular kitchen designs",
  desc: "The workstation is where productivity meets comfort, and it needs to be as organized as it is inspiring. Modulin’s modular workstation designs are tailored to optimize space, enhance functionality, and create a focused, efficient environment. From ergonomic furniture to smart storage solutions, we ensure every element of your workspace is designed for maximum productivity while maintaining a sleek, modern aesthetic.",
  bannertext: false,
};

////////////////////////////////////////////////
// List of image filenames to hide
const imagesToHide = ["Ws03.jpg"];

// Function to import all images from a given context and exclude specified images
const importAllImages = (requireContext) =>
  requireContext
    .keys()
    .filter((key) => {
      const imageName = key.split("/").pop(); // Get the image name from the path
      return !imagesToHide.includes(imageName);
    })
    .map((key, index) => {
      const imageName = key.split("/").pop(); // Get the image name from the path
      const pathParts = key.split("/");
      const folderName =
        pathParts.length >= 2
          ? pathParts[pathParts.length - 2]
          : "Unknown Folder"; // Get the folder name or use "Unknown Folder"
      return {
        id: index + 1, // Generate unique ID using the index
        title: imageName.replace(/\.[^/.]+$/, ""), // Remove file extension for title
        imageUrl: requireContext(key),
        category: folderName,
      };
    });

// Import all images from the specified context
const Gallery = importAllImages(
  require.context(
    "../../Assets/images/workstations",
    true, // Include subdirectories
    /\.(png|jpe?g|webp)$/
  )
);
////////////////////////////////////////////////

export { Gallery, BannerDetails };

import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
//MUI
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  SvgIcon,
  Typography,
} from "@mui/material";

//Custome Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBanner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import Section from "../../Component/HelperComponents/Section";
import SectionTitle from "../../Component/HelperComponents/SectionTitle";
import SectionScrolling from "../../Component/HelperComponents/SectionScrolling";
import CustomCarouselMain from "../../Component/HelperComponents/CustomCarousel/CustomCarouselMain";
import { FromFieldStyles } from "../../Component/HelperComponents/CustomFormElements/CustomeFormElements";
import ConsultationFormSection from "../../Component/HelperComponents/ConsultationForm/ConsultationFormSection";
import RangeSet from "../../Component/HelperComponents/RangeType/RangeSet";

//Image and Icons
import FamiliarizeImage from "../../Assets/images/wardrobe/Wd05.jpg";
import BgImage from "../../Assets/images/bg-image/kitchen-outline.jpg";

//Data
import {
  bannerDetails,
  discoverWardrobes,
  discoverWardrobesSettings,
  typesOfWardrobes,
  premiumCoreMaterials,
  shuterTypes,
  shuterFinishes,
  wardrobeHandles,
  wardrobeCabinets,
  wardrobeAccessories,
  PrallexDetails,
  consultationFormSectionDetails,
} from "../../Data/ModularInteriors/Wardrobes";

import {
  BannerDetails as BannerDetailsWardrobeDesigns,
  Gallery as galleryWardrobeDesigns,
} from "../../Data/DesignIdeas/WardrobeDesigns";
import CustomGallery from "../../Component/HelperComponents/CustomGallery/CustomGallery";
function Wardrobes() {
  const { pathname } = useLocation();
  const UpdateData = pathname?.split("/")?.filter((item) => {
    return item !== "modular-interiors" && item !== "";
  });
  console.log(UpdateData);
  //Modal Setup
  const [openModalForm, setOpenModalForm] = useState(false);
  const handleClickOpen = () => {
    setOpenModalForm(true);
  };
  const handleClose = (event, reason) => {
    //if (reason && reason == "backdropClick") return;
    setOpenModalForm(false);
  };
  //Modal Setup End

  const galleryData = galleryWardrobeDesigns;
  //Form Style
  const classes = FromFieldStyles();
  const location = useLocation();

  return (
    <React.Fragment>
      <DefaultLayout>
        <InnerBanner
          type={bannerDetails.type}
          title={bannerDetails.title}
          desc={bannerDetails.desc}
          image={bannerDetails.image}
          bannertext={bannerDetails.bannertext}
        />
        <Section bgcolor="white" padding="100px 24px 20px 24px">
          <RangeSet
            link={location.pathname}
            title={bannerDetails.title}
            desc={bannerDetails.desc}
          />
        </Section>

        <Section bgimg={BgImage} >
          <Container maxWidth={"xl"}>
            <SectionTitle
              // title="Discover wardrobes designed to suit everyone's style."
              title="Explore wardrobes tailored to fit every style"
              variant="h3"
            />
            <Typography>
              Experience the personal touch of a uniquely tailored wardrobe,
              crafted to meet your individual needs.
            </Typography>

            <CustomCarouselMain
              data={discoverWardrobes}
              type="imagetitle"
              settings={discoverWardrobesSettings}
              showdesc={true}
            />
          </Container>
        </Section>

        <Section bgcolor="255, 255,255" padding="100px 0 100px 24px">
          <Typography variant="h5">Types of wardrobes</Typography>
          <Typography sx={{ maxWidth: "480px" }}>
            Make a significant impact on your daily routine with a wardrobe that
            fits perfectly.
          </Typography>
          <CustomCarouselMain
            type="imagetitle"
            data={typesOfWardrobes}
            showbutton={true}
            showdesc={true}
          />
          {/* <Typography variant="h5">
            Premium core materials for superior performance.
          </Typography>
          <Typography sx={{ maxWidth: "480px" }}>
            Elevate your wardrobe experience with superior quality materials
            that guarantee a lifetime of durabilit
          </Typography>
          <CustomCarouselMain
            type="imagetitle"
            data={premiumCoreMaterials}
            showbutton={true}
          /> */}

          {/* <Typography variant="h5">
            Unleash your style with our customizable wardrobe options
          </Typography>
          <Typography sx={{ maxWidth: "480px" }}>
            Infuse elegance into your room with a customized look tailored to
            your desires.
          </Typography> */}
          {/* <Typography variant="h6">Wardrobe Shuter Types</Typography>
          <CustomCarouselMain
            type="imagetitle"
            data={shuterTypes}
            showbutton={true}
          /> */}
          <Typography variant="h6">Wardrobe Shutter Finishes</Typography>
          <CustomCarouselMain
            type="imagetitle"
            data={shuterFinishes}
            showbutton={true}
            showdesc={true}
          />
          <Typography variant="h6">Handles</Typography>
          <CustomCarouselMain
            type="imagetitle"
            data={wardrobeHandles}
            showbutton={true}
            showdesc={true}
          />

          {/* <Typography variant="h5">
            Discover what awaits in our wardrobe collection.
          </Typography>
          <Typography sx={{ maxWidth: "480px" }}>
            Select from a wide range of cabinets, add-ons, and accessories to
            customize your wardrobe.
          </Typography>
          <Typography variant="h6">The Cabinets</Typography> */}
          {/* <CustomCarouselMain
            type="imagetitle"
            data={wardrobeCabinets}
            showbutton={true}
            showdesc={true}
          /> */}
          <Typography variant="h6">Cabinets and Accessories</Typography>
          <CustomCarouselMain
            type="imagetitle"
            data={wardrobeAccessories}
            showbutton={true}
            showdesc={true}
          />
        </Section>


        {galleryData ? (
          <Section bgcolor="white" padding="0 24px 50px 24px">
            <SectionTitle
              title={UpdateData && `Ideas For Modular ${UpdateData[0]}`}
              shadowtext="Idea"
              // title2={UpdateData && `Ideas For Modular ${UpdateData[0]} Design`}
            />

            <CustomGallery
              data={galleryData}
              type="slideimage"
              handleClickOpen={handleClickOpen}
              loadmore={true}
            />
          </Section>
        ) : null}
        <SectionScrolling
          title={PrallexDetails.title}
          highlight={PrallexDetails.highlight}
          bgimg={PrallexDetails.image}
        />

        <Section bgcolor="0,0,0,.05">
          <Container maxWidth={"xl"}>
            <Typography variant="h5">
              Familiarize yourself with every aspect of your wardrobe.
            </Typography>
            <Typography>
            Explore the elements that define the exquisite craftsmanship of your wardrobe
            </Typography>
            <Grid
              container
              sx={{ marginTop: "70px" }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid md={6}>
                <img src={FamiliarizeImage} style={{ maxWidth: "100%" }} />
              </Grid>
              <Grid md={6}>
                <Box
                  sx={{
                    marginLeft: {
                      md: "80px",
                      xs: 0,
                    },
                  }}
                >
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      fontWeight: 500,
                    }}
                  >
                    <ListItem>
                      Experience a robust cabinet construction with our advanced
                      connector mechanism
                    </ListItem>
                    <ListItem>
                      Ensure long-lasting durability with cabinets assembled
                      using chipboard screws and protected by PVC sockets.
                    </ListItem>
                    <ListItem>
                      Enjoy a stress-free experience with soft-closing hinges
                      that minimize vibrations when cabinets are closed.
                    </ListItem>
                    <ListItem>
                      Safeguard cabinets from moisture damage with PVC legs for
                      elevated support.
                    </ListItem>
                    <ListItem>
                      Maintain the Integrity of your woodwork by leaving a gap
                      between walls and cabinets to prevent moisture intrusion.
                    </ListItem>
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Section>

        <Section
          bgimg={consultationFormSectionDetails.image}
          bgcolor={consultationFormSectionDetails.bgcolor}
        >
          <Container>
            <ConsultationFormSection
              title={consultationFormSectionDetails.title}
            />
          </Container>
        </Section>
      </DefaultLayout>
    </React.Fragment>
  );
}
export default Wardrobes;

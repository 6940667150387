import React, { useEffect, useState } from "react";
import DefaultLayout from "../Layout/DefaultLayout";
import {
  Typography,
  Box,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Grid,
} from "@mui/material";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { ConsultationTable } from "./ConsultationTable";
import axios from "axios";
import { GET_CONSULT } from "../../../Service/ApiRoutes";

const Consultation = () => {
  const Token = localStorage.getItem("TOKEN");
  const Role = localStorage.getItem("ROLE");
  const [status, setstatus] = useState("");
  const [ConsultData, setConsultData] = useState([]);
  const [Page, setPage] = useState(1);
  const [TotalPage, setTotalPage] = useState("");
  const HandleGetUSerAgents = () => {
    axios
      .post(
        GET_CONSULT(),
        {
          status: status,
          page: Page,
        },
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      )
      .then(({ data }) => {
        console.log(data);
        setPage(data?.pagination?.currentPage);
        setTotalPage(data?.pagination?.totalPages);
        setConsultData(data?.data);
      });
  };

  useEffect(() => {
    HandleGetUSerAgents();
  }, [status, Page]);
  console.log(ConsultData);
  console.log(Page);

  return (
    <React.Fragment>
      <DefaultLayout>
        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
          Consulatation
        </Typography>
        {Role === "Super Admin" && (
          <Grid item lg={6} md={6}>
            {" "}
            <div
              className="FleLeft gap-3"
              style={{
                width: "270px",
              }}
            >
              <div
                className=""
                style={{
                  maxWidth: "50%",
                  marginTop: 2,
                  minWidth: "50%",
                }}
              >
                <label
                  htmlFor="leaveId"
                  style={{
                    fontSize: ".9em",
                  }}
                  className="mb-2 fw500"
                >
                  Status
                </label>
                <FormControl fullWidth className="CustomSelectDropSmallGray">
                  <InputLabel id="leaveId">Select</InputLabel>
                  <Select
                    labelId="leaveId"
                    id="demo-simple-leave"
                    label="Select"
                    name="loan_type"
                    value={status}
                    className="Custom_Select"
                  >
                    <MenuItem
                      value={"Reset"}
                      key={"Reset"}
                      onClick={() => {
                        setstatus("");
                      }}
                    >
                      Reset
                    </MenuItem>
                    {[
                      "Pending",
                      "Booked",
                      "Consultation",
                      "Completed",
                      "On Hold",
                      "Cancelled",
                    ]?.map((Elem) => {
                      return (
                        <MenuItem
                          value={Elem}
                          key={Elem}
                          onClick={() => {
                            setstatus(Elem);
                          }}
                        >
                          {Elem}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Grid>
        )}

        <ConsultationTable
          Data={ConsultData}
          Refetch={HandleGetUSerAgents}
          TotalPage={TotalPage}
          setTotalPage={setTotalPage}
          Page={Page}
          setPage={setPage}
        />
        {/* <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            //autoHeight
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box> */}
      </DefaultLayout>
    </React.Fragment>
  );
};

export default Consultation;

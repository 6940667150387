import React, { useEffect, useState } from "react";
import DefaultLayout from "../Layout/DefaultLayout";
import {
  Typography,
  Box,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { CareerTable } from "./CareerTable";
import axios from "axios";
import { GET_CAREER, GET_CONSULT } from "../../../Service/ApiRoutes";

const Career = () => {
  const Token = localStorage.getItem("TOKEN");
  const Role = localStorage.getItem("ROLE");
  const [type, settype] = useState("");
  const [CareerData, setCareerData] = useState([]);
  const [Page, setPage] = useState(1);
  const [TotalPage, setTotalPage] = useState("");
  const HandleGetUSerAgents = () => {
    axios
      .post(
        GET_CAREER(),
        {
          page: Page,
        },
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      )
      .then(({ data }) => {
        console.log(data);
        setPage(data?.pagination?.currentPage);
        setTotalPage(data?.pagination?.totalPages);
        setCareerData(data?.data);
      });
  };

  useEffect(() => {
    HandleGetUSerAgents();
  }, [type,Page]);
  console.log(CareerData);

  return (
    <React.Fragment>
      <DefaultLayout>
        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
          Career
        </Typography>

        <CareerTable
          Data={CareerData}
          TotalPage={TotalPage}
          setTotalPage={setTotalPage}
          Page={Page}
          setPage={setPage}
        />
        {/* <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            //autoHeight
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box> */}
      </DefaultLayout>
    </React.Fragment>
  );
};

export default Career;

import React from "react";
import Popover from "@mui/material/Popover";
import Theme from "../../../Theme";

function ModaalDark({
  anchorEl,
  setAnchorEl,
  children,
  Height,
  borderRadius,
  handleClose,
}) {
  // function handleClose() {
  //   setAnchorEl(false);
  // }
  const id = anchorEl ? "simple-popover" : "undefined";
  return (
    <Popover
      id={id}
      open={anchorEl} // anchorEl={anchorEl}
      disableScrollLock={false}
      anchorEl={document.body}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      onClose={handleClose}
      disableRestoreFocus
      disablePortal={false}
      sx={{
        // backdropFilter: "blur(2px)", // Add backdrop filter for a blurry effect
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(46,46,46, .5)", // Set the background color to black with 50% opacity
          // backgroundColor: "rgb(12, 12, 33, 0.5)",
          // transform: "scale(.9)",
        },
        "& .MuiPopover-paper": {
          minHeight: Height || "auto",
          borderRadius: borderRadius || "15px",
          backgroundImage: "none",
          transform: "none !important",
          transform: "translate(-50%, -50%) !important",
          // backgroundColor: "rgba(255, 255, 255, 1)",
          backgroundColor: `rgba(${Theme.palette.background.white},1)`,
          top: "50% !important",
          left: "50% !important",
          // backgroundImage:
          //   "linear-gradient(180deg, #24222A 0%, rgba(226, 224, 232, 0.50) 610%)",
        },
      }}
      className="DarkModal"
    >
      {children}
    </Popover>
  );
}

export default ModaalDark;

import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
//MUI
import theme from "../../Theme";
import { Box, Container, Grid, Typography, Button, Modal } from "@mui/material";
//Custom Components
import DefaultLayout from "../../Component/Layout/DefaultLayout";
import InnerBannner from "../../Component/HelperComponents/BannerComponent/InnerBanner";
import Section from "../../Component/HelperComponents/Section";
import SectionTitle from "../../Component/HelperComponents/SectionTitle";
import CustomGallery from "../../Component/HelperComponents/CustomGallery/CustomGallery";
import ConsultationForm from "../../Component/HelperComponents/ConsultationForm/ConsultationForm";
import ModalSection from "../../Component/HelperComponents/ModalSection";
import Faqs from "../../Component/HelperComponents/Faqs/Faqs";

//images
import Dummy from "../../Assets/images/kitchen/K1.jpg";

//Data

import {
  BannerDetails as BannerDetailsModularKitchenDesigns,
  Gallery as galleryModularKitchenDesigns,
} from "../../Data/DesignIdeas/ModularKitchenDesigns";
import {
  BannerDetails as BannerDetailsWardrobeDesigns,
  Gallery as galleryWardrobeDesigns,
} from "../../Data/DesignIdeas/WardrobeDesigns";
import {
  BannerDetails as BannerDetailsMasterBedroomDesigns,
  Gallery as galleryMasterBedroomDesigns,
} from "../../Data/DesignIdeas/MasterBedroomDesigns";
import {
  BannerDetails as BannerDetailsLivingRoomDesigns,
  Gallery as galleryLivingRoomDesigns,
} from "../../Data/DesignIdeas/LivingRoomDesigns";
import {
  BannerDetails as BannerDetailsPoojaRoomDesigns,
  Gallery as galleryPoojaRoomDesigns,
} from "../../Data/DesignIdeas/PoojaRoomDesigns";
import {
  BannerDetails as BannerDetailsTvUnitDesigns,
  Gallery as galleryTvUnitDesigns,
} from "../../Data/DesignIdeas/TvUnitDesigns";
import {
  BannerDetails as BannerDetailsKidsBedroomDesigns,
  Gallery as galleryKidsBedroomDesigns,
} from "../../Data/DesignIdeas/KidsBedroomDesigns";
import {
  BannerDetails as BannerDetailsDiningRoomDesigns,
  Gallery as galleryDiningRoomDesigns,
} from "../../Data/DesignIdeas/DiningRoomDesigns";
import {
  BannerDetails as BannerDetailsGuestBedroomDesigns,
  Gallery as galleryGuestBedroomDesigns,
} from "../../Data/DesignIdeas/GuestBedroomDesigns";
import {
  BannerDetails as BannerDetailsWorkstations,
  Gallery as galleryWorkstations,
} from "../../Data/DesignIdeas/Workstations";
import {
  BannerDetails as BannerDetailsOfficeTables,
  Gallery as galleryOfficeTables,
} from "../../Data/DesignIdeas/OfficeTables";
import {
  BannerDetails as BannerDetailsStorageUnits,
  Gallery as galleryStorageUnits,
} from "../../Data/DesignIdeas/StorageUnits";
import {
  BannerDetails as BannerDetailsCrockeryUnits,
  Gallery as galleryCrockeryUnits,
} from "../../Data/DesignIdeas/CrockeryUnitDesigns";
import {
  BannerDetails as BannerDetailsStudyDesign,
  Gallery as galleryStudyDesign,
} from "../../Data/DesignIdeas/StudyDesign";
import {
  BannerDetails as BannerDetailsHomeOffice,
  Gallery as galleryHomeOffice,
} from "../../Data/DesignIdeas/HomeOfficeDesigns";
import {
  BannerDetails as BannerDetailsEntrance,
  Gallery as galleryEntrance,
} from "../../Data/DesignIdeas/entranceDoorDesigns";
import {
  BannerDetails as BannerDetailsShoeRack,
  Gallery as galleryShoeRack,
} from "../../Data/DesignIdeas/shoeRackDesigns";
import RangeSet from "../../Component/HelperComponents/RangeType/RangeSet";

const DesignIdeasPage = () => {
  const { innerPages } = useParams();

  //Modal Setup
  const [openModalForm, setOpenModalForm] = useState(false);
  const handleClickOpen = () => {
    setOpenModalForm(true);
  };
  const handleClose = (event, reason) => {
    //if (reason && reason == "backdropClick") return;
    setOpenModalForm(false);
  };
  //Modal Setup End

  //Data
  // Determine the data based on the inner page
  let BannerDetailsData;
  let galleryData;

  if (innerPages === "modular-kitchen-designs") {
    BannerDetailsData = BannerDetailsModularKitchenDesigns;
    galleryData = galleryModularKitchenDesigns;
  } else if (innerPages === "wardrobe-designs") {
    BannerDetailsData = BannerDetailsWardrobeDesigns;
    galleryData = galleryWardrobeDesigns;
  } else if (innerPages === "master-bedroom-designs") {
    BannerDetailsData = BannerDetailsMasterBedroomDesigns;
    galleryData = galleryMasterBedroomDesigns;
  } else if (innerPages === "living-room-designs") {
    BannerDetailsData = BannerDetailsLivingRoomDesigns;
    galleryData = galleryLivingRoomDesigns;
  } else if (innerPages === "pooja-room-designs") {
    BannerDetailsData = BannerDetailsPoojaRoomDesigns;
    galleryData = galleryPoojaRoomDesigns;
  } else if (innerPages === "tv-unit-designs") {
    BannerDetailsData = BannerDetailsTvUnitDesigns;
    galleryData = galleryTvUnitDesigns;
  } else if (innerPages === "kids-bedroom-designs") {
    BannerDetailsData = BannerDetailsKidsBedroomDesigns;
    galleryData = galleryKidsBedroomDesigns;
  } else if (innerPages === "dining-room-designs") {
    BannerDetailsData = BannerDetailsDiningRoomDesigns;
    galleryData = galleryDiningRoomDesigns;
  } else if (innerPages === "guest-bedroom-designs") {
    BannerDetailsData = BannerDetailsGuestBedroomDesigns;
    galleryData = galleryGuestBedroomDesigns;
  } else if (innerPages === "workstations") {
    BannerDetailsData = BannerDetailsWorkstations;
    galleryData = galleryWorkstations;
  } else if (innerPages === "office-tables") {
    BannerDetailsData = BannerDetailsOfficeTables;
    galleryData = galleryOfficeTables;
  } else if (innerPages === "storage-unit-designs") {
    BannerDetailsData = BannerDetailsStorageUnits;
    galleryData = galleryStorageUnits;
  } else if (innerPages === "crockery-unit-designs") {
    BannerDetailsData = BannerDetailsCrockeryUnits;
    galleryData = galleryCrockeryUnits;
  } else if (innerPages === "study-area") {
    BannerDetailsData = BannerDetailsStudyDesign;
    galleryData = galleryStudyDesign;
  } else if (innerPages === "home-office-designs") {
    BannerDetailsData = BannerDetailsHomeOffice;
    galleryData = galleryHomeOffice;
  } else if (innerPages === "entrance-door-designs") {
    BannerDetailsData = BannerDetailsEntrance;
    galleryData = galleryEntrance;
  } else if (innerPages === "shoe-rack-designs") {
    BannerDetailsData = BannerDetailsShoeRack;
    galleryData = galleryShoeRack;
  }

  function formatString(inputString) {
    // Split the input string into an array of words using hyphens as separators
    const words = inputString.split("-");
    console.log(words);

    // Capitalize the first letter of each word and join them with spaces
    const formattedString = words
      ?.filter((item) => item !== "designs")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return formattedString;
  }

  const [Title, setTitle] = useState("");
  const location = useLocation();
  // Transform Your Space with Kitchen Designs.
  const Locate = location.pathname?.split("/");
  useEffect(() => {
    if (Locate) {
      setTitle(`Transform Your Space with ${Locate[2]?.split("-")?.join(" ")}`);
    }
  }, []);

  let Descriptin;
  if (Title === "Transform Your Space with master bedroom designs") {
    Descriptin =
      "Transform your master bedroom into a luxurious retreat with Modulin’s custom modular designs. Tailored to your style, our solutions enhance comfort, space, and elegance, creating a personal sanctuary where you can unwind and relax every day.";
  } else if (Title === "Transform Your Space with kids bedroom designs") {
    Descriptin =
      "Design a magical space for your children with Modulin’s playful and practical kids bedroom interiors. Our modular designs balance creativity with storage solutions, fostering an environment where imagination thrives, and everything stays organized.";
  } else if (Title === "Transform Your Space with living room designs") {
    Descriptin =
      "Elevate your living room with Modulin’s innovative modular designs, blending style and functionality. Our layouts are crafted to maximize space while creating a warm, inviting atmosphere perfect for family gatherings and entertaining guests.";
  } else if (Title === "Transform Your Space with modular kitchen designs") {
    Descriptin =
      "Redefine your culinary space with Modulin’s modular kitchen designs. Tailored to your needs, our kitchens offer smart storage solutions, sleek finishes, and ergonomic layouts, ensuring a stylish and efficient space for every cooking experience.";
  } else if (Title === "Transform Your Space with storage unit designs") {
    Descriptin =
      "Maximize your space with Modulin’s efficient modular storage solutions. Our designs blend seamlessly with your home decor, providing stylish yet functional options that help keep your home organized and clutter-free.";
  } else if (Title === "Transform Your Space with home office designs") {
    Descriptin =
      "Redefine productivity with Modulin’s smart modular home office designs. Our solutions provide the perfect blend of style and functionality, creating an organized, comfortable workspace that inspires creativity and focus.";
  } else if (Title === "Transform Your Space with entrance door designs") {
    Descriptin =
      "Make a lasting first impression with Modulin’s exquisite modular entrance door designs. Crafted to reflect your personal style, our doors blend security with elegance, welcoming guests with a stunning statement piece.";
  } else if (Title === "Transform Your Space with shoe rack designs") {
    Descriptin =
      "Keep your entrance organized and stylish with Modulin’s modular shoe racks. Designed for convenience and aesthetic appeal, our racks offer smart storage that effortlessly complements the look of your home.";
  }
  console.log(Title);
  // if()
  return (
    <React.Fragment>
      <DefaultLayout>
        <InnerBannner
          type={BannerDetailsData?.type}
          bannertext={BannerDetailsData?.bannertext}
          image={BannerDetailsData?.image}
          backgroundPosition={BannerDetailsData?.backgroundPosition}
        />

        <Section bgcolor="white" padding={"100px 24px 50px 24px"}>
          <RangeSet link={location.pathname} title={Title} desc={Descriptin} />
        </Section>

        <Section bgcolor="white" padding={"0px 24px 50px 24px"}>
          <SectionTitle title={formatString(innerPages)} />
          <Grid container>
            <Grid item xs={12} md={9}>
              <Typography sx={{ marginBottom: "20px" }}>
                {BannerDetailsData?.desc}
                {/* Kitchens are the heart of any home, and they should be designed
                with both functionality and style in mind. A well-designed
                kitchen can make all the difference in how a home feels and
                functions, which is why it's so important to take the time to
                plan the perfect kitchen for your needs. */}
              </Typography>
              {/* <Typography>
                We create straightforward modular kitchen designs that
                seamlessly combine functionality and style. Simple kitchens with
                minimalist interiors to more elaborately designed kitchen
                interiors are all included in our carefully picked, modular
                kitchen designs in India. All of our contemporary kitchen
                designs may be altered to match your needs and available space.
                We also have kitchen interior designs that accommodate various
                shapes, whether they be L-shaped, -shaped, or open. Our goal is
                to create a kitchen interior design that complements your home
                to the fullest. Check out our best kitchen ideas for 2023 to
                start changing your area right away.
              </Typography> */}
            </Grid>
          </Grid>
        </Section>

        {console.log(galleryData)}
        {galleryData ? (
          <Section bgcolor="white" padding="0 24px 50px 24px">
            <SectionTitle
              title={`Ideas For ${formatString(innerPages)}`}
              shadowtext="Idea"
            />
            <CustomGallery
              data={galleryData}
              type="slideimage"
              handleClickOpen={handleClickOpen}
              loadmore={true}
            />
          </Section>
        ) : null}
        <Section bgcolor="white">
          <Container>
            <SectionTitle
              title="Frequently Asked Questions"
              shadowtext="FAQ's"
            />

            <Faqs />
          </Container>
        </Section>
      </DefaultLayout>

      <ModalSection open={openModalForm} onClose={handleClose}>
        <ConsultationForm onClose={handleClose} />
      </ModalSection>
    </React.Fragment>
  );
};

export default DesignIdeasPage;

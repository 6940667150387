import React from "react";
//MUI
import theme from "../../Theme";
import { Box } from "@mui/material";

const Section = (props) => {
  const { children, ...rest } = props;
  return (
    <React.Fragment>
      <Box
        id={props?.id}
        sx={{
          backgroundImage: `url(${props.bgimg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: props.bgposition,
          height: `${props.height}px`,
          margin: props.margin,
         
        }}
      >
        <Box
          sx={{
            backgroundColor: `rgba(${props.bgcolor})`,
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${props.backimg})`,
              backgroundRepeat: props.backimgrepeat,
              backgroundPosition: props.backimgposition,
              padding: props.padding,
              backgroundSize: {
                xs: props.backgroundsize.xs,
                sm: props.backgroundsize.sm,
                md: props.backgroundsize.md,
                lg: props.backgroundsize.lg,
                xs: props.backgroundsize.xs,
              },
            }}
          >
            <Box>{children}</Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

Section.defaultProps = {
  bgcolor: "241,241,241,.85",
  padding: "100px 24px",
  bgimg: "none",
  backimg: "none",
  backimgrepeat: "no-repeat",
  // backimgposition: "50px 0px",
  backimgposition: "top right",
  backgroundsize: "auto",
  bgposition: "top center",
  height: "auto",
  margin: "auto",
};

export default Section;

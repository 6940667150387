import React from "react";
//MUI
import theme from "../../../Theme";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  List,
  useMediaQuery,
} from "@mui/material";
//Custom Components
import ItemList from "../../HelperComponents/ItemList";
/*Images and Icons */
import {
  Creativity,
  Personalized,
  Attention,
  Extensive,
  Seamless,
  Commitment,
} from "../../../Assets/icons/icons";

const ExpertHands = ({
  listcol,
  listbordercol,
  iconcol,
  iconcol2,
  fontSize,
  fontSize1,
}) => {
  return (
    <React.Fragment>
      <List
        sx={{
          color: listcol,
          border: listbordercol ? `1px solid ${listbordercol}` : null,
        }}
      >
        <ItemList
          fontSize1={fontSize1}
          fontSize={fontSize}
          padding={"0"}
          title="Unparalleled Creativity"
          icon={Creativity}
          col={iconcol}
          col2={iconcol2}
        />
        <ItemList
          fontSize1={fontSize1}
          fontSize={fontSize}
          padding={"0"}
          title="Personalized Approach"
          icon={Personalized}
          col={iconcol}
          col2={iconcol2}
        />
        <ItemList
          fontSize1={fontSize1}
          fontSize={fontSize}
          padding={"0"}
          title="Attention to Detail"
          icon={Attention}
          col={iconcol}
          col2={iconcol2}
        />
        <ItemList
          fontSize1={fontSize1}
          fontSize={fontSize}
          padding={"0"}
          title="Extensive Expertise"
          icon={Extensive}
          col={iconcol}
          col2={iconcol2}
        />
        <ItemList
          fontSize1={fontSize1}
          fontSize={fontSize}
          padding={"0"}
          title="Seamless Project Management"
          icon={Seamless}
          col={iconcol}
          col2={iconcol2}
        />
        <ItemList
          fontSize1={fontSize1}
          fontSize={fontSize}
          padding={"0"}
          title="Commitment to Quality"
          icon={Commitment}
          col={iconcol}
          col2={iconcol2}
        />
      </List>
    </React.Fragment>
  );
};

ExpertHands.defaultProps = {
  listcol: "white",
  listbordercol: false,
  iconcol: "white",
  iconcol2: "#FECE78",
};

export default ExpertHands;

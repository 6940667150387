import React from "react";
import ModaalDark from "./ModalDark";

export const ReasonModal = ({ Toggler, value, Heading }) => {
  return (
    <ModaalDark anchorEl={true}>
      <div className="isReasonModal">
        <h6>Reason</h6>
        <span
          onClick={Toggler}
          id="closeIcon"
          style={{
            display: "block",
            borderRadius: "50px",
            position: "absolute",
            top: "20px",
            right: "20px",
            background: "rgb(239 91 65)",
            fontSize: ".9em",
            width: "14px",
            height: "14px",
            zIndex: 10,
          }}
        />
        <div className="modalContent p-3">{value}</div>
      </div>
    </ModaalDark>
  );
};
export const ResponseModal = ({ Toggler, value }) => {
  return (
    <ModaalDark anchorEl={true}>
      <div className="isReasonModal">
        <h6>Response</h6>
        <span
          onClick={Toggler}
          id="closeIcon"
          style={{
            display: "block",
            borderRadius: "50px",
            position: "absolute",
            top: "20px",
            right: "20px",
            background: "rgb(239 91 65)",
            fontSize: ".9em",
            width: "14px",
            height: "14px",
            zIndex: 10,
          }}
        />
        <div className="modalContent p-3">{value}</div>
      </div>
    </ModaalDark>
  );
};
export const AddressModal = ({ title, Toggler, value }) => {
  return (
    <ModaalDark anchorEl={true}>
      <div className="isReasonModal">
        <h6 className="fs-6 fw500">{title || "Address"}</h6>
        <span
          onClick={Toggler}
          id="closeIcon"
          style={{
            display: "block",
            borderRadius: "50px",
            position: "absolute",
            top: "20px",
            right: "20px",
            background: "rgb(239 91 65)",
            fontSize: ".9em",
            width: "14px",
            height: "14px",
            zIndex: 10,
          }}
        />
        <div className="modalContent p-3">{value}</div>
      </div>
    </ModaalDark>
  );
};

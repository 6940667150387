import { IoMdLaptop } from "react-icons/io";
import Person01 from "../Assets/dummy.jpg";
import { GrDeliver, GrShieldSecurity } from "react-icons/gr";
import { SlSpeedometer } from "react-icons/sl";
import wh1 from "../Assets/whychoose/wh1.png";
import wh2 from "../Assets/whychoose/wh2.png";
import wh3 from "../Assets/whychoose/wh3.png";
import wh4 from "../Assets/whychoose/wh4.png";
//testimonialsSettings
const whyChooseSettings = {
  // margin: 120,
  // loop: true,
  // center: false,
  // mouseDrag: false,
  // touchDrag: false,
  // nav: false,
  // dots: true,
  // autoplay: true,
  autoplayTimeout: 100000000,
  autoplayHoverPause: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 2,
    },
    900: {
      items: 4,
    },
    1200: {
      items: 4,
    },
    1536: {
      items: 4,
    },
  },
};

const WhyChooseUs = [
  {
    id: 1,
    name: "Surya Teja",
    location: "",
    rating: 5,
    mainTitle: "Run By",
    title: "Designers",
    content:
      "We always had an appreciation for style but are too confused to pull a design together so we decided to place all of our trust in Modulin. We hired them to furnish our new kitchen top-to-bottom, and we are SO happy with the results! We had some idea of what we were looking for but we would never be able to pull together like what the team did. Watching it all come together was a treat. All we had to do was sit back and watch as the design and delivery team brought pieces to our home, assemble and arrange them!! Just blindly trust them. You will be completely satisfied.",
    imageUrl: (
      <img
        src={wh1}
        style={{
          width: "140px",
        }}
      />
    ),
  },
  {
    id: 2,
    name: "Sai Peddireddy",
    location: "",
    rating: 5,
    mainTitle: "10",
    title: "10* Years Warranty",
    content:
      "Best quality with design at a sustainable price. Staff here is so humble and they work at their best in satisfying customers. Especially architect Suresh has a lot of talent and he helped us so much in delivering the best design and output for our project. I'm overall satisfied with the final output and the attached picture shows the result.",
    imageUrl: (
      <img
        src={wh2}
        style={{
          width: "140px",
        }}
      />
    ),
  },
  {
    id: 3,
    name: "Nodagala Viswanadh",
    location: "",
    rating: 5,
    mainTitle: "30* Day",
    title: "Delivery",
    content:
      "If you are looking for help in getting your kitchen done don't hesitate to contact Modulin. All the staff are very professional and I felt very comfortable working with my designer and their team. They listened to what I wanted and made sure to be available if I had new ideas and they always updated me on the progress of the Kitchen. My favorite thing about this company is definitely their distinct modern style. It feels very current & trendy and I enjoy going to the kitchen every day to spend time .They are also very reasonably priced. They used my small space to the fullest in ways I never thought was possible. In one word, it's beautiful, comfortable and functional with very affordable prices.",
    imageUrl: (
      <img
        src={wh3}
        style={{
          width: "140px",
        }}
      />
    ),
  },
  {
    id: 4,
    name: "Durga Prasad M",
    location: "",
    rating: 5,
    mainTitle: "Delivery In",
    title: "Andrapradesh* & Telangana*",
    content:
      "Sleek Kitchen was great. We had a limited budget and they presented us with a number of options for our modular kitchen Interiors that were within our financial range. They explained color schemes and choice of materials simply so that we could understand and visualize the concept. Our Dream kitchen turned out great.",
    imageUrl: (
      <img
        src={wh4}
        style={{
          width: "140px",
        }}
      />
    ),
  },
];

export { whyChooseSettings, WhyChooseUs };

import React from "react";

import Slide01 from "../../Assets/dummy.jpg";
import BannerImage from "../../Assets/images/studyarea/Sa06.jpg";

// Bg Consultation Form Section
import BgConsultationFormSection from "../../Assets/images/studyarea/Sa01.jpg";

//typesStudyArea
import DedicatedStudyRoom from "../../Assets/images/materials/study-area/dedicated_study_room.jpg";
import CornerNook from "../../Assets/images/materials/study-area/corner_nook.jpg";
import BuiltInStudyArea from "../../Assets/images/materials/study-area/built_in_study_area.jpg";
import MultiFunctionalArea from "../../Assets/images/materials/study-area/multi_functional_area.jpg";
import UnderStairStudy from "../../Assets/images/materials/study-area/under_stair_study.jpg";
import ChildrenStudyArea from "../../Assets/images/materials/study-area/children_study_area.jpg";
import SharedStudyArea from "../../Assets/images/materials/study-area/shared_study_area.jpg";

// Parallex
import BgScrolling from "../../Assets/images/studyarea/Sa02.jpg";

const bannerDetails = {
  type: "fullimage",
  title: "Transform Your Space with Study Area Designs",
  desc: "Create the perfect study environment with Modulin’s modular study area designs. Tailored to enhance focus and productivity, our designs offer a balance of style and practicality for your home workspace or study corner",
  image: BannerImage,
  bannertext: false,
};

const typesStudyArea = [
  {
    id: 1,
    title: "Dedicated Study Room",
    imageUrl: DedicatedStudyRoom,
    desc: "A separate room specifically designed for studying and work, providing a quiet and focused environment.",
  },
  {
    id: 2,
    title: "Corner Nook",
    imageUrl: CornerNook,
    desc: "Utilizes a corner of a room or hallway for a compact study area, saving space while maintaining functionality.",
  },
  {
    id: 3,
    title: "Built-In Study Area",
    imageUrl: BuiltInStudyArea,
    desc: "Integrates a study space into existing furniture or architectural elements, such as bookshelves or cabinets.",
  },
  {
    id: 4,
    title: "Multi-Functional Area",
    imageUrl: MultiFunctionalArea,
    desc: "Combines study and other functions, like a living room with a designated study corner.",
  },
  {
    id: 5,
    title: "Under-Stair Study",
    imageUrl: UnderStairStudy,
    desc: "Converts the space beneath a staircase into a functional study area, optimizing unused space.",
  },
  {
    id: 6,
    title: "Children's Study Area",
    imageUrl: ChildrenStudyArea,
    desc: "Creates a kid-friendly study zone with age-appropriate furniture, colorful decor, and educational resources.",
  },
  {
    id: 7,
    title: "Shared Study Area",
    imageUrl: SharedStudyArea,
    desc: "Set up a study space that can be used by multiple family members, accommodating different study needs.",
  },
];

const PrallexDetails = {
  title:
    "Upgrade your kitchen to modular perfection, where convenience and style harmonize flawlessly.",
  highlight: "modular perfection",
  image: BgScrolling,
};

const consultationFormSectionDetails = {
  image: BgConsultationFormSection,
  title: "Transform your dream interiors into reality with a single click",
  bgcolor: "0,0,0,.2",
};

export {
  bannerDetails,
  typesStudyArea,
  PrallexDetails,
  consultationFormSectionDetails,
};
